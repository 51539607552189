import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import {
    empty,
    isEmpty,
    is_empty,
    is_null,
    jQuery,
    showToast,
    show_loading,
} from "utils/GlobalFunctions";
import { APP_NAME } from "config/CONSTANTS";
import { useDispatch, useSelector } from 'react-redux';
import { console_log } from "utils/logger";
import { apiUserSubmitAnswer } from "services/userCommonService";
import { updateUser } from "redux/actions/userActions";

const UserQuestionData = [
    {
        question: "Who holds the funds used for trading in the forex market when using Higher Level FX's educational tool?",
        answer_list: [
            "a) Higher Level FX",
            "b) The broker chosen by the client",
            "c) Higher Level FX and the broker",
            "d) The customer",
        ],
        correct_answer: 1
    },
    {
        question: "Is it your responsibility to understand how our EA works and make sure it aligns with your trading strategy before using it?",
        answer_list: [
            "a) No, it is the responsibility of the EA developer",
            "b) No, it is the responsibility of the broker",
            "c) Yes, it is my responsibility",
            "d) It depends on the performance of the EA",
        ],
        correct_answer: 2
    },
    {
        question: "In case of any issues with your trading account, who should you contact?",
        answer_list: [
            "a) The EA developer",
            "b) The broker",
            "c) Higher Level FX",
            "d) Some random guy who you never met online",
        ],
        correct_answer: 1
    },
    {
        question: "What is an Expert Advisor (EA) in the context of forex trading?",
        answer_list: [
            "a) A type of order used to enter or exit a trade",
            "b) A software program that can automatically execute trades based on a set of predefined rules that I choose",
            "c) A way to manage your risk while trading",
            "d) A type of account offered by brokers",
        ],
        correct_answer: 1
    },
    {
        question: "Is it necessary to monitor the performance of an EA on a trading account?",
        answer_list: [
            "a) No, it is not necessary",
            "b) Yes, it is necessary",
            "c) Only if the EA is a paid one",
            "d) Only if the EA is a custom-built one",
        ],
        correct_answer: 1
    },
    {
        question: "If I have never used an EA before, what should I do?",
        answer_list: [
            "a) Jump in and start using one without any prior knowledge",
            "b) Read some online articles and figure it out on my own",
            "c) Join the HLX community, contact my sponsor, and watch every single tutorial video including the ones in the Higher Level Academy to gain the necessary knowledge",
            "d) Wait until I feel more comfortable and then try using one",
        ],
        correct_answer: 2
    }
]

const UserQuestionBlock = (props) => {
    const {
        visibleModal = true,       
        modalClass = "user-page modal-xl",
    } = props;

    const dispatch = useDispatch();
    const appDataStore = useSelector((x) => x.appDataStore);
    const userDataStore = useSelector((x) => x.userDataStore);
    const socketStore = useSelector((x) => x.socketStore);

    useEffect(() => {
        if (visibleModal !== undefined) {
            setShow(visibleModal);
        }
    }, [visibleModal]);
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        // setVisibleModal(false);
    };

    const [userAnwser, setUuserAnwser] = useState({});
    const onChangeUserAnswer = (index, a_index) => {
        let user_answer = { ...userAnwser }
        user_answer[index] = a_index
        console_log("onChangeUserAnswer:::", user_answer)
        setUuserAnwser(user_answer)
    }
    const checkAnswerValidate = ()=>{
        for(let k in UserQuestionData){
            let item = UserQuestionData[k]
            if(is_null(userAnwser[k])){
                showToast("One or more answers are incorrect", "error")
                return false;
            }
            if(userAnwser[k] !== item['correct_answer']) {
                showToast("One or more answers are incorrect", "error")
                return false;
            }
        }
        return true
    }

    const onClickSubmitAnswer = ()=>{
        let isValid = checkAnswerValidate()
        console_log("isValid", isValid)
        if(!isValid){
            return false
        }
        submitUseAnswer()        
    }

    const submitUseAnswer = () => {
        show_loading(true);
        apiUserSubmitAnswer()
          .then((api_res) => {
            show_loading(false);
            if (api_res.status === "1") {
              dispatch(updateUser(api_res.data["user"]));
              showToast("Great job!", "success")
            } else {
              showToast(api_res.message, "error");
            }
          })
          .catch((err) => {
            show_loading(false);
            showToast(err, "error");
          });
      };

    return (
        <div className="user-question-bg">
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                dialogClassName={modalClass}
                centered
            >
                <Modal.Body>
                    <div className="modal-content-body-1">
                        <div className="text-normal user-question-box">
                            <h3 className="text-center mb-4">Welcome to {APP_NAME}!</h3>
                            <p className="text-normal">
                                Before you begin using our products, it's important that you take the time to fill out the questionnaire provided.
                                This is to ensure that you have a basic understanding of the forex market and how our product works.
                                Remember, knowledge is power, it's your responsibility to make sure you have the right knowledge before engaging in any trade.
                            </p>
                            <div className="block text-center padding-top-20">
                                <div className="block position-relative">
                                    <iframe
                                        src="https://player.vimeo.com/video/789967067"
                                        width="640"
                                        height="360"
                                        frameBorder="0"
                                        allow="autoplay; fullscreen"
                                        title="WELCOME! (WATCH THIS VIDEO!)"
                                        allowFullScreen
                                    ></iframe>
                                    <div className="text-center mt-1 mb-3"></div>
                                </div>
                            </div>
                            <form className="block">
                                <div className="user-question-list">
                                    {
                                        UserQuestionData.map((item, index) => {
                                            return (
                                                <div className="user-question-item" key={index}>
                                                    <p className="text-normal user-question-text">{item['question']}</p>
                                                    <div className="user-answer-list">
                                                        {
                                                            item['answer_list'].map((answer_item, a_index) => {
                                                                return (
                                                                    <div className="user-answer-item form-group-1" key={a_index}>
                                                                        <div
                                                                            className="form-check form-check-block"
                                                                            key={index}
                                                                        >
                                                                            <label className="form-check-label">
                                                                                <input
                                                                                    type="radio"
                                                                                    className="form-check-input"
                                                                                    name={`user_answer_${index}`}
                                                                                    id={`user_answer_${index}_${a_index}`}
                                                                                    value={a_index}
                                                                                    checked={a_index === userAnwser[index]}
                                                                                    onChange={(e) => {
                                                                                        onChangeUserAnswer(index, a_index);
                                                                                    }}
                                                                                />
                                                                                {answer_item}
                                                                                <i className="input-helper"></i>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="block w-100 text-center">
                        <button
                            type="button"
                            className={`btn btn-primary btn-lg`}
                            onClick={(e) => onClickSubmitAnswer() }
                        >
                            Submit
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );

};

export default UserQuestionBlock;