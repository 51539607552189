
import { empty } from "utils/GlobalFunctions";
import { axiosDelete, axiosGet, axiosPost, axiosPut } from "./ajaxServices";
import { urlUnsubscribe } from "./CONSTANTS";

export function apiUnsubscribe(params) {
  const url = urlUnsubscribe;
  const get_params = {
    encrypted_id: params['encrypted_id']
  }
  return new Promise((resolve, reject) => {
    axiosGet(url, get_params, "")
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });   
}