import { SYSTEM_ERROR } from "config/CONSTANTS";
import { axiosGet, axiosPost } from "./ajaxServices";
import {urlAdminGetSettingPageDetail, urlAdminUpdateSetting } from "./CONSTANTS";

export const apiAdminGetSettingPageDetail = () => {
  const url = urlAdminGetSettingPageDetail;
  return new Promise((resolve, reject) => {
    axiosGet(url, {}, SYSTEM_ERROR)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });   
};
export function apiAdminUpdateSetting(params) {
  const url = urlAdminUpdateSetting;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  for(let k in params) {
    formData.append(k, params[k])
  }
   
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });   
} 

