import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  empty,
  get_data_value,
  intval,
  isEmpty,
  jQuery,
  showToast,
  timeConverter,
} from "utils/GlobalFunctions";
import { APP_NAME } from "config/CONSTANTS";
import AdminCouponUserListTable from "./AdminCouponUserListTable";

function AdminCouponStatsInfoModal(props) {
  const {
    modalTitle,
    isVisible,
    setVisibleModal,
    currentStatItem,
    modalClass,
  } = props;

  useEffect(() => {
    if (isVisible !== undefined) {
      setShow(isVisible);
      if (!empty(currentStatItem)) {
        setModalData(currentStatItem);
      }
    }
  }, [isVisible, currentStatItem]);
  const [show, setShow] = useState(false);

  const defaultModalData = {
    user_name: "",
    user_list: []
  }
  const [modalData, setModalData] = useState(defaultModalData);

  const handleClose = () => {
    setShow(false);
    setVisibleModal(false);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        dialogClassName={modalClass}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="block text-left">
            <div className="modal-box">
              <div>
                <AdminCouponUserListTable
                  initialTableData={modalData["user_list"]}
                  currentRankItem={modalData}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-md btn-close-dlg btn-light"
            data-dismiss="modal"
            onClick={handleClose}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AdminCouponStatsInfoModal;
