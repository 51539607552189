import HomeLayout from "layouts/HomeLayout/HomeLayout";
import {
  ROUTE_LOGIN,
  ROUTE_ROOT,
  ROUTE_UNSUBSCRIBE,
  ROUTE_USER_WALLET,
  ROUTE_WELCOME,
} from "navigation/CONSTANTS";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { UpdateAppData } from "redux/actions/appActions";
import { apiUnsubscribe } from "services/homeService";
import { empty, showToast, show_loading } from "utils/GlobalFunctions";
import { console_log } from "utils/logger";
import Particles from "components/Particles/Particles";

const UnsubscribePage = (props) => {
  const dispatch = useDispatch();
  const initPage = () => {
    let page_title = "Unsubscribe";
    dispatch(
      UpdateAppData({
        currentPageTitle: page_title,
        current_route: ROUTE_UNSUBSCRIBE,
      })
    );
  };

  const getUserEncryptedId = () => {
    let userEncryptedId = "";
    try {
      if (!empty(props.match.params.userEncryptedId)) {
        userEncryptedId = props.match.params.userEncryptedId;
      }
    } catch (e) { }
    return userEncryptedId;
  };

  useEffect(() => {
    initPage();
    doUnsubscribe();
  });

  const doUnsubscribe = () => {
    let userEncryptedId = getUserEncryptedId()
    //console_log("userEncryptedId:::", userEncryptedId)
    if (userEncryptedId) {
      let params = { encrypted_id: userEncryptedId }
      show_loading(true);
      apiUnsubscribe(params)
        .then((api_res) => {
          show_loading(false);
          if (api_res.status === "1") {
            showToast(api_res.message, "success");
          } else {
            showToast(api_res.message, "error");
          }
          redirectMainPage()
        })
        .catch((err) => {
          show_loading(false);
          showToast(err, "error");
          redirectMainPage()
        });
    }
  }

  const redirectMainPage = () => {
    props.history.replace({ pathname: ROUTE_ROOT });
  }

  return (
    <div>
      <HomeLayout>
        <div className="main-content bg-front-page-gradient">
          <div
            className="section page-section container has-header content-sm pb-40 relative"
            id="page-section1"
          >
            <Particles className="absolute inset-0 -z-10" />

            <div className="container">
              <div className="row">
                <div className="col-md-12 margin-bottom-30">
                  <div style={{ paddingTop: "50vh" }}>
                    <h1 className="d-none hidden">Unsubscribe</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HomeLayout>
    </div>
  );
};

export default UnsubscribePage;
