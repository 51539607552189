import { ROUTE_LOGIN, ROUTE_USER_FEED } from "navigation/CONSTANTS";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { UpdateAppData } from "redux/actions/appActions";
import { empty, get_data_value, is_empty } from "utils/GlobalFunctions";
import UserFooter from "./Includes/UserFooter";
import UserHeader from "./Includes/UserHeader";
import UserLoader from "./Includes/UserLoader";
import UserSidebar from "./Includes/UserSidebar";
import "./UserLayout.css";

import { BASE_FRONT_URL, SOCKET_SERVER_URL } from "config/CONSTANTS";
import io from "socket.io-client";
import { updateSocket } from "redux/actions/socketActions";
import Marquee from "react-fast-marquee";
import ConfirmLogoutModal from "components/ConfirmLogoutModal/ConfirmLogoutModal";
import { console_log } from "utils/logger";
import UserQuestionBlock from "./Includes/UserQuestionBlock";

const UserLayout = (props) => {
  const dispatch = useDispatch();

  const appDataStore = useSelector((x) => x.appDataStore);
  const userDataStore = useSelector((x) => x.userDataStore);
  const socketStore = useSelector((x) => x.socketStore);

  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  ///////////////////////////////////socket part///////////////////////////////////////////
  const token = get_data_value(userDataStore, "token");
  const socketHeader = { token: token };

  useEffect(() => {
    if (!empty(token)) {
      const socket = io(SOCKET_SERVER_URL, { transports: ['websocket'] }); //const socket = io(SOCKET_SERVER_URL); //const socket = io(SOCKET_SERVER_URL, { transports: ['polling'] });

      socket.on("connnection", () => {
        console_log("-------------connected to server---------------");
      });
      addSocketListener(socket);
      getUserNotificationData(socket);
      socket.on("disconnect", () => {
        console_log("---------------Socket disconnecting-------------------");
        // dispatch(
        //   updateSocket({
        //     socket: null,
        //   })
        // );
      });

      dispatch(
        updateSocket({
          socket: socket,
        })
      );
      return () => socket.close();
    } else {
      return () => false;
    }
  }, []);

  const addSocketListener = (socket) => {
    socket.off("get_user_notification_data");
    socket.on("get_user_notification_data", (data) => {
      console_log("-------------get_user_notification_data---------", data);
      dispatch(
        updateSocket({
          user_notification_data: data,
        })
      );
      dispatch(
        UpdateAppData({
          license_cancelled_message: data["license_cancelled_message"],
        })
      );
    });

    socket.off("submit_feed_item");
    socket.on("submit_feed_item", (data) => {
      console_log('---------on submit_feed_item--------------')
      socket.emit("get_user_notification_data", { ...socketHeader });
      if (appDataStore.current_route === ROUTE_USER_FEED) {
        socket.emit("get_feed_list", { ...socketHeader, last_id: 0 });
      }
    });

    socket.off("do_logout");
    socket.on("do_logout", (data) => {
      console_log(
        "-------------do_logout received---------",
        data
      );

      history.push(ROUTE_LOGIN); //window.location.href = BASE_FRONT_URL + "/login"
    });

  };
  const getUserNotificationData = (socket) => {
    socket.emit("get_user_notification_data", { ...socketHeader });
  };
  ///////////////////////////////////end socket part///////////////////////////////////////////

  const [showConfirmLogoutModal, setShowConfirmLogoutModal] = useState(false);
  const askLogout = () => {
    setShowConfirmLogoutModal(true);
  };

  const doLogout = () => {
    setShowConfirmLogoutModal(false);
    history.push(ROUTE_LOGIN);
  };

  const checkLogin = () => {
    let token = get_data_value(userDataStore, "token");
    return !is_empty(token); //if valid token then true else false
  };

  if (!checkLogin()) {
    return <Redirect to={ROUTE_LOGIN} />;
  }
  return (
    <div>
      <div
        className={`user-page ${appDataStore.user_menu_collapsed ? "sidebar-icon-only" : ""
          } ${!empty(appDataStore.license_cancelled_message) ? "has-top-banner" : ""
          } ${!empty(userDataStore.action_required) ? "has-maintance-banner" : ""
          }`}
      >
        <div className="maintance-banner-bar text-semibold notify-default">
          {/* <div className="block t-show-desktop">
            <span className="text-bold">ATTENTION!</span>&nbsp; Our website is currently under maintenance. All functions will be back to normal by Sunday the 28th at 11 AM PST.<br />
            We will be excited to provide you with tons of new features and lightning speed!
          </div> */}
          <div className="block t-show-desktop">
            <span className="text-bold text-red">ACTION REQUIRED! </span>&nbsp; Please update all your software by clicking on the "HLX Updater" icon on your VPS's Desktop.<br />
            Once that's done, right-click on any of your software on your Meta Trader 4 under "Expert Advisors", and select "Refresh".
          </div>

          {/* <div className="t-show-mobile">
            <Marquee gradient={false}><span className="text-semibold">ATTENTION!</span>&nbsp; Our website is currently under maintenance. All functions will be back to normal by Sunday the 28th at 11 AM PST. We will be excited to provide you with tons of new features and lightning speed!</Marquee>
          </div> */}
          <div className="t-show-mobile">
            <Marquee gradient={false}><span className="text-semibold text-red">ACTION REQUIRED! </span>&nbsp; Please update all your software by clicking on the "HLX Updater" icon on your VPS's Desktop. Once that's done, right-click on any of your software on your Meta Trader 4 under "Expert Advisors", and select "Refresh".</Marquee>
          </div>
        </div>
        <div className="top-banner-bar notify-danger">
          <div className="t-show-mobile">
            <Marquee gradient={false}>{appDataStore.license_cancelled_message}</Marquee>
          </div>
          <div className="text-emphasis t-show-desktop">
            {appDataStore.license_cancelled_message}
          </div>
        </div>

        <UserLoader></UserLoader>
        <div className={`container-scroller ${userDataStore.question_passed === 0 ? 'blur-effect' : ''} `}>
          <UserSidebar askLogout={askLogout}></UserSidebar>
          <div className="container-fluid page-body-wrapper">
            <UserHeader askLogout={askLogout}></UserHeader>
            <div className="main-panel">
              <div className="content-wrapper">
                {
                  (userDataStore.question_passed === 1) ? (
                    <div>{props.children}</div>
                  ) : (
                    <></>
                  )
                }
              </div>
              <UserFooter></UserFooter>
            </div>
          </div>
        </div>

        
        {
          (userDataStore.question_passed === 0) ? (
            <UserQuestionBlock />
          ) : (
            <></>
          )
        }

        <ConfirmLogoutModal
          onClickYes={doLogout}
          visibleModal={showConfirmLogoutModal}
          setVisibleModal={setShowConfirmLogoutModal}
          modalClass="user-page confirm-modal"
        />

      </div>
    </div>
  );
};
export default UserLayout;
