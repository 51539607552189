import AdminLayout from "layouts/AdminLayout/AdminLayout";
import {
  ROUTE_ADMIN_TRADING_ROOM,
} from "navigation/CONSTANTS";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAppData } from "redux/actions/appActions";
import { updateUser } from "redux/actions/userActions";
import { apiAdminGetTradingRoomPageDetail, apiAdminUpdateTradingRoom } from "services/adminTradingRoomService";
import {
  empty,
  get_data_value,
  showToast,
  show_loading,
} from "utils/GlobalFunctions";
import { console_log } from "utils/logger";

const currentPageTitle = "Live Crypto Trading";
const AdminTradingRoomPage = () => {
  const userDataStore = useSelector((x) => x.userDataStore);
  const socketStore = useSelector((x) => x.socketStore);
  const socket = socketStore["socket"];
  useEffect(() => {
    if (!empty(socket)) {
      console_log("------------socketStore---------------", socketStore);
    }
  }, [socket]);

  const token = get_data_value(userDataStore, "token");
  const socketHeader = { token: token };

  const dispatch = useDispatch();
  const initPage = () => {
    dispatch(
      UpdateAppData({
        currentPageTitle: currentPageTitle,
        current_route: ROUTE_ADMIN_TRADING_ROOM,
        m_user_menu_collapsed: true,
      })
    );
  };
  useEffect(() => {
    initPage();
    getData();
  }, []);

  const defaultSettingData = {}
  const [settingData, setSettingData] = useState(defaultSettingData);
  const defaultPageData = {
    user: {},
    room_settings: defaultSettingData
  };
  const [pageData, setPageData] = useState(defaultPageData);
  const getData = () => {
    show_loading(true);
    apiAdminGetTradingRoomPageDetail()
      .then((api_res) => {
        show_loading(false);
        if (api_res.status === "1") {
          setPageData(api_res.data);
          setSettingData({ ...settingData, ...api_res.data['room_settings'] });
          dispatch(updateUser(api_res.data["user"]));
        } else {
          showToast(api_res.message, "error");
        }
      })
      .catch((err) => {
        show_loading(false);
        showToast(err, "error");
      });
  };

  const [errorField, setErrorField] = useState([]);

  const validateFields = () => {
    var errorList = Array();
    //errorList = isEmpty(settingData, "link", errorList);

    setErrorField([...errorList]);
    return errorList.length > 0 ? false : true;
  };

  const onChangeFormField = (e, field_name) => {
    //console.log("e:::", e)
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    let update_data = {};
    update_data[field_name] = e.target.value;
    setSettingData({ ...settingData, ...update_data });
  };

  const onChangeFormCheckboxField = (e, field_name) => {
    let update_data = {};
    update_data[field_name] = (e.target.checked ? "1" : "0");
    setSettingData({ ...settingData, ...update_data });
  };

  const submitData = () => {
    let is_valid = validateFields();
    if (is_valid) {
      show_loading(true);
      apiAdminUpdateTradingRoom({ ...settingData })
        .then((api_res) => {
          show_loading(false);
          if (api_res.status === "1") {
            dispatch(updateUser(api_res.data["user"]));
            showToast(api_res.message, "success");

            socket.emit('update_trading_room_settings', {
              ...socketHeader              
            })
          } else {
            showToast(api_res.message, "error");
            getData()
          }
        })
        .catch((err) => {
          show_loading(false);
          showToast(err, "error");
          getData()
        });
    }
  }

  return (
    <>
      <AdminLayout>
        <div className="admin-trading-room-page">
          <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title t-show-mobile">
                    {currentPageTitle}
                  </h4>
                  <div className="card-content mt-4">
                    <div className="row">

                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="link">
                            Zoom Meeting Link
                          </label>
                          <textarea
                            className={`form-control link ${errorField.includes("link")
                                ? "is-invalid"
                                : ""
                              }`}
                            name="link"
                            rows={`5`}
                            value={
                              !empty(settingData["link"])
                                ? settingData["link"]
                                : ""
                            }
                            onChange={(e) => {
                              onChangeFormField(e, "link");
                            }}
                          ></textarea>
                        </div>
                        <div className="form-group">
                          <div className="form-inline">
                            <label htmlFor="crypto_package_func">Status: &nbsp; </label>
                            <label className="toggle-switch">
                              <input
                                type="checkbox"
                                name="status"
                                id="status"
                                checked={settingData["status"] == "1"}
                                onChange={(e) =>
                                  onChangeFormCheckboxField(e, "status")
                                }
                              />
                              <span className="toggle-slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group mt-4">
                              <div className="button-group text-center">
                                <button
                                  type="button"
                                  className="mb-2 btn btn-lg1 btn-primary mr-2"
                                  onClick={(e) => {
                                    submitData()
                                  }}
                                >
                                  Update
                                </button>
                                <button
                                  type="reset"
                                  className="mb-2 btn btn-lg1 btn-light"
                                  onClick={(e) => {
                                    getData()
                                  }}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    </>
  );
};

export default AdminTradingRoomPage;
