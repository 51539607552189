import { DISCORD_LINK, FB_FIXEL_ID, TELEGRAM_COMMUNITY_GROUP_LINK } from "config/CONSTANTS";
import HomeLayout from "layouts/HomeLayout/HomeLayout";
import {
  ROUTE_BLOG_LIST,
  ROUTE_LOGIN,
} from "navigation/CONSTANTS";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { UpdateAppData } from "redux/actions/appActions";
import blogList from "assets/data/blog.json";
import Particles from "components/Particles/Particles";
import { Transition } from '@headlessui/react'
import Highlighter, { HighlighterItem } from 'components/Highlighter/Highlighter'

const BlogListPage = () => {
  const dispatch = useDispatch();
  const initPage = () => {
    let page_title = "Blog";
    dispatch(
      UpdateAppData({
        currentPageTitle: page_title,
        current_route: ROUTE_BLOG_LIST,
      })
    );
  };

  useEffect(() => {
    initPage();
  });

  useEffect(() => {
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(FB_FIXEL_ID) // facebookPixelId
        ReactPixel.pageView()
      })
  }, [])

  return (
    <div>
      <HomeLayout>
        <div className="main-content bg-front-page-gradient">
          <div
            className="section page-section container has-header content-sm pb-40 relative"
            id="page-section1"
          >
            <Particles className="absolute inset-0 -z-10" />

            <div className="px-3 pb-3">
              <h1 className="text-2xl inline-flex font-medium bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-purple-200 pb-3">Our Blog</h1>
            </div>

            <div className="container">
              <div className="row multi-columns-row">

                {
                  blogList.map((item, index) => {
                    const uri = `/article/${item.slug}`
                    return (
                      <div className="col-sm-6 col-md-4 col-lg-4 mb-60 mb-xs-40" key={index}>
                        <HighlighterItem className="rounded-2xl">
                          <div className="relative h-full bg-slate-900 rounded-[inherit] z-20 overflow-hidden blog-list-item">
                            <div className="post-prev-img">
                              <Link to={uri}>
                                <img src={`/assets/home/images/blog/${item.id}/thumb.jpg`} alt={item.title} />
                              </Link>
                            </div>

                            <div className="flex flex-col">
                              {/* Radial gradient */}
                              <div className="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/2 aspect-square" aria-hidden="true">
                                <div className="absolute inset-0 translate-z-0 bg-slate-800 rounded-full blur-[80px]" />
                              </div>
                              {/* Text */}
                              <div className="md:max-w-[480px] shrink-0 order-1 md:order-none p-4 md:p-6">
                                <div className="mb-1">
                                  <Link to={uri} className="inline-flex text-lg font-bold bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-1">{item.title}</Link>
                                </div>
                                <div className="mb-4">
                                  <Link to={uri} className="text-sm text-slate-400">{`${item.last_updated}, by ${item.author}`}</Link>
                                </div>

                                <Link className="btn-sm text-slate-300 hover:text-white transition duration-150 ease-in-out group [background:linear-gradient(theme(colors.slate.900),_theme(colors.slate.900))_padding-box,_conic-gradient(theme(colors.slate.400),_theme(colors.slate.700)_25%,_theme(colors.slate.700)_75%,_theme(colors.slate.400)_100%)_border-box] relative before:absolute before:inset-0 before:bg-slate-800/30 before:rounded-full before:pointer-events-none" to={uri}>
                                  <span className="relative inline-flex items-center">
                                    Read more <span className="tracking-normal text-purple-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">-&gt;</span>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </HighlighterItem>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </HomeLayout>
    </div>
  )
}

export default BlogListPage;
