import UserLayout from "layouts/UserLayout/UserLayout";
import { ROUTE_USER_SOFTWARE, ROUTE_USER_TRADING_ROOM } from "navigation/CONSTANTS";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { UpdateAppData } from "redux/actions/appActions";
import { updateUser } from "redux/actions/userActions";
import { apiUserGetTradingRoomPageDetail } from "services/userTradingRoomService";
import {
  empty,
  get_data_value,
  showToast,
  show_loading,
} from "utils/GlobalFunctions";
import { console_log } from "utils/logger";

const currentPageTitle = "Live Crypto Trading";
const UserTradingRoomPage = () => {
  const dispatch = useDispatch();

  //////////////////////////socket part////////////////////////////////
  const userDataStore = useSelector((x) => x.userDataStore);
  const socketStore = useSelector((x) => x.socketStore);
  const socket = socketStore["socket"];
  useEffect(() => {
    if (!empty(socket)) {
      console_log("------------socketStore---------------", socketStore);
      addSocketListener();
    }
  }, [socket]);

  const token = get_data_value(userDataStore, "token");
  const socketHeader = { token: token };

  const addSocketListener = () => {
    socket.off("update_trading_room_settings");
    socket.on("update_trading_room_settings", (data) => {
      console_log(
        "-------------update_trading_room_settings received---------",
        data
      );
      getData(false);
    });
  };
  ///////////////////////////end socket part/////////////////////////////


  const initPage = () => {
    dispatch(
      UpdateAppData({
        currentPageTitle: currentPageTitle,
        current_route: ROUTE_USER_TRADING_ROOM,
        m_user_menu_collapsed: true,
      })
    );
  };
  useEffect(() => {
    initPage();
    getData(true);
  }, []);

  const history = useHistory()

  const defaultPageData = {
    user: {},
    room_settings: {}
  };
  const [pageData, setPageData] = useState(defaultPageData);

  const getData = (loading = true) => {
    if (loading) {
      show_loading(true);
    }
    apiUserGetTradingRoomPageDetail()
      .then((api_res) => {
        show_loading(false);
        if (api_res.status === "1") {
          setPageData(api_res.data);
          dispatch(updateUser(api_res.data["user"]));
          let is_active_customer = api_res.data.is_active_customer
          if (is_active_customer == "0") {
            showToast("You must have an active membership", "error");
            history.push(ROUTE_USER_SOFTWARE)
            return false;
          }
        } else {
          showToast(api_res.message, "error");
        }
      })
      .catch((err) => {
        show_loading(false);
        showToast(err, "error");
      });
  };

  return (
    <UserLayout>
      <div className="user-live-trading-page">
        <div className={`row`}>
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title t-show-mobile">{currentPageTitle}</h4>

                {(pageData['room_settings']['id']) && (
                  <div className="card-content">
                    <div className="r-block">
                      <h4>
                        Join Dillon’s Private Trading Room every Wednesday at 03:30 PM EST!
                      </h4>
                      <br/>
                      <p className="text-normal">
                        The zoom meeting link is updated every week, and will be available 30 minutes prior to each trading call.
                      </p>                     
                      <p className="text-normal">
                        Enter Dillion’s private trading room by clicking the link below:
                      </p>

                      <div className="crypto-trading-link-block">
                        {
                          (pageData['room_settings']['status'] == 0) && (
                            <a className="crypto-trading-link crypto-trading-link-blank" href="#">
                              The link will appear here 30 minutes before the live trading call!
                            </a>
                          )
                        }
                        {
                          (pageData['room_settings']['status'] == 1) && (
                            <a className="crypto-trading-link" href={pageData['room_settings']['link']} target="_blank">
                              {pageData['room_settings']['link']}
                            </a>
                          )
                        }
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </UserLayout>
  );
};

export default UserTradingRoomPage;

