import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  get_data_value,
  isEmpty,
  jQuery,
  priceFormat,
  showToast,
} from "utils/GlobalFunctions";
import { APP_NAME, LICENSE_ANNUAL_PRICE, LICENSE_LIFE_TIME, LICENSE_PRICE, LICENSE_TRIAL_PRICE } from "config/CONSTANTS";
import {
  ROUTE_USER_DASHBOARD,
  ROUTE_USER_PAY_LICENSE,
} from "navigation/CONSTANTS";
import { useHistory } from "react-router-dom";
import SmartPackConfirmModal from "./SmartPackConfirmModal";
import SmartPackAlert from "./SmartPackAlert";
import { useDispatch, useSelector } from "react-redux";
import { updatePageData } from "redux/actions/pageActions";

function UserLicenseIntroPage(props) {
  const { currentPageTitle, pageData } = props;
  const pageDataStore = useSelector((x) => x.pageDataStore);
  const dispatch = useDispatch();
  const history = useHistory();
  const goPayLicensePage = () => {
    if (get_data_value(pageData, "is_active_customer") === 0) {
      dispatch(
        updatePageData({
          purchaseMembershipType: 1,
          purchasePromoCode: ""
        })
      );
      history.push(ROUTE_USER_PAY_LICENSE);
    } else {
      showToast("Invalid request", "error");
      history.push(ROUTE_USER_DASHBOARD);
    }
  };


  const onClickBuySmartPack = () => {
    setShowSmartPackConfirmModal(true)
  }
  const [showSmartPackConfirmModal, setShowSmartPackConfirmModal] = useState(false)
  const onSmarPackConfirmed = () => {
    setShowSmartPackConfirmModal(false)
    setShowSmartPackAlert(true)
  }
  const [showSmartPackAlert, setShowSmartPackAlert] = useState(false)
  const upgradeSmartPack = () => {
    setShowSmartPackAlert(false)
    dispatch(
      updatePageData({
        purchaseMembershipType: 2,
        purchasePromoCode: ""
      })
    );
    history.push(ROUTE_USER_PAY_LICENSE);
  }

  return (
    <>
      <div className={`row`}>
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title t-show-mobile">{currentPageTitle}</h4>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-horizontal">
                    <div className="card-content">
                      <div className="row">
                        <div className="col-md-6">
                          <h4 className="test-norma d-margin-top-20l">
                            You need to become a member to access all of our products.
                          </h4>
                          <div className="block mt-4">
                            <h4>Here is what you get:</h4>
                            <div className="pl-3">
                              <ul>
                                <li>Two software licenses</li>
                                <li>advanced HLX Academy</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="img-wrapper text-center vhalign h-100">
                            <img
                              className="img-responsive"
                              style={{ maxWidth: "120px" }}
                              src="/assets/home/images/20220510/full-access.png"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="block mt-4 mb-4">
            <div className="text-center">
              <span
                className={`text-normal`}
              >
                Monthly plan: ${priceFormat(LICENSE_PRICE)} / Every {LICENSE_LIFE_TIME} days <br />
                (or ${priceFormat(LICENSE_TRIAL_PRICE)} for 7 days if eligible)
              </span>
            </div>
            <div className="mt-4 text-center">
              <button
                type="button"
                className="btn btn-md btn-success add-license-number"
                style={{ width: "120px" }}
                onClick={(e) => {
                  goPayLicensePage();
                }}
              >
                Buy Now
              </button>
            </div>
          </div>

          <hr className="hr-3" />

          <div className="block mt-4 mb-4">
            <div className="text-center">
              <span
                className={`text-normal`}
              >
                OR... save almost 20% with our Smart Pack (${priceFormat(LICENSE_ANNUAL_PRICE)} / Year)
              </span>
            </div>
            <div className="mt-4 text-center">
              <button
                type="button"
                className="btn btn-md btn-success add-license-number"
                style={{ width: "120px" }}
                onClick={(e) => {
                  onClickBuySmartPack();
                }}
              >
                Buy Now
              </button>
            </div>
          </div>
        </div>

        <SmartPackConfirmModal
          modalTitle={`Upgrade to Smart Pack and Save Almost 20%!`}
          isVisible={showSmartPackConfirmModal}
          setVisibleModal={setShowSmartPackConfirmModal}
          submitModalData={onSmarPackConfirmed}
          modalClass="user-page smart-pack-modal"
        />
        <SmartPackAlert
          modalTitle={APP_NAME}
          isVisible={showSmartPackAlert}
          setVisibleModal={setShowSmartPackAlert}
          submitModalData={upgradeSmartPack}
          modalClass="user-page smart-pack-alert"
        />
      </div>
    </>
  );
}

export default UserLicenseIntroPage;
