// @flow strict

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import HomeLayout from "layouts/HomeLayout/HomeLayout";
import { ROUTE_ROOT } from "navigation/CONSTANTS";
import { UpdateAppData } from "redux/actions/appActions";
import { FB_FIXEL_ID } from "config/CONSTANTS";
import LandingSection from "./LandingSection";
import MembershipSection from "./MembershipSection";
import AcademySection from "./AcademySection";
import StellarSection from "./StellarSection";
import CryptonSection from "./CryptonSection";
import GoSection from "./GoSection";
import NovaSection from "./NovaSection";
import AppSection from "./AppSection";
import FeedbackSection from "./FeedbackSection";
import FooterSection from "./FooterSection";
import TradingSection from "./TradingSection";

import Hero from "./Hero";
import Clients from "./Clients";
import Features from "./Features";
import Features02 from "./Features02";
import Features03 from "./Features03";
import TestimonialsCarousel from "./TestimonialsCarousel";
import Features04 from "./Features04";
import Pricing from "./Pricing";
import Testimonials from "./Testimonials";
import Cta from "./Cta";
import PackageList from "./PackageList";
import PricingSection from "./PricingSection";

function SubHomePage(props) {
  return (
    <>
      <Hero />
      <Clients />
      <Features />
      <Features02 />
      {/* <Features03 /> */}
      <TestimonialsCarousel />
      {/* <Features04 /> */}
      {/* <Pricing /> */}
      <PricingSection />

      <Testimonials />
      <Cta />
    </>
  )
}

function HomePage(props) {
  const dispatch = useDispatch();

  const initPage = () => {
    let page_title = "Home";
    dispatch(
      UpdateAppData({
        currentPageTitle: page_title,
        current_route: ROUTE_ROOT,
      })
    );
    //document.title = page_title;
  };

  useEffect(() => {
    initPage();
  });

  useEffect(() => {
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(FB_FIXEL_ID) // facebookPixelId
        ReactPixel.pageView()
      })
  }, [])

  return (
    <div>
      <HomeLayout>
        <SubHomePage />
      </HomeLayout>
    </div>
  );
}
export default HomePage;
