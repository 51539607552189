import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { get_data_value, isEmpty, jQuery, showToast } from "utils/GlobalFunctions";
import { APP_NAME } from "config/CONSTANTS";

function HlxCryptoConfirmModal(props) {
  const { isVisible, setVisibleModal, submitModalData, modalClass, modalTitle } = props;
  const defaultModalData = {
    confirm_text: ""
  };
  useEffect(() => {
    if (isVisible !== undefined) {
      setShow(isVisible);
      setModalData(defaultModalData)

      setTimeout(function () {
        jQuery("#confirm_text").focus()
      }, 500)
    }
  }, [isVisible]);
  const [show, setShow] = useState(false);

  const [modalData, setModalData] = useState(defaultModalData);
  const [errorField, setErrorField] = useState([]);

  const validateFields = () => {
    var errorList = Array();
    errorList = isEmpty(modalData, "confirm_text", errorList);
    setErrorField([...errorList]);
    return errorList.length > 0 ? false : true;
  };

  const onChangeFormField = (e, field_name) => {
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    let modal_data = {}
    modal_data[field_name] = e.target.value;
    setModalData({ ...modalData, ...modal_data });
  };

  const onChangeKeyInput = (e, field_name) => {
    if (field_name === 'confirm_text') {
      if (e.key === 'Enter') {
        onSubmitModalData()
      }
    }
  };

  const handleClose = () => {
    setShow(false);
    setVisibleModal(false);
  };

  const onSubmitModalData = () => {
    let is_valid = validateFields();
    if (is_valid) {
      let confirmTxt = modalData['confirm_text']
      confirmTxt = confirmTxt.toLowerCase()
      if (confirmTxt === "crypto") {
        submitModalData(modalData);
        return true;
      }
    }
    showToast('Please type “Crypto” into the text field to proceed', "error");
  };
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        dialogClassName={modalClass}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="block mb-3 text-center">
            <div className="modal-box">
              <div id="dialog">
                <div className="text-normal text-left">
                  <label className="text-normal">
                  Type “Crypto” below to activate the HLX Crypto Add-on ($40 / month):
                  </label>
                  <div>
                    <input
                      type="text"
                      id="confirm_text"
                      className={`form-control ${errorField.includes("confirm_text") ? "is-invalid" : ""
                        }`}
                      placeholder=""
                      value={get_data_value(modalData, "confirm_text")}
                      onChange={(e) => {
                        onChangeFormField(e, "confirm_text");
                      }}
                      onKeyDown={(e) => {
                        onChangeKeyInput(e, "confirm_text")
                      }}
                    />
                  </div>
                </div>
                <div className="alert alert-warning mt-4 mb-0" role="alert">
                  <div className="note mb-0 text-left">
                    <p>
                      IMPORTANT: Only active customers can purchase this add-on, to active your Crypto Bot you must go to the “Membership” section and enter the trading account number you wish to use the Crypto Bot on. The Crypto Bot is already on your VPS.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-md btn-close-dlg btn-light margin-right-10"
            data-dismiss="modal"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-md btn-primary"
            onClick={onSubmitModalData}
          >
            Upgrade
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default HlxCryptoConfirmModal;
