import ConfirmModal from "components/ConfirmModal/ConfirmModal";
import { APP_NAME, BASE_FRONT_URL } from "config/CONSTANTS";
import UserLayout from "layouts/UserLayout/UserLayout";
import { ROUTE_USER_LICENSE } from "navigation/CONSTANTS";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAppData } from "redux/actions/appActions";
import { updateUser } from "redux/actions/userActions";
import {
  apiGetUserLicensePageDetail,
  apiUserUpdateCryptoLicenseInfo,
  apiUserUpdateLicenseInfo,
} from "services/userLicenseService";
import { get_data_value, showToast, show_loading } from "utils/GlobalFunctions";
import CryptoLicenseInfoModal from "./inc/CryptoLicenseInfoModal";
import LicenseInfoModal from "./inc/LicenseInfoModal";
import UserLicenseIntroPage from "./inc/UserLicenseIntroPage";

const currentPageTitle = "Membership";
const UserLicensePage = () => {
  const dispatch = useDispatch();
  const userDataStore = useSelector((x) => x.userDataStore);

  const initPage = () => {
    dispatch(
      UpdateAppData({
        currentPageTitle: currentPageTitle,
        current_route: ROUTE_USER_LICENSE,
        m_user_menu_collapsed: true,
      })
    );
  };

  useEffect(() => {
    initPage();
    getData();
  }, []);
  //const history = useHistory();
  const defaultPageData = {
    user: {},
    license_list: [],
    enabled_membership: "",
    is_active_customer: -1,
  };
  const [pageData, setPageData] = useState(defaultPageData);
  const getData = () => {
    show_loading(true);
    apiGetUserLicensePageDetail()
      .then((api_res) => {
        show_loading(false);
        if (api_res.status === "1") {
          setPageData(api_res.data);
          dispatch(updateUser(api_res.data["user"]));
        } else {
          showToast(api_res.message, "error");
        }
      })
      .catch((err) => {
        show_loading(false);
        showToast(err, "error");
      });
  };

  const [showCryptoLicenseInfoModal, setShowCryptoLicenseInfoModal] = useState(false);
  const [selectedCryptoLicense, setSelectedCryptoLicense] = useState({});
  const onEditCryptoLicense = (crypto_license_number, crypto_license_id) => {
    const license_item = {
      license_number: crypto_license_number,
      crypto_license_id: crypto_license_id
    }
    setSelectedCryptoLicense(license_item);
    setShowCryptoLicenseInfoModal(true);
  };
  const submitCryptoLicenseInfo = (info) => {
    show_loading(true);
    apiUserUpdateCryptoLicenseInfo(info)
      .then((api_res) => {
        show_loading(false);
        if (api_res.status === "1") {
          getData();
          setShowCryptoLicenseInfoModal(false);
        } else {
          showToast(api_res.message, "error");
        }
      })
      .catch((err) => {
        show_loading(false);
        showToast(err, "error");
      });
  };

  ///////////////////////////////////////////////////////////////////////////////

  const [showLicenseInfoModal, setShowLicenseInfoModal] = useState(false);
  const [selectedLicense, setSelectedLicense] = useState({});
  const onEditLicense = (license_item) => {
    setSelectedLicense(license_item);
    setShowLicenseInfoModal(true);
  };
  const submitLicenseInfo = (info) => {
    show_loading(true);
    apiUserUpdateLicenseInfo(info)
      .then((api_res) => {
        show_loading(false);
        if (api_res.status === "1") {
          getData();
          setShowLicenseInfoModal(false);
        } else {
          showToast(api_res.message, "error");
        }
      })
      .catch((err) => {
        show_loading(false);
        showToast(err, "error");
      });
  };

  const getMembershipText = ()=> {
    var txt = "Full Membership"
    if(pageData['user'] && (pageData['user']['membership_type'] === 4)) {
      txt = "Full Membership at a Discounted Rate"
    }else{
      txt = pageData['user']['membership_type'] == 2 ? "Annual Smart Pack" : "Full Subscription"
    }
    return txt
  }
  return (
    <UserLayout>
      <div className="user-license-page">
        {
          (pageData['is_active_customer'] == 0) && (
            <div>
              <UserLicenseIntroPage
                currentPageTitle={currentPageTitle}
                pageData={pageData}
              />
            </div>
          )
        }
        {
          (pageData['is_active_customer'] == 1) && (
            <div>
              <div className="row">
                <div className="col-md-12 grid-margin">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <h4 className="card-title">
                            {`HLX Forex:`}
                          </h4>

                          <div className="row">
                            <div className="col-md-12">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  height: "100%",
                                }}
                              >
                                <div className="mb-2">
                                  Membership:{" "}
                                  <span className="text-bold text-success">Active ({getMembershipText()})</span>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="available-product-list pt-4">
                                <h4 className="pb-2">Available Products:</h4>
                                <ul className="ml-4">
                                  {/* <li className="text-success">
                                    Alpha GI 2.0 (
                                    <span className="text-success">
                                      Available on Cloud
                                    </span>
                                    )
                                  </li> */}
                                  <li className="text-success">
                                    Stellar (
                                    <span className="text-success">
                                      Available on Cloud
                                    </span>
                                    )
                                  </li>
                                  <li className="text-success">
                                    Nova (
                                    <span className="text-success">
                                      Available on Cloud
                                    </span>
                                    )
                                  </li>
                                  <li className="text-success">
                                    GO (
                                    <span className="text-success">
                                      Available on Cloud
                                    </span>
                                    )
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="img-wrapper text-center vhalign h-100">
                            <img
                              className="img-responsive"
                              style={{ maxWidth: "120px" }}
                              src={pageData['user']['membership_type'] == 2 ? "/assets/home/images/20220510/smart-pack.png" : "/assets/home/images/20220510/full-access.png"}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }

        <LicenseInfoModal
          modalTitle={`Edit Broker Number`}
          isVisible={showLicenseInfoModal}
          setVisibleModal={setShowLicenseInfoModal}
          modalInitialData={selectedLicense}
          submitModalData={submitLicenseInfo}
          modalClass="user-page license-info-modal"
        />
        <CryptoLicenseInfoModal
          modalTitle={`Edit Trading Account Number:`}
          isVisible={showCryptoLicenseInfoModal}
          setVisibleModal={setShowCryptoLicenseInfoModal}
          modalInitialData={selectedCryptoLicense}
          submitModalData={submitCryptoLicenseInfo}
          modalClass="user-page crypto-license-info-modal"
        />

      </div>
    </UserLayout>
  );
};

export default UserLicensePage;
