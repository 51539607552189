import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { jQuery } from "utils/GlobalFunctions";

import 'aos/dist/aos.css';
import "./HomeLayout.css";
import HomeBottom from "./Includes/HomeBottom";
import HomeChatBox from "./Includes/HomeChatBox";
import HomeFooter from "./Includes/HomeFooter";
//import HomeHeader from "./Includes/HomeHeader";
import HomeLoader from "./Includes/HomeLoader";
import HomeNavbar from "./Includes/HomeNavbar";
import AOS from 'aos';
import HomeBottomScoller from "./Includes/HomeBottomScoller";

function HomeLayout(props) {
    const appDataStore = useSelector((x) => x.appDataStore);
    const queryParameters = new URLSearchParams(window.location.search)
    const hashId = queryParameters.get("a")
    const ts = queryParameters.get("ts")
    //console.log('href::::', hashId)

    useEffect(() => {
        if (hashId) {
            var scrollDiv = window.document.getElementById(hashId).offsetTop;
            window.scrollTo({ top: scrollDiv, behavior: 'smooth' });

            //window.scrollTo(0, 0);
        } else {
            window.scrollTo(0, 0);
        }
        jQuery(window).trigger('resize')
    }, [hashId, ts])

    useEffect(() => {
        //AOS.init();

        AOS.init({
            once: true,
            disable: 'phone',
            duration: 1000,
            easing: 'ease-out-cubic',
        })

    }, [])

    return (
        <div className="home-page front-page">
            <div className="font-inter antialiased bg-slate-900 text-slate-100 tracking-tight position-relative-z-index">
                <div className="flex flex-col min-h-screen overflow-hidden supports-[overflow:clip]:overflow-clip">
                    <HomeLoader />
                    <HomeNavbar />

                    <main className="grow" data-aos="fade">
                        {props.children}
                    </main>

                    <HomeFooter />
                    {/* <HomeChatBox /> */}
                    <HomeBottomScoller />
                    <HomeBottom />
                </div>
            </div>
        </div>
    )
}
export default HomeLayout;
