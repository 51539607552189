import { TELEGRAM_ANNOUNCEMENT_LINK, TELEGRAM_COMMUNITY_GROUP_LINK } from "config/CONSTANTS";
import {
  ROUTE_AFFILIATE_AGREEMENT,
  ROUTE_INCOME_DISCLAIME,
  ROUTE_LOGIN,
  ROUTE_PRIVACY_POLICY,
  ROUTE_REGISTER,
  ROUTE_RISK_WARNING,
  ROUTE_ROOT,
  ROUTE_TERMS_CONDITIONS,
  ROUTE_TRIAL_POLICY,
  ROUTE_USER_AGREEMENT,
} from "navigation/CONSTANTS";
import React from "react";
import { Link } from "react-router-dom";
import { Redirect, useHistory } from "react-router-dom";
import { get_utc_timestamp, get_utc_timestamp_ms } from "utils/GlobalFunctions";

const HomeFooter = () => {
  const history = useHistory()
  const onClickPricing = () => {
    const url = `${ROUTE_ROOT}?a=pricing&ts=${get_utc_timestamp_ms()}`
    history.push(url)
  }

  return (
    <footer>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">

        {/* Blocks */}
        <div className="grid sm:grid-cols-12 gap-8 py-8 md:py-12">

          {/* 1st block */}
          <div className="sm:col-span-12 lg:col-span-4 order-1 lg:order-none">
            <div className="h-full flex flex-col sm:flex-row lg:flex-col justify-between">
              <div className="mb-4 sm:mb-0">
                {/* <div className="mb-4">
                  <Link className="inline-flex" to="/" aria-label="Cruip">
                    <img className="max-w-none" src={`/themes/front/images/logo.svg`} width={38} height={38} alt="Stellar" />
                  </Link>
                </div> */}
                <div className="text-sm text-slate-300">HIGHER LEVEL FX™ is an educational company providing software, training and support with a unique focus on trading in the global currency market. <span className="text-slate-500">-</span> All rights reserved.</div>
              </div>
              {/* Social links */}
              <ul className="flex">
                <li>
                  <a
                    className="flex justify-center items-center text-purple-500 hover:text-purple-400 transition duration-150 ease-in-out social-icon-btn social-facebook-btn"
                    href="https://www.facebook.com/higherlevelfx/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img className="icon-img" src="/assets/home/images/20210314/facebook.png" alt="facebook" />
                  </a>
                </li>
                <li className="ml-3">
                  <a
                    className="flex justify-center items-center text-purple-500 hover:text-purple-400 transition duration-150 ease-in-out social-icon-btn social-facebook-btn"
                    href="https://www.instagram.com/higherlevelfx/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img className="icon-img" src="/assets/home/images/20210314/instagram.png" alt="instagram" />
                  </a>
                </li>
                <li className="ml-3">
                  <a
                    className="flex justify-center items-center text-purple-500 hover:text-purple-400 transition duration-150 ease-in-out social-icon-btn social-facebook-btn"
                    href={TELEGRAM_COMMUNITY_GROUP_LINK}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img className="icon-img" src="/assets/home/images/20220510/telegram.png" alt="telegram" />
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="sm:col-span-6 md:col-span-3 lg:col-span-2"></div>

          {/* 2nd block */}
          <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
            <h6 className="text-sm text-slate-50 font-medium mb-2">Resources</h6>
            <ul className="text-sm space-y-2">
              <li>
                <Link className="text-slate-400 hover:text-slate-200 transition duration-150 ease-in-out" to={ROUTE_REGISTER}>Sign Up</Link>
              </li>
              <li>
                {/* <a className="text-slate-400 hover:text-slate-200 transition duration-150 ease-in-out" href={`/#pricing`}>Pricing</a> */}
                {/* <Link className="text-slate-400 hover:text-slate-200 transition duration-150 ease-in-out" to={`${ROUTE_ROOT}?a=pricing`}>Pricing</Link> */}
                <span className="text-slate-400 hover:text-slate-200 transition duration-150 ease-in-out cursor-pointer" onClick={() => onClickPricing()}>Pricing</span>
              </li>
            </ul>
          </div>

          {/* 3rd block */}
          <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
            <h6 className="text-sm text-slate-50 font-medium mb-2">Contact</h6>
            <ul className="text-sm space-y-2">
              <li>
                <span className="text-slate-400 hover:text-slate-200 transition duration-150 ease-in-out">For business Inquiries: admin@higherlevelfx.com</span>
              </li>
              <li>
                <span className="text-slate-400 hover:text-slate-200 transition duration-150 ease-in-out">Address: Hunkins Waterfront Plaza, Suite 556, Main Street, Charlestown, Nevis, West indies, KN0802
                </span>
              </li>
            </ul>
          </div>

          {/* 5th block */}
          <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
            <h6 className="text-sm text-slate-50 font-medium mb-2">Legal</h6>
            <ul className="text-sm space-y-2">
              <li>
                <Link className="text-slate-400 hover:text-slate-200 transition duration-150 ease-in-out" to={ROUTE_USER_AGREEMENT}>User Agreement</Link>
              </li>
              <li>
                <Link className="text-slate-400 hover:text-slate-200 transition duration-150 ease-in-out" to={ROUTE_TERMS_CONDITIONS}>Terms & Conditions</Link>
              </li>
              <li>
                <Link className="text-slate-400 hover:text-slate-200 transition duration-150 ease-in-out" to={ROUTE_AFFILIATE_AGREEMENT}>Affiliate Agreement</Link>
              </li>
              <li>
                <Link className="text-slate-400 hover:text-slate-200 transition duration-150 ease-in-out" to={ROUTE_TRIAL_POLICY}>7-Day Trial & Promo Code Policy</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default HomeFooter;
