import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { empty, isEmpty, jQuery, showToast } from "utils/GlobalFunctions";
import ReactCodeInput from "react-code-input";

function TwoFactorCodeModal(props) {
  const { isVisible, setShowconfirmModal, submitModalData, modalClass, onCancelModal, apiLoading = false, setApiLoading } = props;
  useEffect(() => {
    if (isVisible !== undefined) {
      setShow(isVisible);
    }
  }, [isVisible]);
  const [show, setShow] = useState(false);
  const [code, setCode] = useState("");
  const validateFields = () => {
    return true
  }

  const handleClose = () => {
    setShow(false);
    setShowconfirmModal(false);
    if (!empty(onCancelModal)) {
      onCancelModal()
    }
  }

  const onChangeCode = (v) => {
    setCode(v)
  }

  const submitVerificationCode = () => {
    let is_valid = validateFields();
    if (is_valid) {
      submitModalData(code);
    } else {
      showToast("Please input 2-step verification code correctly", "error");
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        dialogClassName={modalClass}
      >
        <Modal.Header closeButton>
          <Modal.Title>Google 2-Step Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="block">
            <p className="margin-bottom-20 modal-header-desc">
              Enter the 6-digit code generated by your authenticator app.
            </p>
          </div>
          <div className="block margin-bottom-20 text-center">
            <div className="sms-verification-box--1 six-digits-box">
              <div>
                <div className="verification-form--1">
                  <ReactCodeInput
                    type='number'
                    fields={6}
                    value={code}
                    onChange={onChangeCode}
                    inputStyle={{
                      width: '50px',
                      //height: '50px',
                      fontSize: '40px',
                      autoFocus: true,
                      textAlign: 'center',
                      marginLeft: '2px',
                      marginRight: '2px',
                      borderRadius: '4px'
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="block">
            <p className="margin-bottom-10 modal-footer-desc">
              If you have trouble signing in, please contact our support!
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light btn-cancel-2fa mr-1"
            data-dismiss="modal"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn-complete-2fa btn btn-green btn-primary"
            onClick={submitVerificationCode}
            disabled={apiLoading}
          >
            Continue
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TwoFactorCodeModal;
