import { Link } from "react-router-dom";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import DataTable from "react-data-table-component";
import { useDispatch } from "react-redux";
import axios from "axios";
import Select from "react-select";
// import "react-select-2/dist/css/react-select-2.css";

import {
  empty,
  get_data_value,
  intval,
  showToast,
  show_loading,
  timeConverter,
} from "utils/GlobalFunctions";
import { urlUserGetTreeUserList } from "services/CONSTANTS";

const currentPageTitle = "User Referral list";
const UserReferralListPage = (props) => {
  const { pageData } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    updateUserSelectList();
  }, [pageData]);
  var userList = [];
  var defaultUserList = [{ value: 0, label: "All" }];

  const updateUserSelectList = () => {
    userList = defaultUserList;
    for (var key in pageData["personal_referral_list"]) {
      var item = pageData["personal_referral_list"][key];
      var option_item = { value: item["id"], label: item["user_name"] };
      userList.push(option_item);
    }
    setSelectUserList(userList);
  };
  const [selectUserList, setSelectUserList] = useState(userList);
  const [selectedUserID, setSelectedUserID] = useState(0);

  function onUserChange(val) {
    let newSearchVal = get_data_value(val, "value")
    let newSearchText = get_data_value(val, "label")
    if (newSearchVal === 0) {
      newSearchText = ""
    }
    setFilterText(newSearchText)
  }

  const defaultUserRankDetail = {
    user: {},
    active_personal_referral_list: [],
    personal_referral_list: [],
    missing_rank_list: {},
    missing_rank_message: "",
    next_rank_info: {},
    rank_info: {},
    rank_rule_list: {},
    tree_info: [],
    user_base_tree: [],
    destination_personal_referrals: 0,
    personal_referrals: 0,
    error_msg_arr: [],
    error_msg: "",
  };
  const [selectedUserItem, setSelectedUserItem] = useState({});
  const [selectedUserRankDetail, setSelectedUserRankDetail] = useState(
    defaultUserRankDetail
  );
  const [showRankModal, setShowRankModal] = useState(false);

  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  // const [deleted, setDeleted] = useState([]);
  const [currentSortField, setCurrentSortField] = useState("add_timestamp");
  const [currentSortDirection, setCurrentSortDirection] = useState("asc");
  const [userType, setUserType] = useState("personal_referral");

  const [filterText, setFilterText] = useState("");

  const [tableData, setTableData] = useState(pageData["personal_referral_list"]);

  const columns = useMemo(
    () => [
      {
        name: "Username",
        selector: "user_name",
        sortable: true,
      },
      {
        name: "Status",
        selector: "is_active",
        sortable: true,
        cell: (row) => (
          <span
            className={`text-bold ${row["is_active"] == "1" ? "text-success" : "text-danger"}`}
          >
            {row["is_active"] == "1" ? (row['is_trial'] ? "On Trial" : "Active") : "Inactive"}
          </span>
        ),
      },
      {
        name: "Last Renewal",
        selector: "last_payment_timestamp",
        sortable: true,
        cell: (row) => <span>{row.last_payment_timestamp > 0 ? timeConverter(row.last_payment_timestamp) : ""}</span>,
      },
    ],
    []
  );

  const colorStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      color: "black",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: "white",
        color: "black",
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: "#f16857",
          color: "white",
        },
        ":hover": {
          ...styles[":active"],
          backgroundColor: "#f16857",
          color: "white",
        },
      };
    },
    input: (styles) => ({
      ...styles,
      backgroundColor: "white",
      color: "black",
    }),
    placeholder: (styles) => ({
      ...styles,
      backgroundColor: "white",
      color: "black",
    }),
  };

  const subHeaderComponent = useMemo(() => {

    return (
      <div className="datatable-subheader">
        <div className="row">
          <div className="col-md-4">
            <form className="navbar-form margin-0" role="search">
              <div
                className="form-group form-search is-empty"
                style={{
                  position: "relative",
                  maxWidth: "360px",
                  paddingRight: "50px",
                }}
              >
                <Select
                  className="select2-box basic-single"
                  classNamePrefix="select"
                  defaultValue={defaultUserList[0]}
                  isDisabled={false}
                  isLoading={false}
                  isClearable={true}
                  isSearchable={true}
                  name="color"
                  options={selectUserList}
                  styles={colorStyles}
                  onChange={onUserChange}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }, [filterText, selectUserList]);

  const filteredItems = tableData.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const [showPersonalReferrals, setShowPersonalReferrals] = useState(false);
  const revealPersonalReferrals = () => {
    setShowPersonalReferrals(true)
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="card-content">
              <div className="block padding-bottom-5 padding-top-5">
                <div className="flex valign">
                  <h4 className="text-normal mb-3 mr-2">Your Personal Referrals:</h4>
                  {
                    (showPersonalReferrals) ? (
                      <></>
                    ) : (
                      <button type="button" className="btn btn-success mb-3" onClick={() => revealPersonalReferrals()}>Reveal</button>
                    )
                  }
                </div>
              </div>
              <div className={`block ${showPersonalReferrals ? "" : "hidden"}`}>
                <div className="divider-1 mb-4"></div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="datatable-wrapper">
                      <DataTable
                        theme="dark"
                        noHeader={true}
                        columns={columns}
                        data={filteredItems}
                        defaultSortField="is_active"
                        defaultSortAsc={false}
                        pagination
                        subHeader
                        subHeaderComponent={subHeaderComponent}
                        paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserReferralListPage;
