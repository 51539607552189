// @flow strict

import React from "react";
import { APPSTORE_LINK, GOOGLEPLAY_LINK } from "config/CONSTANTS";
import { ROUTE_REGISTER } from "navigation/CONSTANTS";
import { Link } from "react-router-dom";
import BuyButton from "./BuyButton";

const AppSection = (props) => {
  return (
    <section
      className="page-section pt-0 pb-0 banner-section bg-dark"
      data-background="/assets/home/images/20210420/white.jpg?v=1"
      style={{
        backgroundImage:"url('/assets/home/images/20210420/white.jpg?v=1')",
        borderTop: "2px solid #dddddd"
      }}
    >
      <div className="container relative">
        <div className="row">
          <div className="col-md-4 banner-image img-box wow fadeInDown">
            <img
              src="/assets/home/images/20210314/hlx-application.png"
              className="section-thumbnail"
              alt=""
            />
          </div>
          <div className="col-md-8">
            <div className="section-main-content">
              <div className="banner-content">
                <h3 className="banner-heading font-alt text-black">
                  HLX Trade Alerts App
                </h3>
                <div className="banner-decription text-normal-spacing">
                  <ul className="mt-40 list-1 m-text-left text-black">
                    <li>
                      This is a completely independent full-on platform used
                      to breakdown trades without needing your MT4!
                    </li>
                    <li>
                      Let us scan the market EVERY SECOND for you and send
                      you the most accurate trade alerts!
                    </li>
                    <li>You also get Live charts and Forex Calculators!</li>
                    <li>
                      Integrated Economic Calendar to keep you informed!
                    </li>
                  </ul>
                  <div className="appstore-btn-group pl-0">
                    <a className="appstore-btn apple-store" href={APPSTORE_LINK} target="_blank"><img src="/assets/home/images/20220615/appstore.png" /></a>
                    <a className="appstore-btn google-play" href={GOOGLEPLAY_LINK} target="_blank"><img src="/assets/home/images/20220615/google-play.png" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pb-4"></div>

    </section>
  )
}

export default AppSection;

