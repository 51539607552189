import { SYSTEM_ERROR } from "config/CONSTANTS";
import { axiosGet, axiosPost } from "./ajaxServices";
import { urlAdminGetTradingRoomPageDetail, urlAdminUpdateTradingRoom } from "./CONSTANTS";

export const apiAdminGetTradingRoomPageDetail = () => {
  const url = urlAdminGetTradingRoomPageDetail;
  return new Promise((resolve, reject) => {
    axiosGet(url, {}, SYSTEM_ERROR)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });   
};
export function apiAdminUpdateTradingRoom(params) {
  const url = urlAdminUpdateTradingRoom;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("link", params["link"])
  formData.append("status", params["status"])
    
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });   
} 

