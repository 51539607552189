import React, { useEffect, useState, useMemo } from "react";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  empty,
  get_data_value,
  intval,
  isEmpty,
  jQuery,
  showToast,
} from "utils/GlobalFunctions";
import { APP_NAME } from "config/CONSTANTS";
import DataTable from "react-data-table-component";

const AdminUserReferralsModal = (props) => {
  const {
    isVisible,
    setVisibleModal,
    pageData,
    currentRow,
    submitModalData,
    modalClass,
    modalTitle,
    userReferralsList,
  } = props;
  useEffect(() => {
    if (isVisible !== undefined) {
      setShow(isVisible);
      setTableData(userReferralsList)
    }
  }, [isVisible, pageData, currentRow, userReferralsList]);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setVisibleModal(false);
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [tableData, setTableData] = useState([]);

  const columns = useMemo(
    () => [
      {
        name: "USERNAME",
        selector: "user_name",
        sortable: true,
      },
      {
        name: "EMAIL",
        selector: "user_email",
        sortable: true,
      },
      {
        name: "STATUS",
        selector: "is_active",
        sortable: true,
        cell: (row) => (
          <span
            className={`text-bold ${row["is_active"] == "1" ? "text-success" : "text-danger"}`}
          >
            {row["is_active"] == "1" ? (row['is_trial'] ? "On Trial" : "Active") : "Inactive"}
          </span>
        ),
      },
    ],
    []
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <>
        <input
          id="search"
          type="text"
          placeholder="Search..."
          value={filterText}
          className="form-control input-search"
          onChange={(e) => setFilterText(e.target.value)}
        />
        <button className="a-btn btn-clear-search" onClick={handleClear}>
          x
        </button>
      </>
    );
  }, [filterText, resetPaginationToggle]);

  const filteredItems = tableData.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        dialogClassName={modalClass}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{`${currentRow['user_name']}'s Referrals`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="block text-center">
            <div className="modal-box">
              <div id="dialog">
                <div className="text-normal text-left">
                  <div className="text-left">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="datatable-wrapper">
                          <DataTable
                            theme="dark"
                            noHeader={true}
                            columns={columns}
                            data={filteredItems}                         
                            pagination
                            subHeader
                            subHeaderComponent={subHeaderComponent}
                            paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-md btn-close-dlg btn-light margin-right-10"
            data-dismiss="modal"
            onClick={handleClose}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AdminUserReferralsModal;
