import HomeLayout from "layouts/HomeLayout/HomeLayout";
import {
  ROUTE_FORGOT_PASSWORD,
  ROUTE_LOGIN,
  ROUTE_REGISTER,
  ROUTE_ROOT,
  ROUTE_TERMS_CONDITIONS,
  ROUTE_TRIAL_POLICY,
  ROUTE_USER_DASHBOARD,
  ROUTE_WELCOME,
} from "navigation/CONSTANTS";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAppData } from "redux/actions/appActions";
import { Link, withRouter } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import {
  empty,
  get_data_value,
  isEmpty,
  is_empty,
  showToast,
  show_loading,
  to_array,
  trim_phone,
  ValidateEmail,
  jQuery,
  intval,
  priceFormat,
} from "utils/GlobalFunctions";
import {
  apiCheckAuthSms,
  apiCheckCoupon,
  apiCheckPasswordStrength,
  apiCheckSponsor,
  apiGetAppSettings,
  apiGetAvailableServers,
  apiLogin,
  apiLogout,
  apiRegister,
  apiSendAuthSms,
} from "services/loginService";
import { updateUser } from "redux/actions/userActions";
import { countryList } from "utils/countryList";
import CreditCardForm from "components/CreditCardForm/CreditCardForm";
import { FB_FIXEL_ID, LICENSE_ANNUAL_PRICE, LICENSE_LIFE_TIME, LICENSE_PRICE, LICENSE_TRIAL_PRICE, REGISTER_FUNC, SMS_FUNC } from "config/CONSTANTS";
import AuthSmsCodeModal from "components/AuthSmsCodeModal/AuthSmsCodeModal";
import { console_log } from "utils/logger";
import Particles from "components/Particles/Particles";
import Pricing from "../HomePage/Pricing";
import PackageList from "../HomePage/PackageList";

const RegisterPage = (props) => {
  const query_params = new URLSearchParams(props.location.search);

  const ref_id = query_params.get("ref");
  console_log("---------ref_id--------", ref_id);
  const default_sponsor_name = empty(ref_id) ? "Admin" : ref_id;
  const [defaultSponsorName, setDefaultSponsorName] =
    useState(default_sponsor_name);
  const [sponsorName, setSponsorName] = useState(default_sponsor_name);

  const dispatch = useDispatch();
  const [showConfirmModal, setShowconfirmModal] = useState(false);
  const [smsModalTitle, setSmsModalTitle] = useState("Email verification");
  const [verificationMethod, setVerificationMethod] = useState("");

  const initPage = () => {
    let page_title = "Register";
    dispatch(
      UpdateAppData({
        currentPageTitle: page_title,
        current_route: ROUTE_REGISTER,
        license_cancelled_message: "",
      })
    );
    //document.title = page_title;
  };

  useEffect(() => {
    // if(REGISTER_FUNC === "disabled") {
    //   showToast("Registration temporarily disabled! Please check back later", "error")
    //   props.history.replace({ pathname: ROUTE_ROOT });
    // }
    initPage();
    getData();
    doLogout();
  }, []);

  useEffect(() => {
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(FB_FIXEL_ID) // facebookPixelId
        ReactPixel.pageView()
      })
  }, [])

  const showTwoStepCodeModal = () => {
    setShowconfirmModal(true);
  };

  const defaultSettingData = {
    maintenance_mode: "false",
    registration_func: "true",
    email_func: "true",
    sms_func: "true",
    total_server_count: 0,
  };
  const [settingData, setSettingData] = useState(defaultSettingData);
  const [pageData, setPageData] = useState({
    app_settings: defaultSettingData,
    server_count: 0,
  });

  const getData = () => {
    show_loading(true);
    apiGetAvailableServers()
      .then((api_res) => {
        show_loading(false);
        if (api_res.status === "1") {
          setPageData(api_res.data);
          setSettingData(api_res.data.app_settings);
          let is_maintance_mode = api_res.data.is_maintance_mode;
          if (is_maintance_mode) {
            showToast(api_res.data.maintance_msg, "error");
            props.history.replace({ pathname: ROUTE_ROOT });
            return false;
          }

          let server_count = intval(api_res.data.server_count);
          if (server_count <= 0) {
            showToast("Out of stock! Please check back later!", "error");
            props.history.replace({ pathname: ROUTE_ROOT });
          }
        } else {
          showToast(api_res.message, "error");
        }
      })
      .catch((err) => {
        show_loading(false);
        showToast(err, "error");
      });
  };

  //////////////////////////////////////////////////////////////////
  const userDataStore = useSelector((x) => x.userDataStore);
  let curYear = new Date().getFullYear();
  const initUserData = {
    user_name: "",
    user_first_name: "",
    user_last_name: "",
    user_phone: "",
    user_email: "",
    user_confirm_email: "",
    user_password: "",
    user_password1: "",
    user_type: "0",
    billing_street: "",
    billing_city: "",
    billing_state: "",
    billing_zip_code: "",
    billing_country: "US",
    owner: "",
    card_number: "",
    cvv: "",
    exp_month: "",
    exp_year: "",
    card_type: "",
    card_is_valid: false,
    agree_terms_condition: false,
    trial_policy: false,
  };
  const [userData, setUserData] = useState(initUserData);
  const [errorField, setErrorField] = useState([]);
  //const [isBusy, setIsBusy] = useState(false);

  const validateFields = () => {
    var errorList = [];
    errorList = isEmpty(userData, "user_name", errorList);
    errorList = isEmpty(userData, "user_first_name", errorList);
    errorList = isEmpty(userData, "user_last_name", errorList);
    errorList = isEmpty(userData, "user_phone", errorList);
    errorList = isEmpty(userData, "user_email", errorList);
    errorList = isEmpty(userData, "user_confirm_email", errorList);
    errorList = isEmpty(userData, "user_password", errorList);
    errorList = isEmpty(userData, "user_password1", errorList);
    errorList = isEmpty(userData, "user_type", errorList);
    errorList = isEmpty(userData, "billing_street", errorList);
    errorList = isEmpty(userData, "billing_city", errorList);
    errorList = isEmpty(userData, "billing_state", errorList);
    errorList = isEmpty(userData, "billing_zip_code", errorList);
    errorList = isEmpty(userData, "billing_country", errorList);
    errorList = isEmpty(userData, "owner", errorList);
    errorList = isEmpty(userData, "card_number", errorList);
    errorList = isEmpty(userData, "cvv", errorList);
    errorList = isEmpty(userData, "exp_month", errorList);
    errorList = isEmpty(userData, "exp_year", errorList);
    errorList = isEmpty(userData, "card_is_valid", errorList);
    errorList = isEmpty(userData, "agree_terms_condition", errorList);
    errorList = isEmpty(userData, "trial_policy", errorList);

    setErrorField([...errorList]);
    return errorList.length > 0 || errorField.length > 0 ? false : true;
  };
  const onChangeFormField = (e) => {
    let field_name = e.target.name;
    let field_value = e.target.value;
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    userData[field_name] = field_value;
    setUserData({ ...userData });

    if (field_name === "user_password" || field_name === "user_password1") {
      let password_matched = (userData['user_password'] === "" || userData['user_password1'] === "" || userData['user_password'] === userData['user_password1'])
      if (password_matched) {
        if (userData['user_password'] === userData['user_password1'] && userData['user_password'] !== "") {
          checkPasswordStrength(field_value);
          return false;
        }
        setPasswdStrengthGood(true)
      } else {
        setPasswdStrengthErrMessage("Both passwords entered must be identical.");
        setPasswdStrengthGood(false)
      }
    }

    if (field_name === "user_email" || field_name === "user_confirm_email") {
      let email_matched = (userData['user_email'] === "" || userData['user_confirm_email'] === "" || userData['user_email'] === userData['user_confirm_email'])
      setEmailMatched(email_matched)
    }

  };
  const onChangeFormCheckboxField = (e) => {
    let field_name = e.target.name;
    let checked = e.target.checked;
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    userData[field_name] = checked;
    setUserData({ ...userData });
  };
  const onChangePhoneField = (value, country, e, formattedValue) => {
    let phone_number = trim_phone(formattedValue);
    if (errorField.includes("user_phone")) {
      let errors = errorField.filter((x) => x !== "user_phone");
      setErrorField([...errors]);
    }
    userData["user_phone"] = phone_number;
    setUserData({ ...userData });
  };

  const submitModalData = (codeStr) => {
    show_loading(true);
    setApiLoading(true)
    apiCheckAuthSms(userData, codeStr, verificationMethod)
      .then((data) => {
        show_loading(false);
        setApiLoading(false)
        if (data.status === "1") {
          doRegister(codeStr);
        } else {
          showToast(data.message, "error");
        }
      })
      .catch((err) => {
        show_loading(false);
        setApiLoading(false)
        showToast(err, "error");
      });
  };
  const onClickRegister = () => {
    let is_valid = validateFields();
    if (is_valid) {
      if (sponsorChecking) {
        showToast(
          "Please wait until sponsor checking has been finished",
          "error"
        );
        return false;
      }
      if (sponsorModified) {
        showToast(
          "Please finish editing the sponsor name by clicking the check button",
          "error"
        );
        return false;
      }

      if (!emailMatched) {
        return false
      }

      if (!passwdStrengthGood) {
        return false
      }
      if (userData["user_email"] !== userData["user_confirm_email"]) {
        showToast("Invalid or not matching email address", "error");
        return false;
      }
      if (!ValidateEmail(userData["user_confirm_email"])) {
        showToast("Invalid or not matching email address", "error");
        return false;
      }
      if (userData["user_password"] !== userData["user_password1"]) {
        showToast("Password must match!", "error");
        return false;
      }
      if (!userData["agree_terms_condition"]) {
        showToast("Please agree to the terms and conditions", "error");
        return false;
      }
      if (!userData["trial_policy"]) {
        showToast("Please agree to the 7-Day Trial & Promo Code Policy", "error");
        return false;
      }
      if (settingData["sms_func"] === "false" && settingData["email_func"] === "false") {
        //if test mode then register directly
        doRegister();
      } else {
        //if live mode then first verify phone number or verify email address
        show_loading(true);
        apiSendAuthSms(userData)
          .then((data) => {
            show_loading(false);
            if (data.status === "1") {
              showToast(data.message, "success");
              let verification_method = data.data.verification_method
              setVerificationMethod(verification_method)
              if (verification_method === 'email') {
                setSmsModalTitle("Email verification")
              } else {
                setSmsModalTitle("Sms verification")
              }
              showTwoStepCodeModal();
            } else {
              showToast(data.message, "error");
            }
          })
          .catch((err) => {
            show_loading(false);
            showToast(err, "error");
          });
      }
    } else {
      window.scrollTo(0, 0); //
    }
  };
  const doRegister = (codeStr = "") => {
    show_loading(true);
    apiRegister({
      ...userData,
      ref: defaultSponsorName,
      coupon_applied: couponApplied,
      coupon: couponName,
      auth_code: codeStr,
      verificationMethod: verificationMethod
    })
      .then((data) => {
        if (data.status === "1") {
          //showToast("You are in", "success");
          dispatch(updateUser(data.data));
          setShowconfirmModal(false);
          setTimeout(() => {
            show_loading(false);
            props.history.replace({ pathname: ROUTE_WELCOME });
          }, 500);
        } else {
          show_loading(false);
          showToast(data.message, "error");
          setShowconfirmModal(false);
        }
      })
      .catch((err) => {
        show_loading(false);
        showToast(err, "error");
        setShowconfirmModal(false);
      });
  };

  const doLogout = () => {
    if (empty(userDataStore["token"])) {
      return false;
    }

    apiLogout(userDataStore["token"])
      .then((api_res) => { })
      .catch((err) => { });
    dispatch(updateUser({ token: "" }));
  };

  const [sponsorEdiable, setSponsorEdiable] = useState(false);
  const [sponsorModified, setSponsorModified] = useState(false);
  const [sponsorChecking, setSponsorChecking] = useState(false);
  const onClickEditSponsor = () => {
    setSponsorEdiable(true);
    setTimeout(function () {
      jQuery("#sponsor-name").focus();
    }, 100);
  };
  const onChangeSponsorName = (e) => {
    let field_value = e.target.value;
    setSponsorName(field_value);
    if (field_value === defaultSponsorName) {
      setSponsorModified(false);
    } else {
      setSponsorModified(true);
    }
  };
  const onClickCheckSponsor = () => {
    if (sponsorChecking || !sponsorModified) {
      return false;
    }
    setSponsorChecking(true);
    setTimeout(function () {
      doCheckSponsor();
    }, 500);
  };
  const doCheckSponsor = () => {
    apiCheckSponsor({ ref: sponsorName, default_sponsor: defaultSponsorName })
      .then((data) => {
        setSponsorChecking(false);

        if (data.status === "1") {
          setSponsorModified(false);
          setDefaultSponsorName(data.data);
          setSponsorName(data.data);
          setSponsorEdiable(false);
          showToast(data.message, "success");
        } else {
          setSponsorModified(false);
          setDefaultSponsorName(data.data.default_sponsor);
          setSponsorName(data.data.default_sponsor);
          setSponsorEdiable(false);
          showToast(data.message, "error");
        }
      })
      .catch((err) => {
        setSponsorChecking(false);
        showToast(err, "error");
      });
  };

  const [passwdStrengthGood, setPasswdStrengthGood] = useState(true);
  const [passwdStrengthErrMessage, setPasswdStrengthErrMessage] = useState("");

  const checkPasswordStrength = (password) => {
    if (password === "") {
      return false;
    }
    apiCheckPasswordStrength(password)
      .then((data) => {
        setSponsorChecking(false);
        if (data.status === "1") {
          setPasswdStrengthGood(true);
        } else {
          setPasswdStrengthGood(false);
          setPasswdStrengthErrMessage(data.message);
        }
      })
      .catch((err) => {
        setSponsorChecking(false);
        showToast(err, "error");
      });
  };

  const [couponName, setCouponName] = useState("");
  const [couponApplied, setCouponApplied] = useState(false);
  const [couponAppliedTxt, setCouponAppliedTxt] = useState("");
  const [couponChecking, setCouponChecking] = useState(false);
  const onChangeCouponName = (e) => {
    let field_value = e.target.value;
    setCouponName(field_value);
  };
  const onClickCheckCoupon = () => {
    if (couponChecking) {
      return false;
    }
    if (couponName === "") {
      setTimeout(function () {
        jQuery("#coupon-name").focus();
      }, 100);
      return false;
    }
    if (userData["user_type"] == "1") {
      showToast("You must choose gold package", "error");
      setCouponApplied(false);
      return false;
    }
    setCouponChecking(true);
    setTimeout(function () {
      doCheckCoupon();
    }, 500);
  };
  const doCheckCoupon = () => {
    apiCheckCoupon({ coupon: couponName })
      .then((data) => {
        setCouponChecking(false);
        if (data.status === "1") {
          setCouponApplied(true);
          setCouponAppliedTxt(data.message);
          showToast(data.message, "success");
        } else {
          setCouponApplied(false);
          showToast(data.message, "error");
        }
      })
      .catch((err) => {
        setCouponChecking(false);
        showToast(err, "error");
      });
  };

  const [emailMatched, setEmailMatched] = useState(true);
  const [emailMatchedErrMessage, setEmailMatchedErrMessage] = useState("Both email addresses entered must be identical.");

  const [apiLoading, setApiLoading] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    onClickRegister()
  }

  const onChangeUserType = (user_type) => {
    console.log(`user_type::::`, user_type)
    const user_data = {
      ...userData
    }
    user_data["user_type"] = user_type;
    setUserData({ ...user_data });
  }

  return (
    <div>
      <HomeLayout>
        <div className="main-content bg-front-page-gradient">
          <div
            className="section page-section container has-header content-sm relative"
            id="page-section1"
          >
            <Particles className="absolute inset-0 -z-10" />

            <div className="md:block absolute left-1/3 -translate-x-1/2 -mt-36 blur-2xl opacity-40 pointer-events-none -z-10" aria-hidden="true">
              <img src="/themes/front/images/auth-illustration.svg" className="max-w-none" width="1440" height="450" alt="Page Illustration" />
            </div>

            {/* <div className="max-w-3xl mx-auto text-center pb-12">
              <h1 className="h2 bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 auth-title">Registration:</h1>
            </div> */}

            <div className="mx-auto">
              <form onSubmit={handleSubmit}>
                <div className="container">
                  <div className="form-container reg-form-container">
                    <div className="reg-form">
                      <div className="logo-wrapper hidden">
                        <img
                          src="/assets/home/images/higherlevelfx_logo_tm.png"
                          className="img-responsive img-logo"
                          alt=""
                        />
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            className="user-type-block"
                          >
                            <h4 className="box-title text-normal-spacing text-center mt-0 mb-4">
                              Available Servers: {pageData["server_count"]}
                            </h4>
                            <div>
                              <PackageList
                                page="reigster"
                                initialMemberShip={1}
                                onChangeUserType={(v) => onChangeUserType(v)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="user-info-block">
                            <h1 className="box-title text-normal-spacing mt-0 mb-20 h1-auth-page-title">
                              Registration:
                            </h1>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label>Your Sponsor:</label>
                                  <div className="sponsor-check-block">
                                    <div className="sponsor-edit-block">
                                      <div>
                                        <input
                                          type="text"
                                          className="form-input w-full"
                                          id="sponsor-name"
                                          value={sponsorName}
                                          disabled={
                                            !sponsorEdiable || sponsorChecking
                                          }
                                          onChange={(e) => {
                                            onChangeSponsorName(e);
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="sponsor-btn-block">
                                      <button
                                        type="button"
                                        className={`btn btn-sponsor-check btn-spiner btn-round-normal btn-check-sponsor ${sponsorModified
                                          ? `text-white bg-purple-500 hover:bg-purple-600 w-full shadow-sm group`
                                          : `btn-outline-gray`
                                          } ${sponsorChecking ? `loading` : ``}`}
                                        onClick={(e) => {
                                          onClickCheckSponsor();
                                        }}
                                      >
                                        <span>Check</span>
                                        <i className="fa fa-refresh fa-spin"></i>
                                      </button>
                                    </div>
                                  </div>
                                  <div className="block margin-top-5">
                                    <span className="inline-flex font-medium bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-purple-200">
                                      Not the right Sponsor?
                                    </span>{" "}
                                    <span
                                      className="cursor-pointer text-teal-500"
                                      onClick={(e) => {
                                        onClickEditSponsor();
                                      }}
                                    >
                                      Edit <i className="ti-pencil-alt"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label>Username</label>
                                  <input
                                    id="user_name"
                                    type="text"
                                    name="user_name"
                                    className={`form-input w-full ${errorField.includes("user_name") ? "is-invalid" : ""
                                      }`}
                                    placeholder=""
                                    value={get_data_value(userData, "user_name")}
                                    onChange={(e) => {
                                      onChangeFormField(e);
                                    }}
                                    required
                                    disabled={apiLoading}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label>Mobile Number:</label>
                                  <div className="phone-number-box">
                                    <PhoneInput
                                      country={"us"}
                                      value={get_data_value(userData, "user_phone")}
                                      placeholder=""
                                      onChange={(
                                        value,
                                        country,
                                        e,
                                        formattedValue
                                      ) => {
                                        onChangePhoneField(
                                          value,
                                          country,
                                          e,
                                          formattedValue
                                        );
                                      }}
                                      inputProps={{
                                        type: "tel",
                                        className: "form-input w-full phone_number",
                                        id: "phone",
                                        name: "user_phone",
                                        placeholder: "",
                                      }}
                                    />

                                    <input type="hidden" name="phone_number" />
                                    <input type="hidden" name="dial_code" />
                                    <button
                                      type="button"
                                      className="a-btn btn-remove-tel"
                                      onClick={(e) => {
                                        onChangePhoneField("", "us", e, "");
                                      }}
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                  </div>
                                  <div className="block margin-top-5">
                                    <span className="inline-flex font-medium bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-purple-200">
                                      Code will be sent via Email or SMS!
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>First Name:</label>
                                  <input
                                    type="text"
                                    className={
                                      "form-input w-full" +
                                      (errorField.includes("user_first_name")
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="user_first_name"
                                    name="user_first_name"
                                    placeholder=""
                                    onChange={(e) => {
                                      onChangeFormField(e);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Last Name:</label>
                                  <input
                                    type="text"
                                    className={
                                      "form-input w-full" +
                                      (errorField.includes("user_last_name")
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="user_last_name"
                                    name="user_last_name"
                                    placeholder=""
                                    onChange={(e) => {
                                      onChangeFormField(e);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Email:</label>
                                  <input
                                    type="email"
                                    className={
                                      "form-input w-full" +
                                      (errorField.includes("user_email")
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="user_email"
                                    name="user_email"
                                    placeholder=""
                                    onChange={(e) => {
                                      onChangeFormField(e);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Confirm Email:</label>
                                  <input
                                    type="email"
                                    className={
                                      "form-input w-full" +
                                      (errorField.includes("user_confirm_email")
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="user_confirm_email"
                                    name="user_confirm_email"
                                    placeholder=""
                                    onChange={(e) => {
                                      onChangeFormField(e);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <div className={`block ${emailMatched ? `hidden` : ``}`} >
                                    <div
                                      className="block"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginTop: "-5px",
                                        marginBottom: "15px",
                                      }}
                                    >
                                      <img
                                        src="/assets/home/images/warning.svg"
                                        alt="warning"
                                        style={{ width: "1.5rem", marginRight: "0.6rem" }}
                                      />
                                      <span className="text-danger">
                                        {emailMatchedErrMessage}
                                      </span>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Password:</label>
                                  <input
                                    type="password"
                                    className={
                                      "form-input w-full" +
                                      (errorField.includes("user_password")
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="user_password"
                                    name="user_password"
                                    placeholder=""
                                    onChange={(e) => {
                                      onChangeFormField(e);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Confirm Password:</label>
                                  <input
                                    type="password"
                                    className={
                                      "form-input w-full" +
                                      (errorField.includes("user_password1")
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="user_password1"
                                    name="user_password1"
                                    placeholder=""
                                    onChange={(e) => {
                                      onChangeFormField(e);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div
                              className={`row ${passwdStrengthGood ? `hidden` : ``
                                }`}
                            >
                              <div className="col-md-12">
                                <div
                                  className="block"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "-5px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  <img
                                    src="/assets/home/images/warning.svg"
                                    alt="warning"
                                    style={{ width: "1.5rem", marginRight: "0.6rem" }}
                                  />
                                  <span className="text-danger">
                                    {passwdStrengthErrMessage}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            className="billing-info-block"
                            id="billing-info-block"
                          >
                            <h3 className="box-title text-normal-spacing mt-0 mb-20">
                              Billing Info:
                            </h3>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label>Street Address:</label>
                                  <input
                                    type="text"
                                    className={
                                      "form-input w-full" +
                                      (errorField.includes("billing_street")
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="billing_street"
                                    name="billing_street"
                                    placeholder=""
                                    onChange={(e) => {
                                      onChangeFormField(e);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>City:</label>
                                  <input
                                    type="text"
                                    className={
                                      "form-input w-full" +
                                      (errorField.includes("billing_city")
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="billing_city"
                                    name="billing_city"
                                    placeholder=""
                                    onChange={(e) => {
                                      onChangeFormField(e);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>State/Province:</label>
                                  <input
                                    type="text"
                                    className={
                                      "form-input w-full" +
                                      (errorField.includes("billing_state")
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="billing_state"
                                    name="billing_state"
                                    placeholder=""
                                    onChange={(e) => {
                                      onChangeFormField(e);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Zip code:</label>
                                  <input
                                    type="text"
                                    className={
                                      "form-input w-full" +
                                      (errorField.includes("billing_zip_code")
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="billing_zip_code"
                                    name="billing_zip_code"
                                    placeholder=""
                                    onChange={(e) => {
                                      onChangeFormField(e);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Country:</label>
                                  <select
                                    id="billing_country"
                                    name="billing_country"
                                    className={
                                      "form-select" +
                                      (errorField.includes("billing_country")
                                        ? " is-invalid"
                                        : "")
                                    }
                                    onChange={(e) => {
                                      onChangeFormField(e);
                                    }}
                                    value={get_data_value(
                                      userData,
                                      "billing_country",
                                      "US"
                                    )}
                                  >
                                    {countryList.map((countryItem, index) => (
                                      <option key={index} value={countryItem.code}>
                                        {countryItem.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="credit-card-block" id="credit-card-block">
                            <h3 className="box-title text-normal-spacing mt-0 mb-20">
                              Card Details:
                            </h3>
                            <div>
                              <CreditCardForm
                                userData={userData}
                                setUserData={setUserData}
                                errorField={errorField}
                                setErrorField={setErrorField}
                                inputClassName="form-input w-full"
                                selectClassName="form-select"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <>
                        {
                          (get_data_value(userData, "user_type", "0") !== "2") ? (
                            <>
                              <div className="block">
                                <h3 className="box-title text-normal-spacing mt-0 mb-20">
                                  Promo Code:
                                </h3>
                                <div className="form-group">
                                  <label>Enter Promo Code:</label>
                                  <div className="coupon-check-block">
                                    <div className="coupon-edit-block">
                                      <div>
                                        <input
                                          type="text"
                                          className="form-input w-full"
                                          id="coupon-name"
                                          value={couponName}
                                          onChange={(e) => {
                                            onChangeCouponName(e);
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="coupon-btn-block">
                                      <button
                                        type="button"
                                        className={`btn btn-spiner btn-check-coupon text-white bg-purple-500 hover:bg-purple-600 w-full shadow-sm group btn-round-normal 
                                ${couponChecking ? `loading` : ``}`}
                                        onClick={(e) => {
                                          onClickCheckCoupon();
                                        }}
                                        disabled={empty(couponName)}
                                      >
                                        <span>Apply</span>
                                        <i className="fa fa-refresh fa-spin"></i>
                                      </button>
                                    </div>
                                  </div>
                                  <div
                                    className={`block margin-top-5 ${couponApplied ? "" : "hidden"
                                      }`}
                                  >
                                    <span className="text-green">
                                      {couponAppliedTxt}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )
                        }
                      </>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="terms-condition-block">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="custom-checkbox form-group padding-top-20">
                                  <input
                                    type="checkbox"
                                    id="agree-terms-condition"
                                    name="agree_terms_condition"
                                    onChange={(e) => {
                                      onChangeFormCheckboxField(e);
                                    }}
                                  />
                                  <label htmlFor="agree-terms-condition">
                                    &nbsp;I Agree to the{" "}
                                    <Link
                                      to={ROUTE_TERMS_CONDITIONS}
                                      className="text-purple-500"
                                      target="_blank"
                                    >
                                      Terms and Conditions
                                    </Link>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="custom-checkbox form-group">
                                  <input
                                    type="checkbox"
                                    id="trial-policy"
                                    name="trial_policy"
                                    onChange={(e) => {
                                      onChangeFormCheckboxField(e);
                                    }}
                                  />
                                  <label htmlFor="trial-policy">
                                    &nbsp;I read and fully understood the{" "}
                                    <Link
                                      to={ROUTE_TRIAL_POLICY}
                                      className="text-purple-500"
                                      target="_blank"
                                    >
                                      7-Day Trial & Promo Code Policy
                                    </Link>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="reg-button-block">
                            <div className="row">
                              <div className="col-md-12">
                                <p className="register-desc form-group text-center mt-3 text-md text-slate-400">
                                  When you join Higher Level FX you have 2 ways to
                                  participate. Once your account has been created
                                  you can change the type of membership you have
                                  inside of your back office!
                                </p>
                                <p className="register-desc form-group text-center text-md text-slate-400">
                                  This transaction may appear on the cardholder bank
                                  statement as “HIGHER LEVEL FX”.
                                </p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group text-center">
                                  <div className="mt-6">
                                    <input
                                      type="hidden"
                                      name="action"
                                      value="submit"
                                    />

                                    <button
                                      type="submit"
                                      className="btn text-sm text-white bg-purple-500 hover:bg-purple-600 w-full shadow-sm group"
                                      disabled={apiLoading}
                                      style={{ maxWidth: '280px' }}
                                    >
                                      Pay & Register
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        {showConfirmModal && (
          <AuthSmsCodeModal
            title={smsModalTitle}
            verificationMethod={verificationMethod}
            isVisible={true}
            setShowconfirmModal={setShowconfirmModal}
            submitModalData={submitModalData}
            modalClass="user-page "
            apiLoading={apiLoading}
            setApiLoading={setApiLoading}
          />
        )}
      </HomeLayout>
    </div>
  );
};
export default withRouter(RegisterPage);
