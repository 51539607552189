import { SYSTEM_ERROR } from "config/CONSTANTS";
import { axiosGet, axiosPost } from "./ajaxServices";
import { urlAdminGetVpsConsoleUrl, urlAdminGetVpsPassword } from "./CONSTANTS";

 export const apiAdminGetVpsPassword = (userData) => {
  const url = urlAdminGetVpsPassword;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("user_id", userData.id); 

  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });   
};

export const apiAdminGetVpsConsoleUrl = (userData) => {
  const url = urlAdminGetVpsConsoleUrl;
  let params = {user_id: userData.id}
  return new Promise((resolve, reject) => {
    axiosGet(url, params, SYSTEM_ERROR)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
