import AdminLayout from "layouts/AdminLayout/AdminLayout";
import {
  ROUTE_ADMIN_SETTING,
} from "navigation/CONSTANTS";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAppData } from "redux/actions/appActions";
import { updateUser } from "redux/actions/userActions";
import {
  apiAdminGetSettingPageDetail,
  apiAdminUpdateSetting,
} from "services/adminSettingService";
import {
  empty,
  get_data_value,
  intval,
  isEmpty,
  showToast,
  show_loading,
  timeConverter,
} from "utils/GlobalFunctions";
import { console_log } from "utils/logger";

const MAX_GRACE_DAYS = 6;

const currentPageTitle = "Setting";
const AdminSettingPage = () => {
  const userDataStore = useSelector((x) => x.userDataStore);

  ///////////////////////////////////socket part///////////////////////////////////////////
  const socketStore = useSelector((x) => x.socketStore);
  const socket = socketStore["socket"];
  useEffect(() => {
    if (!empty(socket)) {
      console_log("------------socketStore---------------", socketStore);
    }
  }, [socket]);

  const token = get_data_value(userDataStore, "token");
  const socketHeader = { token: token };

  const dispatch = useDispatch();
  const initPage = () => {
    dispatch(
      UpdateAppData({
        currentPageTitle: currentPageTitle,
        current_route: ROUTE_ADMIN_SETTING,
        m_user_menu_collapsed: true,
      })
    );
  };
  useEffect(() => {
    initPage();
    getData();
  }, []);

  const defaultSettingData = {
    action_required: "false",
    maintenance_mode: "false",
    maintenance_hour: "",
    registration_func: "false",
    email_func: "false",
    sms_func: "false",
    crypto_package_func: "false",
    total_server_count: 0,
    admin_ticket_email: "",
    vps_creation_time: 0,
    grace_days: 0,
    hlx_api_key: ""
  }
  const [settingData, setSettingData] = useState(defaultSettingData);
  const defaultPageData = {
    user: {},
    app_settings: defaultSettingData
  };
  const [pageData, setPageData] = useState(defaultPageData);
  const getData = () => {
    show_loading(true);
    apiAdminGetSettingPageDetail()
      .then((api_res) => {
        show_loading(false);
        if (api_res.status === "1") {
          setPageData(api_res.data);
          setSettingData({ ...settingData, ...api_res.data['app_settings'] });
          dispatch(updateUser(api_res.data["user"]));
        } else {
          showToast(api_res.message, "error");
        }
      })
      .catch((err) => {
        show_loading(false);
        showToast(err, "error");
      });
  };

  const [errorField, setErrorField] = useState([]);

  const validateFields = () => {
    var errorList = Array();
    errorList = isEmpty(settingData, "total_server_count", errorList);
    errorList = isEmpty(settingData, "grace_days", errorList);

    setErrorField([...errorList]);
    return errorList.length > 0 ? false : true;
  };

  const onChangeFormField = (e, field_name) => {
    //console.log("e:::", e)
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    let update_data = {};
    update_data[field_name] = e.target.value;
    setSettingData({ ...settingData, ...update_data });
  };

  const onChangeFormCheckboxField = (e, field_name) => {
    let update_data = {};
    update_data[field_name] = (e.target.checked ? "true" : "false");
    setSettingData({ ...settingData, ...update_data });
  };

  const submitData = () => {
    let is_valid = validateFields();
    if (is_valid) {
      let grace_days = settingData['grace_days']
      if(grace_days > MAX_GRACE_DAYS) {
        showToast("Grace days must be less than " + ( MAX_GRACE_DAYS + 1 ), "error")
        return false;
      }

      show_loading(true);
      apiAdminUpdateSetting({ ...settingData })
        .then((api_res) => {
          show_loading(false);
          if (api_res.status === "1") {
            dispatch(updateUser(api_res.data["user"]));
            showToast(api_res.message, "success");

            if (settingData['maintenance_mode'] == "true") {
              socket.emit("do_logout", { ...socketHeader });
            }
          } else {
            showToast(api_res.message, "error");
            getData()
          }
        })
        .catch((err) => {
          show_loading(false);
          showToast(err, "error");
          getData()
        });
    }
  }

  const showCronStatus = (cronjob_test_field) => {
    if (cronjob_test_field) {
      const splitter = " - "
      const arr = cronjob_test_field.split(splitter)
      if (arr.length === 2) {
        const api_version = arr[0]
        const cron_timestamp = arr[1].trim()
        return (
          <div>
            <p>API Version: {api_version}</p>
            <p>Cron Time: <span>{timeConverter(intval(cron_timestamp))}</span></p>
          </div>
        )
      } else {
        return (
          <div>
            <p>Cron Status: {cronjob_test_field}</p>
          </div>
        )
      }
    } else {
      return (
        <div></div>
      )
    }
  }

  return (
    <>
      <AdminLayout>
        <div className="admin-setting-page">
          <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title t-show-mobile">
                    {currentPageTitle}
                  </h4>
                  <div className="card-content mt-4">
                    <div className="row cron-status-field hidden">
                      <div className="col-md-12">
                        <div className="form-group">
                          {showCronStatus(settingData['cronjob_test_field'])}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group hidden">
                          <div className="form-inline">
                            <label htmlFor="action_required">
                              Action required: &nbsp;{" "}
                            </label>
                            <label className="toggle-switch">
                              <input
                                type="checkbox"
                                name="action_required"
                                id="action_required"
                                checked={settingData["action_required"] == "true"}
                                onChange={(e) =>
                                  onChangeFormCheckboxField(
                                    e,
                                    "action_required"
                                  )
                                }
                              />
                              <span className="toggle-slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="form-inline valign">
                            <label htmlFor="maintenance_mode" className="mb-2 mt-2">
                              Maintenance mode: &nbsp;{" "}
                            </label>
                            <label className="toggle-switch mb-2 mt-2 mr-4">
                              <input
                                type="checkbox"
                                name="maintenance_mode"
                                id="maintenance_mode"
                                checked={settingData["maintenance_mode"] == "true"}
                                onChange={(e) =>
                                  onChangeFormCheckboxField(
                                    e,
                                    "maintenance_mode"
                                  )
                                }
                              />
                              <span className="toggle-slider round"></span>
                            </label>
                            {(settingData['maintenance_mode'] !== "false") && (
                              <>
                                <span className="t-show-mobile w-100"></span>
                                <label htmlFor="maintenance_hour" className="mb-2 mt-2">
                                  Hour: &nbsp;{" "}
                                </label>
                                <input
                                  type="number"
                                  min={0}
                                  placeholder="Maintenance hour"
                                  className={`form-control maintenance_hour ${errorField.includes("maintenance_hour")
                                    ? "is-invalid"
                                    : ""
                                    }`}
                                  id="maintenance_hour"
                                  name="maintenance_hour"
                                  style={{ width: "6rem" }}
                                  value={
                                    !empty(settingData["maintenance_hour"])
                                      ? settingData["maintenance_hour"]
                                      : ""
                                  }
                                  onChange={(e) =>
                                    onChangeFormField(e, "maintenance_hour")
                                  }
                                />
                              </>
                            )}
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="form-inline">
                            <label htmlFor="registration_func">
                              Registration: &nbsp;{" "}
                            </label>
                            <label className="toggle-switch">
                              <input
                                type="checkbox"
                                name="registration_func"
                                id="registration_func"
                                checked={settingData["registration_func"] == "true"}
                                onChange={(e) =>
                                  onChangeFormCheckboxField(
                                    e,
                                    "registration_func"
                                  )
                                }
                              />
                              <span className="toggle-slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="form-inline">
                            <label htmlFor="email_func">Email sending: &nbsp; </label>
                            <label className="toggle-switch">
                              <input
                                type="checkbox"
                                name="email_func"
                                id="email_func"
                                checked={settingData["email_func"] == "true"}
                                onChange={(e) =>
                                  onChangeFormCheckboxField(e, "email_func")
                                }
                              />
                              <span className="toggle-slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="form-inline">
                            <label htmlFor="sms_func">SMS sending: &nbsp; </label>
                            <label className="toggle-switch">
                              <input
                                type="checkbox"
                                name="sms_func"
                                id="sms_func"
                                checked={settingData["sms_func"] == "true"}
                                onChange={(e) =>
                                  onChangeFormCheckboxField(e, "sms_func")
                                }
                              />
                              <span className="toggle-slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="form-inline">
                            <label htmlFor="crypto_package_func">HLX Crypto: &nbsp; </label>
                            <label className="toggle-switch">
                              <input
                                type="checkbox"
                                name="crypto_package_func"
                                id="crypto_package_func"
                                checked={settingData["crypto_package_func"] == "true"}
                                onChange={(e) =>
                                  onChangeFormCheckboxField(e, "crypto_package_func")
                                }
                              />
                              <span className="toggle-slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="total_server_count">
                            Server limitation
                          </label>
                          <input
                            type="number"
                            min={0}
                            placeholder=""
                            className={`form-control total_server_count ${errorField.includes("total_server_count")
                              ? "is-invalid"
                              : ""
                              }`}
                            id="total_server_count"
                            name="total_server_count"
                            value={
                              !empty(settingData["total_server_count"])
                                ? settingData["total_server_count"]
                                : ""
                            }
                            onChange={(e) =>
                              onChangeFormField(e, "total_server_count")
                            }
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="admin_ticket_email">
                            Admin ticket email
                          </label>
                          <input
                            type="text"
                            placeholder=""
                            className={`form-control admin_ticket_email ${errorField.includes("admin_ticket_email")
                              ? "is-invalid"
                              : ""
                              }`}
                            id="admin_ticket_email"
                            name="admin_ticket_email"
                            value={
                              !empty(settingData["admin_ticket_email"])
                                ? settingData["admin_ticket_email"]
                                : ""
                            }
                            onChange={(e) =>
                              onChangeFormField(e, "admin_ticket_email")
                            }
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="vps_creation_time">
                            VPS creation time (minutes)
                          </label>
                          <input
                            type="number"
                            min={0}
                            placeholder=""
                            className={`form-control vps_creation_time ${errorField.includes("vps_creation_time")
                              ? "is-invalid"
                              : ""
                              }`}
                            id="vps_creation_time"
                            name="vps_creation_time"
                            value={
                              !empty(settingData["vps_creation_time"])
                                ? settingData["vps_creation_time"]
                                : ""
                            }
                            onChange={(e) =>
                              onChangeFormField(e, "vps_creation_time")
                            }
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="grace_days">
                            Grace period (days)
                          </label>
                          <input
                            type="number"
                            min={0}
                            max={MAX_GRACE_DAYS}
                            placeholder=""
                            className={`form-control grace_days ${errorField.includes("grace_days")
                              ? "is-invalid"
                              : ""
                              }`}
                            id="grace_days"
                            name="grace_days"
                            value={
                              !empty(settingData["grace_days"])
                                ? settingData["grace_days"]
                                : ""
                            }
                            onChange={(e) =>
                              onChangeFormField(e, "grace_days")
                            }
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="hlx_api_key">
                            Api Key
                          </label>
                          <input
                            type="text"
                            placeholder=""
                            className={`form-control hlx_api_key ${errorField.includes("hlx_api_key")
                              ? "is-invalid"
                              : ""
                              }`}
                            id="hlx_api_key"
                            name="hlx_api_key"
                            value={
                              !empty(settingData["hlx_api_key"])
                                ? settingData["hlx_api_key"]
                                : ""
                            }
                            onChange={(e) =>
                              onChangeFormField(e, "hlx_api_key")
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group mt-4">
                              <div className="button-group text-center">
                                <button
                                  type="button"
                                  className="mb-2 btn btn-lg1 btn-primary mr-2"
                                  onClick={(e) => {
                                    submitData()
                                  }}
                                >
                                  Update
                                </button>
                                <button
                                  type="reset"
                                  className="mb-2 btn btn-lg1 btn-light"
                                  onClick={(e) => {
                                    getData()
                                  }}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    </>
  );
};

export default AdminSettingPage;
