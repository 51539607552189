import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Particles from "components/Particles/Particles";
import { ROUTE_REGISTER } from "navigation/CONSTANTS";
import PackageList from "./PackageList";

const PricingSection = (props) => {

  return (
    <section className="relative" id="pricing">
      {/* Radial gradient */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none -z-10" aria-hidden="true">
        <div className="absolute flex items-center justify-center top-0 -translate-y-1/2 left-1/2 -translate-x-1/2 w-1/3 aspect-square">
          <div className="absolute inset-0 translate-z-0 bg-purple-500 rounded-full blur-[120px] opacity-50" />
        </div>
      </div>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Content */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <div>
              <div className="inline-flex font-medium bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-purple-200 pb-3">Pricing plans</div>
            </div>
            <h2 className="h2 bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-4">Flexible plans and features</h2>
            <p className="text-lg text-slate-400">No contract, cancel anytime! Please note that if the membership isn't cancelled within the 7-day trial it will automatically go to a normal membership and being charged $179.00 / 30 days.</p>
          </div>

          <div className="relative">
            {/* Blurred shape */}
            <div className="max-md:hidden absolute bottom-0 -mb-20 left-2/3 -translate-x-1/2 blur-2xl opacity-70 pointer-events-none" aria-hidden="true">
              <svg xmlns="http://www.w3.org/2000/svg" width="434" height="427">
                <defs>
                  <linearGradient id="bs5-a" x1="19.609%" x2="50%" y1="14.544%" y2="100%">
                    <stop offset="0%" stopColor="#A855F7" />
                    <stop offset="100%" stopColor="#6366F1" stopOpacity="0" />
                  </linearGradient>
                </defs>
                <path fill="url(#bs5-a)" fillRule="evenodd" d="m661 736 461 369-284 58z" transform="matrix(1 0 0 -1 -661 1163)" />
              </svg>
            </div>
            {/* Content */}

            <div style={{ margin: 'auto' }}>
              <PackageList
                page="home"
                initialMemberShip={1}
              />
            </div>
          </div>
        </div>
      </div>

    </section>
  )
}

export default PricingSection;
