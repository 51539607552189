import HomeLayout from "layouts/HomeLayout/HomeLayout";
import {
  ROUTE_ADMIN_DASHBOARD,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_FREE_BOOK,
  ROUTE_LOGIN,
  ROUTE_REGISTER,
  ROUTE_USER_DASHBOARD,
} from "navigation/CONSTANTS";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAppData } from "redux/actions/appActions";
import ReCAPTCHA from "react-google-recaptcha";
import { googleKey } from "config/CONSTANTS";
import { Link, withRouter } from "react-router-dom";
import TwoFactorCodeModal from "components/TwoFactorCodeModal/TwoFactorCodeModal";
import {
  empty,
  get_data_value,
  isEmpty,
  is_empty,
  showToast,
  to_array,
} from "utils/GlobalFunctions";
import {
  console_log,
} from "utils/logger";
import {
  apiCheckTwoFactAuth,
  apiLogin,
  apiLoginTwoFactAuth,
  apiLogout,
} from "services/loginService";
import { updateUser } from "redux/actions/userActions";
import Particles from "components/Particles/Particles";

const LoginPage = (props) => {
  const dispatch = useDispatch();
  const [showConfirmModal, setShowconfirmModal] = useState(false);
  const initPage = () => {
    let page_title = "Login";
    dispatch(
      UpdateAppData({
        currentPageTitle: page_title,
        current_route: ROUTE_LOGIN,
        license_cancelled_message: "",
      })
    );
  };

  useEffect(() => {
    initPage();
    doLogout()
  }, []);

  const showTwoStepCodeModal = () => {
    setShowconfirmModal(true);
  };

  //////////////////////////////////////////////////////////////////
  const userDataStore = useSelector((x) => x.userDataStore);
  const userInfo = userDataStore;
  console_log("userInfo", userInfo);
  const initUserData = { user_name: "", user_password: "" };
  const [userData, setUserData] = useState(initUserData);
  const [errorField, setErrorField] = useState([]);
  const [apiLoading, setApiLoading] = useState(false);

  const getLoginAttemptCount = () => {
    let login_attempt_count = get_data_value(userInfo, "login_attempt_count");
    if (is_empty(login_attempt_count)) {
      login_attempt_count = 0;
    } else {
      login_attempt_count = parseInt(login_attempt_count);
    }
    return login_attempt_count;
  };
  const checkCaptchaEnabled = () => {
    let login_attempt_count = getLoginAttemptCount();
    if (login_attempt_count >= 3) {
      return true;
    } else {
      return false;
    }
  };
  const [recaptchaEnable, setRecaptchaEnable] = useState(checkCaptchaEnabled());
  const updateLoginAttemptCount = (count) => {
    if (count === null || count === undefined) {
      count = getLoginAttemptCount();
      count++;
    }
    userInfo["login_attempt_count"] = count;
    dispatch(updateUser({ login_attempt_count: count }));
    setRecaptchaEnable(checkCaptchaEnabled());
  };

  const [recaptchaValid, setRecaptchaValid] = useState(false);
  const reCaptchaChanged = (value) => {
    console_log("reCaptchaChanged::::", value);
    if (value) {
      setRecaptchaValid(true);
    } else {
      setRecaptchaValid(false);
    }
  };

  let captcha;
  const setCaptchaRef = (ref) => {
    if (ref) {
      return (captcha = ref);
    }
  };
  const resetCaptcha = () => {
    // maybe set it till after is submitted
    captcha.reset();
  };

  const validateFields = () => {
    var errorList = Array();
    errorList = isEmpty(userData, "user_name", errorList);
    errorList = isEmpty(userData, "user_password", errorList);
    setErrorField([...errorList]);
    return errorList.length > 0 ? false : true;
  };
  const onChangeFormField = (e, field_name) => {
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    userData[field_name] = e.target.value;
    setUserData({ ...userData });
  };
  const submitModalData = (codeStr) => {
    console_log("codeStr::::", codeStr);
    setApiLoading(true);
    apiLoginTwoFactAuth(userData, codeStr)
      .then((api_res) => {
        if (api_res.status === "1") {
          doLogin(api_res.data);
        } else {
          setApiLoading(false);
          showToast(api_res.message, "error");
        }
      })
      .catch((err) => {
        setApiLoading(false);
        showToast(err, "error");
      });
  };
  const onClickLogin = () => {
    let is_valid = validateFields();
    console_log("is valid", is_valid);
    if (is_valid) {
      if (recaptchaEnable) {
        if (!recaptchaValid) {
          showToast("Please confirm that you're not a robot", "error");
          return false;
        }
      }
      setApiLoading(true);
      apiLogin(userData)
        .then((api_res) => {
          console_log("api_res", api_res);
          if (api_res.status === "1") {
            let data = api_res["data"];
            if (!empty(data["2fa_secret"]) && data["2fa_secret"] !== "") {
              setApiLoading(false);
              showTwoStepCodeModal();
            } else {
              doLogin(data);
            }
          } else {
            setApiLoading(false);
            showToast(api_res.message, "error");
            updateLoginAttemptCount();
            if (recaptchaEnable) {
              setRecaptchaValid(false);
              resetCaptcha()
            }
          }
        })
        .catch((err) => {
          setApiLoading(false);
          showToast(err, "error");
          if (recaptchaEnable) {
            setRecaptchaValid(false);
            resetCaptcha()
          }
        });
    }
  };
  const doLogin = (data) => {
    showToast("You are in!", "success");
    dispatch(updateUser(data));
    setTimeout(() => {
      setApiLoading(false);
      if (data["is_admin"] === "1") {
        props.history.replace({ pathname: ROUTE_ADMIN_DASHBOARD });
      } else {
        props.history.replace({ pathname: ROUTE_USER_DASHBOARD });
      }
    }, 500);
    updateLoginAttemptCount(0);
  };
  const doLogout = () => {
    if (empty(userDataStore['token'])) {
      return false
    }

    apiLogout(userDataStore['token'])
      .then((api_res) => {
        console_log("api_res", api_res)
      })
      .catch((err) => {

      });
    dispatch(updateUser({ token: "" }));
  }

  const onChangeKeyInput = (e, field_name) => {
    if (field_name === 'user_password') {
      if (e.key === 'Enter') {
        onClickLogin()
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onClickLogin()
  }

  return (
    <div>
      <HomeLayout>
        <div className="main-content bg-front-page-gradient">
          <div
            className="section page-section container has-header content-sm relative"
            id="page-section1"
          >
            <Particles className="absolute inset-0 -z-10" />

            <div className="md:block absolute left-1/3 -translate-x-1/2 -mt-36 blur-2xl opacity-40 pointer-events-none -z-10" aria-hidden="true">
              <img src="/themes/front/images/auth-illustration.svg" className="max-w-none" width="1440" height="450" alt="Page Illustration" />
            </div>

            {/* Page header */}
            <div className="max-w-3xl mx-auto text-center pb-12">

              {/* <AuthLogo /> */}
              <img
                src="/assets/home/images/HLFX_Logo_White-Green_TM-01.png"
                className="img-responsive img-logo auth-logo mx-auto text-center mb-4"
                style={{ marginTop: '4vh' }}
                alt="Logo"
              />

              {/* Page title */}
              <h1 className="h2 bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 auth-title">Sign in to your account</h1>
            </div>

            {/* Form */}
            <div className="max-w-sm mx-auto">

              <form onSubmit={handleSubmit}>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm text-slate-300 font-medium mb-1" htmlFor="user_name">Username or Email</label>
                    <input
                      id="user_name"
                      type="text"
                      name="user_name"
                      className={`form-input w-full ${errorField.includes("user_name") ? "is-invalid" : ""
                        }`}
                      placeholder="Username or Email"
                      value={get_data_value(userData, "user_name")}
                      onChange={(e) => {
                        onChangeFormField(e, "user_name");
                      }}
                      required
                      disabled={apiLoading}
                    />
                  </div>
                  <div>
                    <div className="flex justify-between">
                      <label className="block text-sm text-slate-300 font-medium mb-1" htmlFor="user_password">Password</label>
                      <Link className="text-sm font-medium text-purple-500 hover:text-purple-400 transition duration-150 ease-in-out ml-2" to={ROUTE_FORGOT_PASSWORD}>Forgot?</Link>
                    </div>
                    <input
                      type="password"
                      id="user_password"
                      name="user_password"
                      className={`form-input w-full ${errorField.includes("user_password") ? "is-invalid" : ""
                        }`}
                      placeholder="Password"
                      value={get_data_value(userData, "user_password")}
                      onChange={(e) => {
                        onChangeFormField(e, "user_password");
                      }}
                      autoComplete="off"
                      required
                      disabled={apiLoading}
                    />
                  </div>

                  <div
                    className={`form-group mt-4 recaptcha-wrap ${recaptchaEnable ? "" : "hidden"
                      }`}
                  >
                    <ReCAPTCHA
                      ref={(r) => setCaptchaRef(r)}
                      sitekey={googleKey}
                      onChange={reCaptchaChanged}
                      theme="dark"
                    ></ReCAPTCHA>
                  </div>

                </div>
                <div className="mt-6">
                  <button
                    type="submit"
                    className="btn text-sm text-white bg-purple-500 hover:bg-purple-600 w-full shadow-sm group"
                    disabled={apiLoading || (recaptchaEnable && !recaptchaValid)}
                  >
                    Sign In <span className="tracking-normal text-purple-300 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">-&gt;</span>
                  </button>
                </div>
              </form>

              <div className="text-center mt-4">
                <div className="text-sm text-slate-400">
                  Don't have an account? <Link className="font-medium text-purple-500 hover:text-purple-400 transition duration-150 ease-in-out" to={ROUTE_REGISTER}>Sign up</Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showConfirmModal && (
          <TwoFactorCodeModal
            isVisible={true}
            setShowconfirmModal={setShowconfirmModal}
            submitModalData={submitModalData}
            modalClass="user-page front-two-factor-modal"
            apiLoading={apiLoading}
            setApiLoading={setApiLoading}
          />
        )}
      </HomeLayout>
    </div>
  );
};

export default withRouter(LoginPage);
