import HomeLayout from "layouts/HomeLayout/HomeLayout";
import { ROUTE_AFFILIATE_AGREEMENT } from "navigation/CONSTANTS";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { UpdateAppData } from "redux/actions/appActions";
import Particles from "components/Particles/Particles";

const AffiliateAgreementPage = () => {
    const dispatch = useDispatch();
    const initPage = () => {
        let page_title = "Affiliate Agreement";
        dispatch(
            UpdateAppData({
                currentPageTitle: page_title,
                current_route: ROUTE_AFFILIATE_AGREEMENT,
            })
        );
    };

    useEffect(() => {
        initPage();
    });

    return (
        <div>
            <HomeLayout>
                <div className="main-content bg-front-page-gradient">
                    <div
                        className="section page-section container has-header content-sm relative"
                        id="page-section1"
                    >
                        <Particles className="absolute inset-0 -z-10" />

                        <div className="container d-padding-top-20">
                            <div className="row d-margin-top-20">
                                <div className="col-md-12 md-margin-bottom-30">
                                    <h1 className="inline-flex font-medium bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-purple-200 pb-3">
                                        AFFILIATE AGREEMENT
                                    </h1>
                                    <p className="text-normal">
                                        This Affiliate Agreement (“Agreement”) governs Your application for, and any subsequent participation in, Higher Level FX’s Affiliate program. By clicking “I Accept the Terms and Conditions” and submitting, You hereby accept the terms of this Agreement, You indicate that You have read and understood this Agreement, and agree that You are bound by its terms. <br /><br />

                                        1. PARTIES. All references to “Higher Level FX” herein mean and refer to Higher Level FX, doing business as Higher Level FX. All references to “You” and “Your” mean and refer to the person or entity who has executed this Agreement by clicking “I Accept the Terms and Conditions” and submitting. Higher Level FX and You are each referred to herein as a “Party,” and collectively as the “Parties.”<br /><br />
                                        2. APPLICATION. You agree to provide all information requested by Higher Level FX in connection with Your Affiliate application, and You affirm that all information that You provide is truthful and accurate.
                                        You understand and agree that Higher Level FX retains sole and exclusive discretion to determine whether You qualify for participation in Higher Level FX’s Affiliate program. Not everyone who applies for Higher Level FX’s Affiliate program will qualify to participate<br /><br />
                                        3. CONSENT TO BE CONTACTED.
                                        You expressly consent to be contacted at the email address and the phone number You provide in Your application about Your application and the Affiliate Program, including through automated dialing systems, texts, and artificial or pre-recorded messages. This consent is a material condition of this Agreement and may not be revoked except in writing by both Parties.<br /><br />
                                        4. In the event that a Prospect has multiple Affiliate Cookies, the most recently-acquired Cookie will determine which Affiliate is credited with a Sale.
                                        Affiliates will be paid $50 per month for every personal referral who becomes an HLX customer.
                                        For any referral who upgrades to the annual membership, you will receive a $500 commission per sale each year their membership renews.
                                        Since Higher Level FX offers a 7-day trial, affiliates are not paid on first-time purchases until 7 days or until any coupon has expired.
                                        If a referral fraudulent disputes the payment on a purchase of the Higher Level FX membership, the affiliate is not eligible for their commission.
                                        All recurring payments for Higher Level FX Products originally purchased through the affiliates link are paid right away to the affiliate.
                                        Your commissions must equal or exceed One Hundred and 00/100 Dollars ($100.00) (USD) before you receive a payment from Higher Level FX.
                                        For you to be eligible to receive your commissions, you must have an active Higher Level FX membership.
                                        If you cancel your membership and no longer have an active membership, you will not be eligible to withdraw your commissions.
                                        However, your commissions will be available to you whenever you return as a paying Higher Level FX client.
                                        For example, if you refer 20 people to the company, which earns you about $1000/month in commissions, you stop paying for your membership then you no longer have access to all future commissions earned from referring these 20 people.
                                        If you have any questions on this, you may contact us at admin@higherlevelfx.com.
                                        Once a commission of $100 (USD) or more is earned, you will need to have a Paypal account to receive payment of commissions.
                                        You are solely responsible for complying with all tax laws in your respective jurisdiction(s) including but not limited to, the payment of all required taxes to, and filing all returns and other required documents with, the applicable governing body(ies).
                                        Affiliates are not permitted to open a Higher Level FX account under the name of another person or entity, under a fictitious name, or under any name merely for the purpose of obtaining Commissions or any other compensation.
                                        Affiliates may not pay for another person’s or entity’s account.
                                        Affiliates are not permitted to offer cash rebates or other monetary incentives to obtain Sales.
                                        Commissions are paid only for transactions that actually occur between Higher Level FX and a Sale in which payment is received by Higher Level FX.
                                        If payment for a Sold Account later results in a refund or chargeback, and if a Commission was paid to You for that Sold Account, then the Commission will be deducted from Your future Commissions.
                                        If Higher Level FX determines, in its sole and exclusive discretion, that any Sale was procured fraudulently or as a result of any violation of this Agreement or applicable law, no Commission will be paid for such Sale and, for past sales, such payment amounts shall be deducted from Your future Commissions, and Higher Level FX may terminate this Agreement immediately without Higher Level FX having any liability to You.
                                        ADDITIONAL REPRESENTATIONS AND WARRANTIES.
                                        In addition to Your other representations and warranties herein, You further represent and warrant that there are no prior or pending government investigations or inquiries of, or prosecutions against You by the Federal Trade Commission (“FTC”), any other federal or state governmental agency, or any industry regulatory authority, anywhere in the world, nor any prior or pending private lawsuits against You which relate to alleged intentional torts or alleged violation of any consumer protection or advertising laws.
                                        If You become the subject of such an investigation, inquiry, prosecution, or lawsuit any time after this Agreement is executed, You are required to notify Higher Level FX of the same within 24 hours.
                                        Higher Level FX, in its sole and exclusive discretion, may immediately terminate Your participation in Higher Level FX’s Affiliate program, as well as immediately terminate this Agreement, based on any investigation, proceeding, or lawsuit identified pursuant to this paragraph.<br /><br />
                                        5. ENTIRE AGREEMENT.
                                        This Agreement, Appendix A below, along with Higher Level FX’s standard Terms of Use represents the entire agreement between the Parties and supersedes any other written or oral agreement between the Parties pertaining to Your Affiliate application and, if approved, Your rights and responsibilities as an Affiliate.<br /><br />
                                        6. Appendix A
                                        Additional Terms of the Affiliate Agreement and Advertising Rules

                                        Advertising Rules<br />

                                        These Advertising Rules apply to all activities of Affiliates.<br />
                                        1. General Compliance.<br />
                                        Affiliates shall publish or otherwise distribute advertisements in strict compliance with all applicable laws and regulations, including without limitation, laws prohibiting deceptive and misleading advertising and marketing, email marketing laws (including the federal CAN-SPAM Act (15 U.S.C. § 7701)), laws governing testimonials (including the FTC’s Revised Endorsements and Testimonials Guides (16 CFR Part 255 of the Code of Federal Regulations)), and all guidelines issued by the FTC.
                                        Affiliate is solely responsible for ensuring Affiliate’s compliance with all laws.
                                        Affiliates are strictly prohibited from making claims concerning the products and services offered by Higher Level FX that are inconsistent with, or beyond the scope of marketing materials produced and made available by Higher Level FX on Higher Level FX’s website, www.higherlevelfx.com.
                                        Affiliate is prohibited from publishing or otherwise distributing advertisements by telemarketing, fax, or text messaging in any form to any device. Affiliates shall not offer monetary incentives, such as rewards points, cash, or prizes to Prospects in return for their response to an advertisement.
                                        The affiliate may, however, offer Prospects information and materials of tangible value including, but not limited to, website templates, information about e-commerce, website design, and online marketing, for reduced or no charge, but only so long as the Affiliate accurately describes and delivers such information and materials to the Prospect.
                                        Higher Level FX retains the sole and exclusive discretion to determine whether the Affiliate’s advertising and conduct are in compliance with all laws.<br />
                                        2. Disclosure.<br />
                                        On any website that Affiliate advertises any Higher Level FX service or product, Affiliate must plainly display (i.e., not in a link, or in small font) the following disclaimer language:<br />
                                        3. Disclosure: I am an independent Higher Level FX Affiliate, not an employee.<br />
                                        I receive referral payments from Higher Level FX.
                                        The opinions expressed here are my own and are not official statements of Higher Level FX or its parent company, Higher Level FX.
                                        Non-Disparagement.
                                        Affiliate is not permitted to comment negatively about or disparage the products or services of Higher Level FX or any other person or entity, including without limitation the products or services of a Higher Level FX competitor.
                                        Affiliate is not permitted to engage in any unlawful or deceptive actions with respect to search engine optimization, including, but not limited to, using any technique that generates paid search results based on any trademarks of Higher Level FX, any brand name of Higher Level FX, or based on the trademarks or brand name of any competitor of Higher Level FX, or any other third party.<br />
                                        4. Social Media.<br />
                                        If an Affiliate advertises on Instagram or YouTube, then each post must comply with all of the following:<br />
                                        ■ Each post must contain @higherlevelfx or #higherlevelfx.<br />
                                        ■ Each post must contain the #ad in a clear and conspicuous location before the text of the description and in all events before the “More” button.<br />
                                        ■ Each Instagram post must use Instagram’s “Paid Partnership” tool.<br />
                                        ■ Each YouTube post must contain the word “Ad,” “Advertisement,” “Promotion,” or “Paid Partnership” within the video itself in a font size that is clearly recognizable to the viewer and which appears persistently throughout the length of the video in the top right-hand portion of the video.<br />
                                        5. If Affiliate is advertising on other forms of written social media (e.g., Facebook, Twitter), Affiliate must comply with the above disclosure restrictions as applicable to each form of social media.<br />
                                        Affiliate must also comply with all rules of each social media platform that Affiliate uses.
                                        Income and Business Opportunity Claims.
                                        Affiliates are expressly prohibited from making any claims that the use of Higher Level FX’s services will guarantee that the user will make money.
                                        If Affiliate’s recruiting efforts include claims related to income Affiliate has made from using Higher Level FX’s services or as an Affiliate, the following guidelines must adhere to<br /><br /> 7. (a) Affiliate’s statements must be completely true and accurate and supported by evidence of Affiliate’s experience; and (b) Affiliate’s statements must be accompanied by the following disclaimer in a clear and conspicuous font: “These were my results.
                                        Your results will vary based on a variety of factors including Your education, effort, and market factors.
                                        There is no guarantee You will make any money.
                                        If trading, only trade with money you are willing to lose.” Affiliate is also expressly prohibited from making any express or implied claims that Higher Level FX is or provides a business opportunity, franchise opportunity, a “business-in-a-box,” or an assisted marketing plan.
                                        Affiliates may not use “Higher Level FX” or other Higher Level FX trademarks as part of any URL, domain, or website name.
                                        Higher Level FX retains exclusive ownership of all Higher Level FX Trademarks and other intellectual property and all of its rights therein.
                                        Affiliate shall not promote or provide services to any other business or person that is infringing any of Higher Level FX’s intellectual property.
                                        Complaint Notification.
                                        Affiliate must notify Company of any complaint received by Affiliate regarding any advertisements within twenty-four (24) hours of receiving such complaint. Notice should be sent to admin@higherlevelfx.com.<br /><br />
                                        8. Independent Contractor.
                                        Affiliates are independent contractors of Higher Level FX.
                                        It is the express understanding and intention of the Parties that no relationship of master and servant nor principal and agent shall exist between Higher Level FX and You by virtue of this Agreement.
                                        You have no right to act on behalf of or bind Higher Level FX in any way, nor share in the profits or losses of Higher Level FX.
                                        The only compensation available to You is set forth in this Agreement.
                                        You are solely and exclusively responsible and liable for all of Your acts or omissions.<br /><br />
                                        9. NO WARRANTY; NO LEADS.
                                        Higher Level FX does not promise, guarantee or warrant Your business success, income, or sales.
                                        You understand and acknowledge that Higher Level FX will not at any time provide sales leads or referrals to You.
                                        You understand and agree further that this is not a business opportunity, a franchise opportunity, a “business-in-a-box,” or an assisted marketing plan.
                                        You are responsible for procuring and paying for any and all materials and resources necessary to operate as an Affiliate as You determine in Your sole discretion.<br /><br />
                                        10. LIMITATION OF LIABILITY.
                                        YOU AGREE THAT IN NO EVENT SHALL Higher Level FX, OR THEIR RESPECTIVE OWNER(S), PARENT COMPANY(IES), AFFILIATE ENTITIES, EMPLOYEES, OR ASSIGNS HAVE LIABILITY TO YOU FOR ANY CLAIM OF ANY KIND OR DESCRIPTION FOR AN AMOUNT IN EXCESS OF THE AMOUNT OF THE COMMISSIONS PAID TO YOU FOR THE MONTH PRECEDING THE DATE IN WHICH THE FACTS GIVING RISE TO A CLAIM AGAINST Higher Level FX OCCURRED, OR TWO-THOUSAND DOLLARS ($2,000), WHICHEVER IS GREATER.
                                        YOU WAIVE ANY RIGHT TO SPECIAL, INDIRECT, OR CONSEQUENTIAL DAMAGES OF ANY KIND OR DESCRIPTION.
                                        THIS LIMITATION OF LIABILITY SHALL APPLY FOR ALL CLAIMS AND ALL THEORIES OF LIABILITY ADVANCED, REGARDLESS OF WHETHER Higher Level FX WAS AWARE OF OR ADVISED IN ADVANCE OF THE POSSIBILITY OF DAMAGES OR SUCH CLAIMS.
                                        DISPUTE RESOLUTION BY MANDATORY BINDING ARBITRATION, CLASS ACTION WAIVER, & GOVERNING LAW.<br /><br />
                                        11. As explained in Higher Level FX’s Terms of Use, any controversy or claim arising out of or related to this Agreement or Your relationship with us that cannot be resolved through negotiation within 120 days shall be resolved by binding, confidential arbitration administered by the American Arbitration Association (“AAA”), and judgment on the award rendered may be entered in any court having jurisdiction thereof.
                                        The sections of the Terms of Use entitled “DISPUTE RESOLUTION BY MANDATORY BINDING ARBITRATION AND CLASS ACTION WAIVER,” “Higher Level FX’S ADDITIONAL REMEDIES,” and “GOVERNING LAW AND VENUE” are expressly incorporated herein by reference.
                                        Please review the Terms of Use for more information.
                                        Indemnity.
                                        You agree to protect, defend, indemnify and hold harmless Higher Level FX, its officers, directors, employees, owner(s), and parent company(ies) and assigns from and against all claims, demands, and causes of action of every kind and character without limitation arising out of Your conduct, acts, or omissions related to Your application and/or performance of this Agreement including, but not limited to, any breach of this Agreement.
                                        Your indemnity obligation includes, but is not limited to, any third party claim against Higher Level FX for liability or payments for damages caused by, or other liability relating to, You.
                                        This provision expressly survives the termination of this Agreement.<br /><br />
                                        12. Term and Termination.
                                        If approved, Your participation in the Higher Level FX Affiliate program will continue month-to-month until either:<br /><br />
                                        13. A. Higher Level FX cancels Your account due to Your breach of any of the terms of this Agreement. In the event this Agreement is canceled due to Your breach, You immediately forfeit all Commissions and any other payments owed to You or that may in the future be owed to You without any further liability by Higher Level FX to You
                                        OR
                                        B. Higher Level FX or its successors or assigns, in its sole and absolute discretion, cancels or fails to renew this Agreement. In the event that Higher Level FX or its successors or assigns cancel this Agreement, You will first receive thirty (30) days written notice, sent to the email address associated with Your Affiliate profile.
                                        OR
                                        C. You provide Higher Level FX thirty (30) days' written notice that You wish to cancel the Agreement.
                                        If this Agreement is terminated or canceled, then all provisions that, by their nature, should survive, will survive, including, but not necessarily limited to, all limitations of liability, disclaimers of warranties, indemnity obligations, mandatory arbitration, and class action waiver provisions, and exceptions to arbitration.
                                        All representations and warranties undertaken by You shall also survive termination or cancellation of this Agreement and/or Your Higher Level FX account.
                                        Severability.
                                        In the event any provision of this Agreement is inconsistent with or contrary to any applicable law, rule, or regulation, the provision shall be deemed to be modified to the extent required to comply with the law, rule, or regulation, and this Agreement and the Terms of Service, as so modified, shall continue in full force and effect.<br /><br />
                                        14. Modification/Amendments.
                                        This Agreement and Higher Level FX’s standard Terms of Use may be modified by Higher Level FX at any time, with or without prior notice to You.
                                        No amendment to this Agreement or the Terms of Service shall be valid unless prepared or signed by Higher Level FX.
                                        Your continued acceptance of Commission payments constitutes Your acceptance of any modifications or amendments to this Agreement and the Terms of Use.

                                    </p>
                                </div>
                                <div className="col-md-12 md-margin-bottom-30 mt-5">
                                    <h4 className="text-normal">
                                        Referral Commission Expiration:
                                    </h4>
                                    <p className="text-normal">
                                        To maintain eligibility for referral commissions, you must remain active within the program. Inactivity for more than 180 days will result in the forfeiture of any accumulated referral commissions.<br />
                                        It is your responsibility to ensure that your account remains active by making new referrals and accessing your account regularly. <br />
                                        The company is not responsible for any expired or forfeited referral commissions due to inactivity.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </HomeLayout>
        </div>
    );
};

export default AffiliateAgreementPage;
