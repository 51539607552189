// @flow strict

import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const FeedbackSection = (props) => {
  const carouselTitle = "Messages from customers";
  const carouselItems = [
    {
      title: carouselTitle,
      content:
        "HLX is the first company I've come across that is truly pushing and striving for the best products on the market. I have no doubt that they will continue to innovate and implement only the highest quality of services to their customers. There are no marketing tactics that try to get people to join, and no fake hype. Probably one of the most transparent companies I've seen.",
      author: "Jacob Diamse",
    },
    {
      title: carouselTitle,
      content:
        "HigherLevelFX has been an absolute game changer for my trading career. After failing miserably in Forex for a solid 2 1/2 years a buddy introduced me to HFX & I finally became a profitable trader! The education, tools & support I’ve received have been second to none. The value they provide pails in comparison to the monthly membership. They’re always updating, tweaking & refining their process to bring the best in class to the table. And because of this I’ll be a lifelong customer of HFX! Thank you HFX team for everything you do!",
      author: "Drew Taylor",
    },
    {
      title: carouselTitle,
      content:
        "I have been with HLX and using the Alpha software since the beginning. I am very impressed by their constant evolving tools and services that will definitely keep them on the cutting edge for years to come.  I strongly recommend this company for education and support for those who want to learn and grow in a great Forex community such as HLX. Long live HLX!",
      author: "Jason Brus",
    },
    {
      title: carouselTitle,
      content:
        "When a friend asked me to join HLX, I was initially very hesitant thinking I would have to undergo the same misfortune I experienced with the previous company I joined in. Several issues were encountered in that company, so it did not last long. The pandemic triggered me to find another source of income hence I decided to join. Fortunately for me, the people behind HLX are so helpful, responsive and are always available to listen to customer's feedback. The company also constantly improves their products and services. With this, I was very glad I got into this since they always put their customers first above anything else. Being with this company has helped me a lot especially during this pandemic. As a result, I also invited my Philippine friends to be part of HLX and just like me, they are also very pleased and satisfied of the products and services.",
      author: "Mark Vincent Po",
    },
    {
      title: carouselTitle,
      content:
        "I have been part of the HLX family from the beginning and I have to say they are the best. They education system is truly useful and practical, you will find your material whether you are beginner or advanced trader. Thanks to their strategy I understand the market more and I became a better trader overall. They forged their trading strategy into an EA software that is available to try on demo account and it is absolutely mind blowing, best ever! This company really knows what they are doing.",
      author: "Gergo Fancsali",
    },
    {
      title: carouselTitle,
      content:
        "As an FX trader that’s been with many companies, some good mostly bad…I’ve never been so profitable as I have with this company.  Although you hear it said many times, they are truly the “real deal”. Rest, weary traveler, you’ve finally come to an oasis for prosperity! They more than deserve their praise.  Sign on, and see for yourself!",
      author: "Joel Kim",
    },
  ];
  return (
    <section
      className="testimal-section pt-5 pb-5"
    >
      <OwlCarousel
        className="owl-theme"
        loop={true}
        margin={10}
        nav={true}
        autoplay={false}
        lazyLoad={true}
        items={1}
      >
        {carouselItems.map((item, index) => (
          <div className="item max-w-6xl mx-auto px-4 sm:px-6" key={index}>
            <div className="container relative">
              <div className="row">
                <div className="col-md-12 align-center text-center ">
                  <p className="text-lg text-center text-slate-400">{item.title}</p>
                  <div className="lead text-center mt-1 text-purple-500">
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                  </div>
                  <blockquote className="text-lg text-slate-400 mt-3">
                    <p>{item.content}</p>
                    <p className="testimonial-author blockquote-footer mt-2">
                      {item.author}
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        ))}
      </OwlCarousel>
    </section>

  );
}

export default FeedbackSection;
