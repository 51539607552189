import {
  ROUTE_LOGIN,
  ROUTE_REGISTER,
  ROUTE_ROOT,
  ROUTE_USER_DASHBOARD,
} from "navigation/CONSTANTS";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";

import { Link } from "react-router-dom";
import { get_utc_timestamp_ms, isMobile } from "utils/GlobalFunctions";
import { HOME_MENU_ITEMS } from "./HomeMenuItems";
import { Collapse } from 'react-collapse';

const HomeNavbar = () => {
  const history = useHistory()

  const appDataStore = useSelector((x) => x.appDataStore);
  const [is_mobile, set_is_mobile] = useState(isMobile())

  useLayoutEffect(() => {
    function updateSize() {
      //setSize([window.innerWidth, window.innerHeight]);
      set_is_mobile(isMobile())
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  // const { getCollapseProps, getToggleProps } = useCollapse({ mobileNavOpen })

  const onClickMobileNav = () => {
    setMobileNavOpen(!mobileNavOpen)
  }

  ///////////////////////////////////////////////////////////////////////////////
  const onClickMenuItem = (menuItem) => {
    const clickFunc = menuItem.clickFunc
    if (clickFunc === "onClickPricingHashTag") {
      const url = `${ROUTE_ROOT}?a=pricing&ts=${get_utc_timestamp_ms()}`
      history.push(url)
    }
  }

  return (
    <header className="absolute w-full z-30">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between h-16 md:h-20">
          <div className="flex-1">
            <Link className="inline-flex" to={ROUTE_ROOT} aria-label="Cruip">
              <img className="max-w-none" src="/favicon.png" width="38" height="38" alt="Stellar" />
            </Link>
          </div>
          <nav className={`md:flex md:grow ${is_mobile ? 'hidden' : ''}`}>
            <ul className="flex grow justify-center flex-wrap items-center">
              {HOME_MENU_ITEMS.map((menuItem, index) => (
                <li key={index}>
                  {
                    (menuItem.external_link) ? (
                      <a
                        href={menuItem.route}
                        className={`font-medium text-sm text-slate-300 hover:text-white mx-4 lg:mx-5 transition duration-150 ease-in-out ${appDataStore.current_route === menuItem.route
                          ? "active"
                          : ""
                          }`}
                        target="_blank"
                      >
                        {menuItem.title}
                      </a>
                    ) : (
                      <>
                        {
                          (menuItem.clickFunc) ? (
                            <span
                              to={menuItem.route}
                              className={`font-medium text-sm text-slate-300 hover:text-white mx-4 lg:mx-5 transition duration-150 ease-in-out cursor-pointer ${appDataStore.current_route === menuItem.route
                                ? "active"
                                : ""
                                }`}
                              onClick={() => onClickMenuItem(menuItem)}
                            >
                              {menuItem.title}
                            </span>
                          ) : (
                            <Link
                              to={menuItem.route}
                              className={`font-medium text-sm text-slate-300 hover:text-white mx-4 lg:mx-5 transition duration-150 ease-in-out ${appDataStore.current_route === menuItem.route
                                ? "active"
                                : ""
                                }`}
                            >
                              {menuItem.title}
                            </Link>
                          )
                        }
                      </>
                    )
                  }
                </li>
              ))}
            </ul>
          </nav>

          <ul className="flex-1 flex justify-end items-center">
            <li>
              <Link className="font-medium text-sm text-slate-300 hover:text-white whitespace-nowrap transition duration-150 ease-in-out" to={ROUTE_LOGIN}>Sign in</Link>
            </li>
            <li className="ml-6">
              <Link className="btn-sm text-slate-300 hover:text-white transition duration-150 ease-in-out w-full group [background:linear-gradient(theme(colors.slate.900),_theme(colors.slate.900))_padding-box,_conic-gradient(theme(colors.slate.400),_theme(colors.slate.700)_25%,_theme(colors.slate.700)_75%,_theme(colors.slate.400)_100%)_border-box] relative before:absolute before:inset-0 before:bg-slate-800/30 before:rounded-full before:pointer-events-none" to={ROUTE_REGISTER}>
                <span className="relative inline-flex items-center">
                  Sign up <span className="tracking-normal text-purple-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">-&gt;</span>
                </span>
              </Link>
            </li>
          </ul>


          <div className={`flex items-center ml-4 ${is_mobile ? '' : 'hidden'}`} x-data="{ expanded: false }">
            <button className={`hamburger ${mobileNavOpen ? 'active' : ''}`} aria-controls="mobile-nav" onClick={() => onClickMobileNav()}>
              <span className="sr-only">Menu</span>
              <svg className="w-5 h-5 fill-current text-slate-300 hover:text-white transition duration-150 ease-in-out" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <rect y="2" width="20" height="2" rx="1" />
                <rect y="9" width="20" height="2" rx="1" />
                <rect y="16" width="20" height="2" rx="1" />
              </svg>
            </button>
          </div>


        </div>

        <div className={`mobile-nav-content ${is_mobile ? '' : 'hidden'}`}>
          <Collapse isOpened={mobileNavOpen} className="transition-all duration-300 ease-in-out">
            <nav id="mobile-nav" className="top-full z-20 left-0 w-full overflow-hidden transition-all duration-300 ease-in-out" x-ref="mobileNav">
              <ul className="border border-transparent [background:linear-gradient(theme(colors.slate.900),_theme(colors.slate.900))_padding-box,_conic-gradient(theme(colors.slate.400),_theme(colors.slate.700)_25%,_theme(colors.slate.700)_75%,_theme(colors.slate.400)_100%)_border-box] rounded-lg px-4 py-1.5">
                {HOME_MENU_ITEMS.map((menuItem, index) => (
                  <li key={index}>
                    {
                      (menuItem.external_link) ? (
                        <a
                          href={menuItem.route}
                          className={`flex font-medium text-sm text-slate-300 hover:text-white py-1.5 ${appDataStore.current_route === menuItem.route
                            ? "active"
                            : ""
                            }`}
                          target="_blank"
                        >
                          {menuItem.title}
                        </a>
                      ) : (
                        <>
                          {
                            (menuItem.clickFunc) ? (
                              <span
                                to={menuItem.route}
                                className={`flex font-medium text-sm text-slate-300 hover:text-white py-1.5 cursor-pointer ${appDataStore.current_route === menuItem.route
                                  ? "active"
                                  : ""
                                  }`}
                                onClick={() => onClickMenuItem(menuItem)}
                              >
                                {menuItem.title}
                              </span>
                            ) : (
                              <Link
                                to={menuItem.route}
                                className={`flex font-medium text-sm text-slate-300 hover:text-white py-1.5 ${appDataStore.current_route === menuItem.route
                                  ? "active"
                                  : ""
                                  }`}
                              >
                                {menuItem.title}
                              </Link>
                            )
                          }
                        </>
                      )
                    }
                  </li>
                ))}
              </ul>
            </nav>
          </Collapse>
        </div>
      </div>
    </header>
  )
}

export default HomeNavbar;
