import HomeLayout from "layouts/HomeLayout/HomeLayout";
import {
  ROUTE_FORGOT_PASSWORD,
  ROUTE_FREE_BOOK,
  ROUTE_LOGIN,
  ROUTE_REGISTER,
} from "navigation/CONSTANTS";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAppData } from "redux/actions/appActions";
import ReCAPTCHA from "react-google-recaptcha";
import { googleKey } from "config/CONSTANTS";
import { Link, withRouter } from "react-router-dom";
import TwoFactorCodeModal from "components/TwoFactorCodeModal/TwoFactorCodeModal";
import {
  get_data_value,
  isEmpty,
  is_empty,
  showToast,
} from "utils/GlobalFunctions";
import { apiLogin, apiRequestResetPassword } from "services/loginService";
import { updateUser } from "redux/actions/userActions";
import { console_log } from "utils/logger";
import { useHistory } from "react-router-dom";
import Particles from "components/Particles/Particles";

const ForgotPasswordPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const initPage = () => {
    let page_title = "Forgot password";
    dispatch(
      UpdateAppData({
        currentPageTitle: page_title,
        current_route: ROUTE_FORGOT_PASSWORD,
      })
    );
    //document.title = page_title;
  };

  useEffect(() => {
    initPage();
  });

  //////////////////////////////////////////////////////////////////
  const userDataStore = useSelector((x) => x.userDataStore);
  const userInfo = userDataStore;
  const initUserData = { user_name: "", user_password: "" };
  const [userData, setUserData] = useState(initUserData);
  const [errorField, setErrorField] = useState([]);
  const [apiLoading, setApiLoading] = useState(false);

  const checkCaptchaEnabled = () => {
    return true;
  };
  const [recaptchaEnable, setRecaptchaEnable] = useState(checkCaptchaEnabled());
  const [recaptchaValid, setRecaptchaValid] = useState(false);
  const reCaptchaChanged = (value) => {
    console_log("reCaptchaChanged::::", value);
    if (value) {
      setRecaptchaValid(true);
    } else {
      setRecaptchaValid(false);
    }
  };

  let captcha;
  const setCaptchaRef = (ref) => {
    if (ref) {
      return (captcha = ref);
    }
  };
  const resetCaptcha = () => {
    // maybe set it till after is submitted
    captcha.reset();
  };

  const validateFields = () => {
    var errorList = Array();
    errorList = isEmpty(userData, "user_email", errorList);
    errorList = isEmpty(userData, "user_password", errorList);
    setErrorField([...errorList]);
    return errorList.length > 0 ? false : true;
  };
  const onChangeFormField = (e, field_name) => {
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    userData[field_name] = e.target.value;
    setUserData({ ...userData });
  };

  const onClickResetPassword = () => {
    let is_valid = validateFields();
    if (is_valid) {
      if (recaptchaEnable) {
        if (!recaptchaValid) {
          showToast("Please confirm that you're not a robot", "error");
          return false;
        }
      }
      setApiLoading(true);
      apiRequestResetPassword(userData)
        .then((api_res) => {
          if (api_res.status === "1") {
            //let data = api_res["data"];
            setApiLoading(false)
            showToast(api_res.message, 'success');

            setTimeout(() => {
              history.push(ROUTE_LOGIN)
            }, 2000)

          } else {
            setApiLoading(false);
            showToast(api_res.message, "error");
          }
          if (recaptchaEnable) {
            setRecaptchaValid(false);
            resetCaptcha();
          }
        })
        .catch((err) => {
          setApiLoading(false);
          showToast(err, "error");
          setRecaptchaValid(false);
          resetCaptcha();
        });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onClickResetPassword()
  }

  return (
    <div>
      <HomeLayout>
        <div className="main-content bg-front-page-gradient">
          <div
            className="section page-section container has-header content-sm relative"
            id="page-section1"
          >
            <Particles className="absolute inset-0 -z-10" />

            <div className="md:block absolute left-1/3 -translate-x-1/2 -mt-36 blur-2xl opacity-40 pointer-events-none -z-10" aria-hidden="true">
              <img src="/themes/front/images/auth-illustration.svg" className="max-w-none" width="1440" height="450" alt="Page Illustration" />
            </div>

            {/* Page header */}
            <div className="max-w-3xl mx-auto text-center pb-12">

              {/* <AuthLogo /> */}
              <img
                src="/assets/home/images/HLFX_Logo_White-Green_TM-01.png"
                className="img-responsive img-logo auth-logo mx-auto text-center mb-4"
                style={{ marginTop: '4vh' }}
                alt="Logo"
              />

              <div className="max-w-sm mx-auto">
                <p className="bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60">Enter the email address for your account and a new password below.
                  You will receive a confirmation email, please click the link in the email to activate your new password.</p>
              </div>

            </div>

            {/* Form */}
            <div className="max-w-sm mx-auto">

              <form onSubmit={handleSubmit}>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm text-slate-300 font-medium mb-1" htmlFor="user_email">Email</label>
                    <input
                      id="user_email"
                      type="email"
                      name="user_email"
                      className={`form-input w-full ${errorField.includes("user_email") ? "is-invalid" : ""
                        }`}
                      placeholder="Email"
                      value={get_data_value(userData, "user_email")}
                      onChange={(e) => {
                        onChangeFormField(e, "user_email");
                      }}
                      required
                      disabled={apiLoading}
                    />
                  </div>
                  <div>
                    <div className="flex justify-between">
                      <label className="block text-sm text-slate-300 font-medium mb-1" htmlFor="user_password">New Password</label>
                    </div>
                    <input
                      type="text"
                      id="user_password"
                      name="user_password"
                      className={`form-input w-full ${errorField.includes("user_password") ? "is-invalid" : ""
                        }`}
                      placeholder="Password"
                      value={get_data_value(userData, "user_password")}
                      onChange={(e) => {
                        onChangeFormField(e, "user_password");
                      }}
                      autoComplete="off"
                      required
                      disabled={apiLoading}
                    />
                  </div>

                  <div
                    className={`form-group mt-4 recaptcha-wrap`}
                  >
                    <ReCAPTCHA
                      ref={(r) => setCaptchaRef(r)}
                      sitekey={googleKey}
                      onChange={reCaptchaChanged}
                      theme="dark"
                    ></ReCAPTCHA>
                  </div>

                </div>
                <div className="mt-6">
                  <button
                    type="submit"
                    className="btn text-sm text-white bg-purple-500 hover:bg-purple-600 w-full shadow-sm group"
                    disabled={apiLoading || (recaptchaEnable && !recaptchaValid)}
                  >
                    Reset Password <span className="tracking-normal text-purple-300 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">-&gt;</span>
                  </button>
                </div>
              </form>

              <div className="text-center mt-4">
                <div className="text-sm text-slate-400">
                  Back to <Link className="font-medium text-purple-500 hover:text-purple-400 transition duration-150 ease-in-out" to={ROUTE_LOGIN}>Sign in</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HomeLayout>
    </div>
  );
};

export default withRouter(ForgotPasswordPage);