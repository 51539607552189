import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Particles from "components/Particles/Particles";
import { ROUTE_REGISTER } from "navigation/CONSTANTS";

const MEMBERSHIP_LIST = [
  {
    membership: 1,
    user_type: "0",
    highlight: false,
    name: "Full Package",
    price: 179,
    duration: "month",
    note: "Following the 7-day $1 trial!",
    mt4_licenses: 2,
    mt5_licenses: 2,
    vps: "Free"
  },
  {
    membership: 2,
    user_type: "2",
    highlight: true,
    name: "Smart Package",
    price: 1797,
    duration: "year",
    note: "",
    mt4_licenses: 2,
    mt5_licenses: 2,
    vps: "Free"
  }
]

const PackageList = (props) => {
  const { page = "home", initialMemberShip = 0, onChangeUserType } = props

  const getInitialMemberShip = () => {
    if (page === "home") {
      return MEMBERSHIP_LIST[1]['membership']
    } else {
      return 0
    }
  }
  const [selectedMembership, setSelectedMembership] = useState(initialMemberShip)

  const onClickMembership = (item) => {
    setSelectedMembership(item.membership)
    onChangeUserType(item.user_type)
  }

  return (
    <div className="membership-list-container">
      <div className="membership-list">
        <div className="t-show-desktop">
          <div className="row">
            <div className="col-md-4 membership-item-wrapper no-package">
              <div className="membership-item">
                <div className="package-header">

                </div>
                <div className="package-middle">

                </div>
                <div className="package-bottom">
                  <div className="package-info-line flex items-center h-full border-b border-slate-800 py-2 text-slate-100">
                    <label className="mb-0">Products</label>
                  </div>
                  <div className="package-info-line flex items-center h-full border-b border-slate-800 py-2 text-slate-400">
                    <label className="mb-0">MT4 Software Licenses</label>
                  </div>
                  <div className="package-info-line flex items-center h-full border-b border-slate-800 py-2 text-slate-400">
                    <label className="mb-0">MT5 Software Licenses</label>
                  </div>
                  <div className="package-info-line flex items-center h-full border-b border-slate-800 py-2 text-slate-400">
                    <label className="mb-0">VPS</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="row membership-package-list">
                {
                  MEMBERSHIP_LIST.map((item, index) => {
                    return (
                      <div className={`col-md-6 membership-item-wrapper valid-package ${item.membership === selectedMembership ? 'package-highlight' : ''}`} key={index}>
                        <div className="membership-item">
                          <div className="package-header">
                            <div className="text-base font-medium bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-purple-200 pb-0.5">{item.name}</div>
                            <div className="mb-1">
                              <span className="text-lg font-medium text-slate-400">$</span><span className="text-3xl font-bold text-slate-50">{item.price}</span><span className="text-sm text-slate-400 font-medium">/{item.duration}</span>
                            </div>
                            <div className="text-slate-400"><label>{item.note}</label></div>
                          </div>
                          <div className="package-middle">
                            <div className="border-b border-slate-800">
                              {
                                (page === 'home') ? (
                                  <>
                                    {
                                      (item.membership === selectedMembership) ? (
                                        <Link className="btn-sm text-white bg-purple-500 hover:bg-purple-600 w-full transition duration-150 ease-in-out group" to={ROUTE_REGISTER}>
                                          Get Started <span className="tracking-normal text-purple-300 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">-&gt;</span>
                                        </Link>
                                      ) : (
                                        <Link className="btn-sm text-slate-900 bg-gradient-to-r from-white/80 via-white to-white/80 hover:bg-white w-full transition duration-150 ease-in-out group" to={ROUTE_REGISTER}>
                                          Get Started <span className="tracking-normal text-purple-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">-&gt;</span>
                                        </Link>
                                      )
                                    }
                                  </>
                                ) : (
                                  <>
                                    {
                                      (item.membership === selectedMembership) ? (
                                        <button className="btn-sm text-white bg-purple-500 hover:bg-purple-600 w-full transition duration-150 ease-in-out group" type="button" onClick={() => onClickMembership(item)}>
                                          Select <span className="tracking-normal text-purple-300 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">-&gt;</span>
                                        </button>
                                      ) : (
                                        <button className="btn-sm text-slate-900 bg-gradient-to-r from-white/80 via-white to-white/80 hover:bg-white w-full transition duration-150 ease-in-out group" type="button" onClick={() => onClickMembership(item)}>
                                          Select <span className="tracking-normal text-purple-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">-&gt;</span>
                                        </button>
                                      )
                                    }
                                  </>
                                )
                              }
                            </div>
                          </div>
                          <div className="package-bottom">
                            <div className="package-info-line flex items-center h-full border-b border-slate-800 py-2 text-slate-400">
                            </div>
                            <div className="package-info-line flex items-center h-full border-b border-slate-800 py-2 text-slate-400">
                              <svg className="shrink-0 fill-purple-500 mr-3" xmlns="http://www.w3.org/2000/svg" width="12" height="9">
                                <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
                              </svg>
                              <span>{item.mt4_licenses}</span>
                            </div>
                            <div className="package-info-line flex items-center h-full border-b border-slate-800 py-2 text-slate-400">
                              <svg className="shrink-0 fill-purple-500 mr-3" xmlns="http://www.w3.org/2000/svg" width="12" height="9">
                                <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
                              </svg>
                              <span>{item.mt5_licenses}</span>
                            </div>
                            <div className="package-info-line flex items-center h-full border-b border-slate-800 py-2 text-slate-400">
                              <svg className="shrink-0 fill-purple-500 mr-3" xmlns="http://www.w3.org/2000/svg" width="12" height="9">
                                <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
                              </svg>
                              <span>{item.vps}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>

        <div className="t-show-mobile">
          <div className="block membership-list-mobile">
            <div className="row membership-package-list">
              {
                MEMBERSHIP_LIST.map((item, index) => {
                  return (
                    <div className={`col-md-6 membership-item-wrapper valid-package ${item.membership === selectedMembership ? 'package-highlight' : ''}`} key={index}>
                      <div className="membership-item">
                        <div className="package-header">
                          <div className="text-base font-medium bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-purple-200 pb-0.5">{item.name}</div>
                          <div className="mb-1">
                            <span className="text-lg font-medium text-slate-400">$</span><span className="text-3xl font-bold text-slate-50">{item.price}</span><span className="text-sm text-slate-400 font-medium">/{item.duration}</span>
                          </div>
                          <div className="text-slate-400"><label>{item.note}</label></div>
                        </div>
                        <div className="package-middle">
                          <div className="border-b border-slate-800">
                            {
                              (page === 'home') ? (
                                <>
                                  {
                                    (item.membership === selectedMembership) ? (
                                      <Link className="btn-sm text-white bg-purple-500 hover:bg-purple-600 w-full transition duration-150 ease-in-out group" to={ROUTE_REGISTER}>
                                        Get Started <span className="tracking-normal text-purple-300 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">-&gt;</span>
                                      </Link>
                                    ) : (
                                      <Link className="btn-sm text-slate-900 bg-gradient-to-r from-white/80 via-white to-white/80 hover:bg-white w-full transition duration-150 ease-in-out group" to={ROUTE_REGISTER}>
                                        Get Started <span className="tracking-normal text-purple-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">-&gt;</span>
                                      </Link>
                                    )
                                  }
                                </>
                              ) : (
                                <>
                                  {
                                    (item.membership === selectedMembership) ? (
                                      <button className="btn-sm text-white bg-purple-500 hover:bg-purple-600 w-full transition duration-150 ease-in-out group" type="button" onClick={() => onClickMembership(item)}>
                                        Select <span className="tracking-normal text-purple-300 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">-&gt;</span>
                                      </button>
                                    ) : (
                                      <button className="btn-sm text-slate-900 bg-gradient-to-r from-white/80 via-white to-white/80 hover:bg-white w-full transition duration-150 ease-in-out group" type="button" onClick={() => onClickMembership(item)}>
                                        Select <span className="tracking-normal text-purple-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">-&gt;</span>
                                      </button>
                                    )
                                  }
                                </>
                              )
                            }
                          </div>
                        </div>
                        <div className="package-bottom">
                          <div className="package-info-line flex items-center h-full border-b border-slate-800 py-2 text-slate-400">
                          </div>
                          <div className="package-info-line flex items-center h-full border-b border-slate-800 py-2 text-slate-400">
                            <svg className="shrink-0 fill-purple-500 mr-3" xmlns="http://www.w3.org/2000/svg" width="12" height="9">
                              <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
                            </svg>
                            <span>{item.mt4_licenses} MT4 Software Licenses</span>
                          </div>
                          <div className="package-info-line flex items-center h-full border-b border-slate-800 py-2 text-slate-400">
                            <svg className="shrink-0 fill-purple-500 mr-3" xmlns="http://www.w3.org/2000/svg" width="12" height="9">
                              <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
                            </svg>
                            <span>{item.mt5_licenses} MT5 Software Licenses</span>
                          </div>
                          <div className="package-info-line flex items-center h-full border-b border-slate-800 py-2 text-slate-400">
                            <svg className="shrink-0 fill-purple-500 mr-3" xmlns="http://www.w3.org/2000/svg" width="12" height="9">
                              <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
                            </svg>
                            <span>{item.vps} VPS</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PackageList;
