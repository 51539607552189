import { APPSTORE_LINK, GOOGLEPLAY_LINK } from "config/CONSTANTS";
import HomeLayout from "layouts/HomeLayout/HomeLayout";
import { ROUTE_FREE_BOOK, ROUTE_FREE_TRADE_IDEA } from "navigation/CONSTANTS";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { UpdateAppData } from "redux/actions/appActions";
import Particles from "components/Particles/Particles";

const FreeTradeIdeaPage = () => {

  const dispatch = useDispatch();
  const initPage = () => {
    let page_title = "Free Trade Ideas"
    dispatch(
      UpdateAppData({
        currentPageTitle: page_title,
        current_route: ROUTE_FREE_TRADE_IDEA,
      })
    );

  };

  useEffect(() => {
    initPage();
  });

  return (
    <div>
      <HomeLayout>
        <div className="main-content bg-front-page-gradient">
          <div
            className="section page-section container content-sm relative"
            id="page-section1"
          >
             <Particles className="absolute inset-0 -z-10" />

            <div className="container">
              <div className="block-1 d-padding-top-40">
                <div className="row d-padding-top-20">
                  <div className="col-md-6 d-margin-bottom-30">
                    <h1 className="inline-flex font-medium bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-purple-200 pb-3">
                      DOWNLOAD OUR FREE HLX TRADE ALERTS APP!
                    </h1>
                    <p className="text-normal">
                      This is a completely independent full-on platform used to breakdown trades without needing your Meta Trader!
                    </p>
                    <p className="text-normal">
                      Let us scan the market EVERY SECOND for you and send you the most accurate trade ideas!
                    </p>
                    <p className="text-normal">
                      Integrated Economic Calendar to keep you informed!
                    </p>
                    <div className="appstore-btn-group pl-0">
                      <a className="appstore-btn apple-store" href={APPSTORE_LINK} target="_blank"><img src="/assets/home/images/20220615/appstore.png" /></a>
                      <a className="appstore-btn google-play" href={GOOGLEPLAY_LINK} target="_blank"><img src="/assets/home/images/20220615/google-play.png" /></a>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="intro-video-box text-center">
                      <img
                        style={{ width: "410px", maxWidth: "100%" }}
                        src="/assets/home/images/20211227/hlxapp.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HomeLayout>
    </div>
  );
};

export default FreeTradeIdeaPage;
