import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { get_data_value, isEmpty, jQuery, priceFormat, showToast, show_loading } from "utils/GlobalFunctions";
import { APP_NAME, LICENSE_LIFE_TIME, LICENSE_PRICE, LICENSE_TRIAL_PRICE } from "config/CONSTANTS";
import { apiCheckCoupon } from "services/loginService";

function ReactivationCodeModal(props) {
  const { isVisible, setVisibleModal, submitModalData, modalClass, modalTitle } = props;
  const defaultModalData = {
    promo_code: ""
  };
  useEffect(() => {
    if (isVisible !== undefined) {
      setShow(isVisible);
      setModalData(defaultModalData)

      setTimeout(function () {
        jQuery("#promo_code").focus()
      }, 500)
    }
  }, [isVisible]);
  const [show, setShow] = useState(false);

  const [modalData, setModalData] = useState(defaultModalData);
  const [errorField, setErrorField] = useState([]);

  const validateFields = () => {
    var errorList = Array();
    errorList = isEmpty(modalData, "promo_code", errorList);
    setErrorField([...errorList]);
    return errorList.length > 0 ? false : true;
  };

  const onChangeFormField = (e, field_name) => {
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    let modal_data = {}
    modal_data[field_name] = e.target.value;
    setModalData({ ...modalData, ...modal_data });
  };

  const onChangeKeyInput = (e, field_name) => {
    if (field_name === 'promo_code') {
      if (e.key === 'Enter') {
        onSubmitModalData()
      }
    }
  };

  const handleClose = () => {
    setShow(false);
    setVisibleModal(false);
  };

  const onSubmitModalData = () => {
    let is_valid = validateFields();
    if (is_valid) {
      let promo_code = modalData['promo_code']
      doCheckCoupon(promo_code)
      return true;
    }
    showToast('Please type promo code', "error");
  };

  const doCheckCoupon = (couponName) => {
    show_loading(true)

    const payload = { coupon: couponName, user_logged_in: true }
    apiCheckCoupon(payload)
      .then((data) => {
        show_loading(false)
        if (data.status === "1") {
          //setCouponAppliedTxt(data.message);
          //showToast(data.message, "success");
          let couponInfo = data.data
          submitModalData({ ...couponInfo, promo_code: modalData['promo_code'] });
        } else {
          showToast(data.message, "error");
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  };
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        dialogClassName={modalClass}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="block mb-3 text-center">
            <div className="modal-box">
              <div id="dialog">
                <div className="text-normal text-left">
                  <label className="text-normal">
                    Bring your subscription back to life by entering the promo code:
                  </label>
                  <div>
                    <input
                      type="text"
                      id="promo_code"
                      className={`form-control ${errorField.includes("promo_code") ? "is-invalid" : ""
                        }`}
                      placeholder=""
                      value={get_data_value(modalData, "promo_code")}
                      onChange={(e) => {
                        onChangeFormField(e, "promo_code");
                      }}
                      onKeyDown={(e) => {
                        onChangeKeyInput(e, "promo_code")
                      }}
                    />
                  </div>
                </div>
                <div className="alert alert-warning mt-4 mb-0" role="alert">
                  <div className="note mb-0 text-left">
                    <p>
                      If you're using a 14-day or 30-day coupon, the cost of reactivation is $1.
                    </p>
                    <p>
                      If you're using a discount coupon, the promised promotional offer starts immediately.
                    </p>
                    <p>
                      <b>IMPORTANT NOTE</b>: If the membership is not cancelled within a 30-day period, it will automatically subscribe you to the monthly membership!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-md btn-close-dlg btn-light margin-right-10"
            data-dismiss="modal"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-md btn-primary"
            onClick={(e) => onSubmitModalData()}
          >
            Reactivate
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ReactivationCodeModal;
