import {
  ROUTE_USER_ACADEMY,
  ROUTE_USER_DASHBOARD,
  ROUTE_USER_FEED,
  ROUTE_USER_LICENSE,
  ROUTE_USER_PAYMENT,
  ROUTE_USER_PROFILE,
  ROUTE_USER_REFER_FRIEND,
  ROUTE_USER_SOFTWARE,
  ROUTE_USER_SUPPORT,
  ROUTE_USER_TICKET,
  ROUTE_USER_TRADING_ROOM,
  ROUTE_USER_VERIFICATION,
  ROUTE_USER_VPS,
  ROUTE_USER_WALLET,
  USER_LEVEL_ALL,
} from "navigation/CONSTANTS";


export const USER_MENU_ITEMS = [
  // {
  //   route: ROUTE_USER_FEED,
  //   icon: "mdi mdi-rss-box",
  //   title: "What's new",
  //   level: USER_LEVEL_ALL,
  // },
  {
    route: ROUTE_USER_SOFTWARE,
    icon: "mdi mdi-hand-pointing-right",
    title: "Getting Started",
    level: USER_LEVEL_ALL,
  },
  {
    route: ROUTE_USER_DASHBOARD,
    icon: "mdi mdi-home",
    title: "Dashboard",
    level: USER_LEVEL_ALL,
  },
  {
    route: ROUTE_USER_VPS,
    icon: "mdi mdi-server-plus",
    title: "Virtual Private Server",
    level: USER_LEVEL_ALL,
  },
  {
    route: ROUTE_USER_LICENSE,
    icon: "mdi mdi-format-list-bulleted-type",
    title: "Membership",
    level: USER_LEVEL_ALL,
  },
  // {
  //   route: "https://higherlevelcrypto.com",
  //   icon: "mdi mdi-package-variant-closed",
  //   title: "HLX Crypto",
  //   level: USER_LEVEL_ALL,
  //   //onClickFunc:"onClickHlxCrypto",
  //   external_link: true
  // },
  // {
  //   route: ROUTE_USER_TRADING_ROOM,
  //   icon: "mdi mdi-flask-empty",
  //   title: "Live Crypto Trading",
  //   level: USER_LEVEL_ALL,
  // },
  {
    route: ROUTE_USER_ACADEMY,
    icon: "mdi mdi-near-me",
    title: "Higher Level Academy",
    level: USER_LEVEL_ALL,
  },
  {
    route: ROUTE_USER_REFER_FRIEND,
    icon: "mdi mdi-flask-empty",
    title: "Refer a Friend!",
    level: USER_LEVEL_ALL,
  },
  {
    route: ROUTE_USER_WALLET,
    icon: "mdi mdi-wallet",
    title: "Wallet",
    level: USER_LEVEL_ALL,
  },
  {
    route: ROUTE_USER_PAYMENT,
    icon: "mdi mdi-currency-usd",
    title: "Payment History",
    level: USER_LEVEL_ALL,
  },
  {
    route: ROUTE_USER_TICKET,
    icon: "mdi mdi-forum",
    title: "Support Tickets",
    level: USER_LEVEL_ALL,
  },
  {
    route: ROUTE_USER_SUPPORT,
    icon: "mdi mdi-help-circle",
    title: "FAQ",
    level: USER_LEVEL_ALL,
  },
  {
    route: ROUTE_USER_VERIFICATION,
    icon: "mdi mdi-lock",
    title: "Two-Step Verification",
    level: USER_LEVEL_ALL,
  },
  {
    route: "",
    icon: "mdi mdi-code-not-equal-variant",
    title: "Reactivation via Code",
    level: USER_LEVEL_ALL,
    onClickFunc:"onClickReactivateViaCode"
  },
  {
    route: "",
    icon: "mdi mdi-package-up",
    title: "Upgrade to Smart Pack",
    level: USER_LEVEL_ALL,
    onClickFunc:"onClickBuySmartPack"
  },
  {
    route: ROUTE_USER_PROFILE,
    icon: "mdi mdi-account",
    title: "Profile / Cancellation",
    level: USER_LEVEL_ALL,
  },
];
