import { ROUTE_ABOUT_US, ROUTE_BLOG_LIST, ROUTE_FREE_BOOK, ROUTE_FREE_TRADE_IDEA, ROUTE_LOGIN, ROUTE_ROOT, ROUTE_SUPPORT } from "navigation/CONSTANTS";

export const HOME_MENU_ITEMS = [
  // {
  //   route: "https://higherlevelcrypto.com",
  //   icon: "",
  //   title: "HLX Crypto",
  //   level: "",
  //   external_link: true
  // },
  {
    route: ROUTE_BLOG_LIST,
    icon: "",
    title: "Blog",
    level: ""
  },
  {
    route: ROUTE_FREE_TRADE_IDEA,
    icon: "",
    title: "Free Trade Ideas",
    level: ""
  },
  {
    route: ROUTE_FREE_BOOK,
    icon: "",
    title: "Free Book",
    level: ""
  },
  {
    route: ROUTE_ROOT,
    icon: "",
    title: "Why HLX?",
    level: ""
  },

  // {
  //   route: ROUTE_ABOUT_US,
  //   icon: "",
  //   title: "About Us",
  //   level: ""
  // },
  // {
  //   route: ROUTE_LOGIN,
  //   icon: "",
  //   title: "Login",
  //   level: ""
  // },
  {
    route: ROUTE_ROOT,
    icon: "",
    title: "Pricing",
    level: "",
    clickFunc: "onClickPricingHashTag"
  },
  {
    route: ROUTE_SUPPORT,
    icon: "",
    title: "Support",
    level: ""
  }
];
