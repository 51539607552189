import { DISCORD_LINK, TELEGRAM_ANNOUNCEMENT_LINK, TELEGRAM_COMMUNITY_GROUP_LINK } from "config/CONSTANTS";
import UserLayout from "layouts/UserLayout/UserLayout";
import { ROUTE_USER_SOFTWARE } from "navigation/CONSTANTS";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { UpdateAppData } from "redux/actions/appActions";
import { updateUser } from "redux/actions/userActions";
import { apiUserGetProfileInfo } from "services/userCommonService";
import { showToast, show_loading } from "utils/GlobalFunctions";
import "./UserSoftwarePage.css";

const currentPageTitle = "Getting Started";
const UserSoftwarePage = () => {
  const dispatch = useDispatch();
  const initPage = () => {
    dispatch(
      UpdateAppData({
        currentPageTitle: currentPageTitle,
        current_route: ROUTE_USER_SOFTWARE,
        m_user_menu_collapsed: true,
      })
    );
  };

  useEffect(() => {
    initPage();
    getData();
  }, []);
  const history = useHistory();
  const defaultPageData = {
    user: {},
  };
  const [pageData, setPageData] = useState(defaultPageData);
  const getData = () => {
    show_loading(true);
    apiUserGetProfileInfo()
      .then((api_res) => {
        show_loading(false);
        if (api_res.status === "1") {
          setPageData(api_res.data);
          dispatch(updateUser(api_res.data["user"]));
        } else {
          showToast(api_res.message, "error");
        }
      })
      .catch((err) => {
        show_loading(false);
        showToast(err, "error");
      });
  };

  return (
    <UserLayout>
      <div className="user-getting-started-page">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title t-show-mobile">{currentPageTitle}</h4>
                <div className="card-content">
                  <div className="block padding-bottom-5 padding-top-5">
                    <h3 className="text-bold text-center">
                      Ready to Reach a Higher Level?
                    </h3>
                    <h4 className="r-item text-center">
                      Please watch the following steps to get started!
                    </h4>
                  </div>
                  <div
                    className="sub-block margin-top-0"
                    style={{ border: "none" }}
                  >
                    <h2 className="text-bold text-underline text-uppercase text-center">
                      WATCH THIS FIRST!
                    </h2>
                    <div className="block text-center padding-top-20">
                      <div className="block position-relative">
                        <iframe
                          src="https://player.vimeo.com/video/790007099"
                          width="640"
                          height="360"
                          frameBorder="0"
                          allow="autoplay; fullscreen"
                          title="WELCOME! (WATCH THIS FIRST!)"
                          allowFullScreen
                        ></iframe>
                        <div className="text-center mt-1 mb-3"></div>
                      </div>
                    </div>
                    <div className="block padding-bottom-5 padding-top-5">
                      <h4 className="r-item text-center" style={{ maxWidth: '640px', margin: '10px auto' }}>
                        <span className="text-warning">IMPORTANT STEP: </span> Interact with real users who have experience and can provide help and support by clicking the button below!
                      </h4>
                    </div>

                    <div className="block text-center padding-bottom-10 padding-top-10">
                      <a
                        href={TELEGRAM_COMMUNITY_GROUP_LINK}
                        className="btn btn-md btn-success"
                        rel="noreferrer"
                        target="_blank"
                      >
                        Join Our Community Support Group!
                      </a>
                    </div>
                    <div className="block text-center padding-bottom-10 padding-top-10">
                      <a
                        href={TELEGRAM_ANNOUNCEMENT_LINK}
                        className="btn btn-md btn-success"
                        rel="noreferrer"
                        target="_blank"
                      >
                        Join Our Announcement Channel!
                      </a>
                    </div>

                  </div>

                  <div className="sub-block">
                    <h4 className="text-bold text-underline text-center">
                      UNDERSTANDING NOVA (META TRADER 5)
                    </h4>
                    <div className="block text-center padding-top-20">
                      <div
                        className="block position-relative"
                        style={{ marginBottom: "20px" }}
                      >
                        <iframe
                          src="https://player.vimeo.com/video/790725527"
                          width="640"
                          height="360"
                          frameBorder="0"
                          allow="autoplay; fullscreen"
                          title="NOVA EXPLAINED"
                          allowFullScreen
                        ></iframe>
                        <h4>
                          <b></b>
                        </h4>
                      </div>
                    </div>
                    <div className="block padding-bottom-5 padding-top-5">
                      <h4 className="r-item text-center">
                        Download the Nova E-book for better understanding{" "}
                        <a
                          className="text-primary"
                          href="/assets/global/pdf/HLX-Nova-Ebook.pdf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          HERE.
                        </a>
                      </h4>
                    </div>
                  </div>

                  <div className="sub-block">
                    <h4 className="text-bold text-underline text-center">
                      UNDERSTANDING STELLAR (META TRADER 5)
                    </h4>
                    <div className="block text-center padding-top-20">
                      <div
                        className="block position-relative"
                        style={{ marginBottom: "20px" }}
                      >
                        <iframe
                          src="https://player.vimeo.com/video/904633503"
                          width="640"
                          height="360"
                          frameBorder="0"
                          allow="autoplay; fullscreen"
                          title="Stellar MT5 EXPLAINED"
                          allowFullScreen
                        ></iframe>
                        <h4>
                          <b></b>
                        </h4>
                      </div>
                    </div>
                    <div className="block padding-bottom-5 padding-top-5">
                      <h4 className="r-item text-center">
                        Download the Stellar MT5 E-book for better understanding{" "}
                        <a
                          className="text-primary"
                          href="/assets/global/pdf/HLX-Stellar-MT5-Ebook.pdf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          HERE.
                        </a>
                      </h4>
                    </div>
                  </div>

                  <div className="sub-block">
                    <h4 className="text-bold text-underline text-center">
                      UNDERSTANDING STELLAR (META TRADER 4)
                    </h4>
                    <div className="block text-center padding-top-20">
                      <div
                        className="block position-relative"
                        style={{ marginBottom: "20px" }}
                      >
                        <iframe
                          src="https://player.vimeo.com/video/582318051"
                          width="640"
                          height="360"
                          frameBorder="0"
                          allow="autoplay; fullscreen"
                          title="STELLAR EXPLAINED"
                          allowFullScreen
                        ></iframe>
                        <h4>
                          <b></b>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="sub-block">
                    <h4 className="text-bold text-underline text-center">
                      UNDERSTANDING GO (META TRADER 4)
                    </h4>
                    <div className="block text-center padding-top-20">
                      <div
                        className="block position-relative"
                        style={{ marginBottom: "20px" }}
                      >
                        <iframe
                          src="https://player.vimeo.com/video/540541325"
                          width="640"
                          height="360"
                          frameBorder="0"
                          allow="autoplay; fullscreen"
                          title="GO EXPLAINED"
                          allowFullScreen
                        ></iframe>
                        <h4>
                          <b></b>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </UserLayout>
  );
};

export default UserSoftwarePage;
