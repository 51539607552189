import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { get_data_value, isEmpty, jQuery, showToast } from "utils/GlobalFunctions";
import { APP_NAME } from "config/CONSTANTS";

function HlxCryptoAlert(props) {
  const { isVisible, setVisibleModal, submitModalData, modalClass, modalTitle } = props;
  const defaultModalData = {
    confirm_text: ""
  };
  useEffect(() => {
    if (isVisible !== undefined) {
      setShow(isVisible);
      setModalData(defaultModalData)
    }
  }, [isVisible]);
  const [show, setShow] = useState(false);

  const [modalData, setModalData] = useState(defaultModalData);
  const [errorField, setErrorField] = useState([]);

  const validateFields = () => {
    var errorList = Array();
    errorList = isEmpty(modalData, "confirm_text", errorList);
    setErrorField([...errorList]);
    return errorList.length > 0 ? false : true;
  };

  const onChangeFormField = (e, field_name) => {
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    let modal_data = {}
    modal_data[field_name] = e.target.value;
    setModalData({ ...modalData, ...modal_data });
  };
  const handleClose = () => {
    setShow(false);
    setVisibleModal(false);
  };

  const onSubmitModalData = () => {
    submitModalData()
  };
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        dialogClassName={modalClass}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="block margin-bottom-0 text-center">
            <div className="modal-box">
              <div id="dialog">
                <div className="text-normal text-center">
                  <label className="text-normal">
                    Are you sure you want to purchase the HLX Crypto Add-on ($40 / month)?
                  </label>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-md btn-primary margin-right-10"
            onClick={onSubmitModalData}
          >
            Absolutely!
          </button>
          <button
            type="button"
            className="btn btn-md btn-close-dlg btn-light"
            data-dismiss="modal"
            onClick={handleClose}
          >
            Not right now
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default HlxCryptoAlert;
