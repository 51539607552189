import React, { useEffect } from "react";
import Particles from "components/Particles/Particles";

// Import Swiper
import Swiper, { Autoplay } from 'swiper'
import 'swiper/swiper.min.css'
Swiper.use([Autoplay])

const Clients = (props) => {
  useEffect(() => {
    const carousel = new Swiper('.clients-carousel', {
      slidesPerView: 'auto',
      spaceBetween: 64,
      centeredSlides: true,
      loop: true,
      speed: 5000,
      noSwiping: true,
      noSwipingClass: 'swiper-slide',
      autoplay: {
        delay: 0,
        disableOnInteraction: true,
      },
    })
  }, [])

  const currencyPairList = [
    'EUR/USD', 'AUD/USD', 'GBP/USD', 'NZD/USD', 'USD/CAD', 'GBP/CAD', 'NZD/CAD'
  ]

  return (
    <section>
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">

        {/* Particles animation */}
        <div className="absolute inset-0 max-w-6xl mx-auto px-4 sm:px-6">
          <Particles className="absolute inset-0 -z-10" quantity={5} />
        </div>

        <div className="py-12 md:py-16">
          <div className="overflow-hidden">

            <div className="clients-carousel swiper-container relative before:absolute before:inset-0 before:w-32 before:z-10 before:pointer-events-none before:bg-gradient-to-r before:from-slate-900 after:absolute after:inset-0 after:left-auto after:w-32 after:z-10 after:pointer-events-none after:bg-gradient-to-l after:from-slate-900">
              <div className="swiper-wrapper !ease-linear select-none items-center">
                {
                  currencyPairList.map((item, index) => {
                    return (
                      <div className="swiper-slide !w-auto" key={index}>
                        <div className="text-slider-item">{item}</div>
                      </div>
                    )
                  })
                }

                {/* <div className="swiper-slide !w-auto">
                  <img className="mt-2" src="/themes/front/images/client-09.svg" alt="Client 09" width="92" height="28" />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Clients;
