import { UPDATE_PAGE } from "redux/actions/pageActions";

const initialState = { pageName: ""};

export const pageReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PAGE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
