import UserLayout from "layouts/UserLayout/UserLayout";
import { ROUTE_USER_REFER_FRIEND } from "navigation/CONSTANTS";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { UpdateAppData } from "redux/actions/appActions";
import { updateUser } from "redux/actions/userActions";
import { apiUserGetProfileInfo } from "services/userCommonService";
import { showToast, show_loading } from "utils/GlobalFunctions";
import "./UserReferFriendPage.css";

const currentPageTitle = "Refer a Friend!";
const UserReferFriendPage = () => {
  const dispatch = useDispatch();
  const initPage = () => {
    dispatch(
      UpdateAppData({
        currentPageTitle: currentPageTitle,
        current_route: ROUTE_USER_REFER_FRIEND,
        m_user_menu_collapsed: true,
      })
    );
  };

  useEffect(() => {
    initPage();
    getData();
  }, []);
  const history = useHistory();
  const defaultPageData = {
    user: {},
  };
  const [pageData, setPageData] = useState(defaultPageData);
  const getData = () => {
    show_loading(true);
    apiUserGetProfileInfo()
      .then((api_res) => {
        show_loading(false);
        if (api_res.status === "1") {
          setPageData(api_res.data);
          dispatch(updateUser(api_res.data["user"]));
        } else {
          showToast(api_res.message, "error");
        }
      })
      .catch((err) => {
        show_loading(false);
        showToast(err, "error");
      });
  };

  return (
    <UserLayout>
      <div className="user-getting-started-page">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title t-show-mobile">{currentPageTitle}</h4>
                <div className="card-content">
                  <div className="block padding-bottom-5 padding-top-5">
                    <h4 className="text-bold text-normal text-center">
                      Refer a friend and receive $50 for every new or renewed membership
                    </h4>
                    <p className="r-item text-center text-normal">
                      Please watch the video below for more details!
                    </p>
                  </div>
                  <div
                    className="sub-block margin-top-0"
                    style={{ border: "none" }}
                  >
                    <div className="block text-center padding-top-20">
                      <div className="block position-relative">
                        <iframe
                          src="https://player.vimeo.com/video/790030096"
                          width="640"
                          height="360"
                          frameBorder="0"
                          allow="autoplay; fullscreen"
                          title="Watch video"
                          allowFullScreen
                        ></iframe>
                        <div className="text-center mt-1 mb-3"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </UserLayout>
  );
};

export default UserReferFriendPage;
