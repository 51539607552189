import { useState } from "react";

import { DISCORD_LINK, FB_FIXEL_ID, TELEGRAM_COMMUNITY_GROUP_LINK } from "config/CONSTANTS";
import HomeLayout from "layouts/HomeLayout/HomeLayout";
import {
  ROUTE_BLOG_LIST,
  ROUTE_LOGIN,
} from "navigation/CONSTANTS";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { UpdateAppData } from "redux/actions/appActions";
import { empty, is_empty, showToast } from "utils/GlobalFunctions";
import blogList from "assets/data/blog.json";
import renderHTML from 'react-render-html';
import Sticky from 'react-stickynode';
import Particles from "components/Particles/Particles";
import { Transition } from '@headlessui/react'
import Highlighter, { HighlighterItem } from 'components/Highlighter/Highlighter'

const getBlogInfoBySlug = (slug) => {
  //let currentBlogInfo = null
  for (let k in blogList) {
    const blogInfo = blogList[k]
    if (blogInfo.slug === slug) {
      return blogInfo
    }
  }
  return null
}

const getTopBlogList = (slug) => {
  let topBloglist = []
  for (let k in blogList) {
    const blogInfo = blogList[k]
    if (blogInfo.slug !== slug) {
      topBloglist.push(blogInfo)
    }
    if (topBloglist.length >= 5) {
      return topBloglist
    }
  }
  return topBloglist
}

const BlogDetailPage = (props) => {
  const dispatch = useDispatch();
  const initPage = (page_title) => {
    if (empty(page_title)) {
      page_title = "Article";
    }
    dispatch(
      UpdateAppData({
        currentPageTitle: page_title,
        current_route: ROUTE_BLOG_LIST,
      })
    );
  };

  useEffect(() => {
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(FB_FIXEL_ID) // facebookPixelId
        ReactPixel.pageView()
      })
  }, [])

  const getBlogSlug = () => {
    let slug = "";
    try {
      if (!is_empty(props.match.params.slug)) {
        slug = props.match.params.slug
      }
    } catch (e) {

    }
    return slug
  }

  const blogSlug = getBlogSlug()

  const [blogInfo, setBlogInfo] = useState(null)
  const [topBlogList, setTopBlogList] = useState(null)

  const getBlogDetail = (slug) => {
    const blog_info = getBlogInfoBySlug(slug)
    const top_blog_list = getTopBlogList(slug)
    if (empty(blog_info)) {
      //showToast("Invalid request", "error");
      props.history.replace({ pathname: ROUTE_BLOG_LIST });
      return false
    }

    setBlogInfo(blog_info)
    setTopBlogList(top_blog_list)
    return blog_info
  }

  useEffect(() => {
    const blog_info = getBlogDetail(blogSlug)
    if (blog_info) {
      initPage(blog_info?.title)
    }
  }, [blogSlug])

  return (
    <div>
      <HomeLayout>
        <section className="relative bg-front-page-gradient">
          {
            blogInfo && (
              <>
                {/* <div className="absolute flex items-center justify-center top-0 -translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-[800px] aspect-square" aria-hidden="true">
            <div className="absolute inset-0 translate-z-0 bg-purple-500 rounded-full blur-[120px] opacity-30"></div>
            <div className="absolute w-64 h-64 translate-z-0 bg-purple-400 rounded-full blur-[80px] opacity-70"></div>
          </div> */}

                <Particles className="absolute inset-0 -z-10" />

                <div className="max-w-6xl mx-auto px-4 sm:px-6">
                  <div className="pt-32 md:pt-40">
                    <div className="md:flex md:justify-between">
                      <div className="md:grow pb-12 md:pb-20" id="blog_single_content">
                        <div className="max-w-[720px]">
                          <div className="flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-16">

                            {/* <div className="shrink-0">
                                  <div className="sticky top-6">
                                    <a className="flex items-center justify-center w-9 h-9 group border border-transparent rounded-full [background:linear-gradient(theme(colors.slate.900),_theme(colors.slate.900))_padding-box,_conic-gradient(theme(colors.slate.400),_theme(colors.slate.700)_25%,_theme(colors.slate.700)_75%,_theme(colors.slate.400)_100%)_border-box] relative before:absolute before:inset-0 before:bg-slate-800/30 before:rounded-full before:pointer-events-none" href="integrations.html">
                                      <span className="sr-only">Go back</span>
                                      <svg className="w-4 h-4 fill-purple-500" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.7 14.7l1.4-1.4L3.8 9H16V7H3.8l4.3-4.3-1.4-1.4L0 8z" />
                                      </svg>
                                    </a>
                                  </div>
                                </div> */}

                            <div>
                              <article className="pb-12 mb-12 border-b [border-image:linear-gradient(to_right,transparent,theme(colors.slate.800),transparent)1]">
                                <figure className="bg-slate-700/20 border border-slate-300/10 rounded-3xl mb-8 p-4 overflow-hidden">
                                  {/* <img className="w-full rounded-2xl" src="./images/integration-image.png" width="586" height="316" alt="Integration image" /> */}
                                  <img className="w-full rounded-2xl" src={`/assets/home/images/blog/${blogInfo?.id}/thumb.jpg`} alt={blogInfo?.title} />
                                </figure>

                                <div className="prose max-w-none text-slate-400 prose-headings:text-slate-50 prose-h2:text-xl prose-h2:mt-8 prose-h2:mb-4 prose-p:leading-relaxed prose-a:text-purple-500 prose-a:no-underline hover:prose-a:underline prose-strong:text-slate-50 prose-strong:font-medium prose-blockquote:pl-5 prose-blockquote:xl:-ml-5 prose-blockquote:border-l-2 prose-blockquote:border-purple-500 prose-blockquote:font-medium prose-blockquote:text-slate-300 prose-blockquote:italic">

                                  <div className="text-slate-300 h4">{blogInfo?.title}</div>

                                  <p className="text-purple-500">
                                    {`${blogInfo?.last_updated}, By ${blogInfo?.author ?? 'Admin'}`}
                                  </p>

                                  <div className="blog-content text-slate-400">
                                    {
                                      (blogInfo?.content) ? renderHTML(blogInfo?.content) : (<></>)
                                    }
                                  </div>

                                </div>
                              </article>
                            </div>
                          </div>
                        </div>
                      </div>


                      <aside className="md:w-64 lg:w-80 md:shrink-0 md:pt-[3.75rem] lg:pt-0 pb-12 md:pb-20">
                        <Sticky enabled={true} top={100} bottomBoundary="#blog_single_content">
                          <div className="top-6 md:pl-4 lg:pl-6">
                            <div className="space-y-6">
                              <div className="bg-gradient-to-tr from-slate-800 to-slate-800/25 rounded-3xl border border-slate-300/10">
                                <div className="px-3 py-4">
                                  <div className="text-center mb-4">
                                    <span className="relative text-slate-300 inline-flex items-center">
                                      Top Articles
                                    </span>
                                  </div>
                                  <ul className="text-sm">
                                    {
                                      topBlogList.map((item, index) => {
                                        const uri = `/article/${item.slug}`
                                        return (
                                          <li className="flex items-center justify-between space-x-2 py-3 border-t [border-image:linear-gradient(to_right,theme(colors.slate.700/.3),theme(colors.slate.700),theme(colors.slate.700/.3))1]" key={index}>
                                            <Link to={uri} className="widget-posts-item" title={item.title}>
                                              <img src={`/assets/home/images/blog/${item.id}/thumb.jpg`} alt={item.title} className="widget-posts-img-1" />
                                              <div className="widget-posts-title text-emphasis-2 text-sm text-slate-400">
                                                {`${item.title}`}
                                              </div>
                                            </Link>
                                          </li>
                                        )
                                      })
                                    }
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Sticky>
                      </aside>
                    </div>
                  </div>
                </div>
              </>
            )
          }
        </section>
      </HomeLayout>
    </div>
  );
};

export default withRouter(BlogDetailPage);
