import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { USER_MENU_ITEMS } from "./UserMenuItems";
import { UpdateAppData } from "redux/actions/appActions";
import { useHistory } from "react-router-dom";
import { updatePageData } from "redux/actions/pageActions";

import {
  ROUTE_USER_PAY_LICENSE,
  USER_LEVEL_ALL,
  USER_LEVEL_CUSTOMER,
  ROUTE_USER_ACADEMY,
  ROUTE_USER_DASHBOARD,
  ROUTE_USER_FEED,
  ROUTE_USER_LICENSE,
  ROUTE_USER_PAYMENT,
  ROUTE_USER_PROFILE,
  ROUTE_USER_REFER_FRIEND,
  ROUTE_USER_SOFTWARE,
  ROUTE_USER_SUPPORT,
  ROUTE_USER_TICKET,
  ROUTE_USER_TRADING_ROOM,
  ROUTE_USER_VERIFICATION,
  ROUTE_USER_VPS,
  ROUTE_USER_WALLET,
} from "navigation/CONSTANTS";
import { empty, get_data_value, showToast, show_loading } from "utils/GlobalFunctions";
import { console_log } from "utils/logger";
import { APP_NAME } from "config/CONSTANTS";
import SmartPackConfirmModal from "pages/User/UserLicensePage/inc/SmartPackConfirmModal";
import SmartPackAlert from "pages/User/UserLicensePage/inc/SmartPackAlert";
import ReactivationCodeModal from "pages/User/UserLicensePage/inc/ReactivationCodeModal";
import HlxCryptoConfirmModal from "pages/User/UserLicensePage/inc/HlxCryptoConfirmModal";
import HlxCryptoAlert from "pages/User/UserLicensePage/inc/HlxCryptoAlert";
import { apiGetAppSettings } from "services/loginService";
import { apiGetUserProfilePageDetail } from "services/userProfileService";

const UserSidebar = (props) => {
  const { askLogout } = props;
  const appDataStore = useSelector((x) => x.appDataStore);
  const user = useSelector((x) => x.userDataStore);
  const pageDataStore = useSelector((x) => x.pageDataStore);
  const dispatch = useDispatch();
  const history = useHistory();
  //////////////////////////socket part////////////////////////////////
  const socketStore = useSelector((x) => x.socketStore);
  const socket = socketStore["socket"];
  const user_notification_data = socketStore["user_notification_data"];
  useEffect(() => {
    if (!empty(user_notification_data)) {
      console_log(
        "------------socketStore(UserSidebar page)---------------",
        user_notification_data
      );
      //addSocketListener();
      //getFeedList();
    }
  }, [user_notification_data]);

  const token = get_data_value(user, "token");
  const socketHeader = { token: token };
  ///////////////////////////end socket part/////////////////////////////

  const onClickMenuItem = (clickFunc) => {
    console_log("clickFunc::", clickFunc)
    if (clickFunc === "onClickBuySmartPack") {
      onClickBuySmartPack()
    }
    else if (clickFunc === "onClickReactivateViaCode") {
      onClickReactivateViaCode()
    }
    else if (clickFunc === "onClickHlxCrypto") {
      onClickHlxCrypto()
    }
  }

  //////////////////////////////////////////////////////////////////////////////////////
  const onClickBuySmartPack = () => {
    setShowSmartPackConfirmModal(true)
  }
  const [showSmartPackConfirmModal, setShowSmartPackConfirmModal] = useState(false)
  const onSmarPackConfirmed = () => {
    setShowSmartPackConfirmModal(false)
    setShowSmartPackAlert(true)
  }
  const [showSmartPackAlert, setShowSmartPackAlert] = useState(false)
  const upgradeSmartPack = () => {
    setShowSmartPackAlert(false)
    dispatch(
      updatePageData({
        purchaseMembershipType: 2,
        purchasePromoCode: ""
      })
    );
    history.push(ROUTE_USER_PAY_LICENSE);
  }
  /////////////////////////////////////////////////////////////////////////////////////
  const onClickReactivateViaCode = () => {
    setShowReactivationCodeModal(true)
  }
  const [showReactivationCodeModal, setShowReactivationCodeModal] = useState(false)
  const onReactivationCodeSubmitted = (couponInfo) => {
    console_log("couponInfo:::", couponInfo)
    setShowReactivationCodeModal(false)

    const update_page_data = {
      purchaseMembershipType: 1,
      purchasePromoCode: couponInfo['promo_code']
    }
    if (parseInt(couponInfo['type']) === 0) {
      update_page_data['purchaseMembershipType'] = 4
    }

    dispatch(
      updatePageData(update_page_data)
    );
    history.push(ROUTE_USER_PAY_LICENSE);
  }
  //////////////////////////////////////////////////////////////////////////////////////
  const onClickHlxCrypto = () => {
    show_loading(true);
    apiGetUserProfilePageDetail()
      .then((api_res) => {
        show_loading(false);
        if (api_res.status === "1") {
          let app_settings = api_res.data.app_settings
          console_log("app_settings:::", app_settings)
          if (app_settings['crypto_package_func'] == 'true') {
            if (api_res.data['is_active_customer'] == 1) {
              setShowHlxCryptoConfirmModal(true)
            } else {
              showToast("You must be an active customer to purchase this Add-on", "error");
            }
          } else {
            showToast("Coming Soon!", "success");
          }
        } else {
          showToast(api_res.message, "error");
        }
      })
      .catch((err) => {
        show_loading(false);
        showToast(err, "error");
      });
  }
  const [showHlxCryptoConfirmModal, setShowHlxCryptoConfirmModal] = useState(false)
  const onHlxCryptoConfirmed = () => {
    setShowHlxCryptoConfirmModal(false)
    setShowHlxCryptoAlert(true)
  }
  const [showHlxCryptoAlert, setShowHlxCryptoAlert] = useState(false)
  const upgradeHlxCrypto = () => {
    setShowHlxCryptoAlert(false)
    dispatch(
      updatePageData({
        purchaseMembershipType: 3, //crypto package
        purchasePromoCode: ""
      })
    );
    history.push(ROUTE_USER_PAY_LICENSE);
  }

  let sideMenuItems = [];

  for (let i = 0; i < USER_MENU_ITEMS.length; i++) {
    let menuItem = USER_MENU_ITEMS[i];
    if (user["user_type"] === 0) {
      if (
        menuItem["level"] === USER_LEVEL_ALL ||
        menuItem["level"] === USER_LEVEL_CUSTOMER
      ) {
        sideMenuItems.push(menuItem);
      }
    } else if (user["user_type"] === 1) {
      if (
        menuItem["level"] === USER_LEVEL_ALL
      ) {
        sideMenuItems.push(menuItem);
      }
    } else if (user["user_type"] === 2) {
      if (
        menuItem["level"] === USER_LEVEL_ALL
      ) {
        sideMenuItems.push(menuItem);
      }
    }
  }

  const ROUTES_SPONSOR_ONLY = [
    ROUTE_USER_SOFTWARE, ROUTE_USER_DASHBOARD, ROUTE_USER_REFER_FRIEND, ROUTE_USER_WALLET, ROUTE_USER_TICKET, ROUTE_USER_SUPPORT, ROUTE_USER_VERIFICATION, ROUTE_USER_PROFILE
  ]

  if (user['user_role'] === 'sponsor_only') {
    sideMenuItems = USER_MENU_ITEMS.filter((menuItem) => ROUTES_SPONSOR_ONLY.includes(menuItem.route))
  }

  const menuItemClass = (menu_item) => {
    let class_name = ""
    if (menu_item.onClickFunc === "onClickBuySmartPack" && (user['membership_type'] == 2 || user['license_status'] == 2)) {
      class_name = "hidden"
    }
    else if (menu_item.onClickFunc === "onClickReactivateViaCode" && (user['is_active'] == 1)) {
      class_name = "hidden"
    }
    else if (menu_item.onClickFunc === "onClickHlxCrypto" && ( /*user['license_status'] == 0 || */user['crypto_package'] == 1)) {
      class_name = "hidden"
    }
    return class_name
  }

  return (
    <>
      <nav className="sidebar sidebar-danger" id="sidebar">
        <div
          className={`sidebar-content-wrapper sidebar-offcanvas custom-scroller ${appDataStore.m_user_menu_collapsed ? "" : "active"
            }`}
        >
          <ul className="nav">
            {sideMenuItems.map((menuItem, index) => (
              <li
                key={index}
                className={`nav-item ${appDataStore.current_route === menuItem.route ? "active" : ""} ${menuItemClass(menuItem)}`}
              >
                {
                  (menuItem.route !== "") ? (
                    <>
                      {
                        (menuItem.external_link) ? (
                          <a
                            href={menuItem.route}
                            activeclassname="active"
                            className="nav-link"
                            target="_blank"
                          >
                            <i className={menuItem.icon}></i>
                            <span className="menu-title">{menuItem.title}</span>
                            {/* {index === 0 && (
                              <span className={`badge badge-pill badge-primary`}>{!empty(user_notification_data) &&
                                !empty(user_notification_data["unread_feed_list"])
                                ? user_notification_data["unread_feed_list"].length
                                : ""}</span>
                            )} */}
                          </a>
                        ) : (
                          <Link
                            to={menuItem.route}
                            activeclassname="active"
                            className="nav-link"
                          >
                            <i className={menuItem.icon}></i>
                            <span className="menu-title">{menuItem.title}</span>
                            {/* {index === 0 && (
                              <span className={`badge badge-pill badge-primary`}>{!empty(user_notification_data) &&
                                !empty(user_notification_data["unread_feed_list"])
                                ? user_notification_data["unread_feed_list"].length
                                : ""}</span>
                            )} */}
                          </Link>
                        )
                      }
                    </>

                  ) : (
                    <a
                      href="#"
                      activeclassname="active"
                      className={`nav-link`}
                      onClick={(e) => onClickMenuItem(menuItem.onClickFunc)}
                    >
                      <i className={menuItem.icon}></i>
                      <span className="menu-title">{menuItem.title}</span>
                    </a>
                  )
                }
              </li>
            ))}
            <li className="nav-item">
              <div className="nav-link a-btn" onClick={(e) => { askLogout() }}>
                <i className="mdi mdi-logout-variant"></i>
                <span className="menu-title">Log out</span>
              </div>
            </li>
          </ul>
        </div>
      </nav>
      <SmartPackConfirmModal
        modalTitle={`Upgrade to Smart Pack and Save Almost 20%!`}
        isVisible={showSmartPackConfirmModal}
        setVisibleModal={setShowSmartPackConfirmModal}
        submitModalData={onSmarPackConfirmed}
        modalClass="user-page smart-pack-modal"
      />
      <SmartPackAlert
        modalTitle={APP_NAME}
        isVisible={showSmartPackAlert}
        setVisibleModal={setShowSmartPackAlert}
        submitModalData={upgradeSmartPack}
        modalClass="user-page smart-pack-alert"
      />
      <HlxCryptoConfirmModal
        modalTitle={`Purchasing HLX's Crypto Add-on:`}
        isVisible={showHlxCryptoConfirmModal}
        setVisibleModal={setShowHlxCryptoConfirmModal}
        submitModalData={onHlxCryptoConfirmed}
        modalClass="user-page hlx-crypto-modal"
      />
      <HlxCryptoAlert
        modalTitle={APP_NAME}
        isVisible={showHlxCryptoAlert}
        setVisibleModal={setShowHlxCryptoAlert}
        submitModalData={upgradeHlxCrypto}
        modalClass="user-page hlx-crypto-alert"
      />
      <ReactivationCodeModal
        modalTitle={`Reactivation via Code`}
        isVisible={showReactivationCodeModal}
        setVisibleModal={setShowReactivationCodeModal}
        submitModalData={onReactivationCodeSubmitted}
        modalClass="user-page smart-pack-modal"
      />
    </>

  );
};

export default UserSidebar;
