import { axiosGet, axiosPost } from "./ajaxServices";
import { urlUserGetTradingRoomPageDetail, urlUserGetVpsConsoleUrl, urlUserGetVpsPageDetail, urlUserGetVpsPassword } from "./CONSTANTS";

export const apiUserGetTradingRoomPageDetail = () => {
  const url = urlUserGetTradingRoomPageDetail;
  return new Promise((resolve, reject) => {
    axiosGet(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
 