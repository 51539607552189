import HomeLayout from "layouts/HomeLayout/HomeLayout";
import { ROUTE_SUPPORT } from "navigation/CONSTANTS";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { UpdateAppData } from "redux/actions/appActions";
import Particles from "components/Particles/Particles";

const SupportPage = () => {
  const dispatch = useDispatch();
  const initPage = () => {
    let page_title = "Support";
    dispatch(
      UpdateAppData({
        currentPageTitle: page_title,
        current_route: ROUTE_SUPPORT,
      })
    );
  };

  useEffect(() => {
    initPage();
  });

  function openTicketPopup() {
    window.openChatBox(true);
  }

  return (
    <div>
      <HomeLayout>
        <div className="main-content bg-front-page-gradient">
          <div
            className="section page-section container has-header content-sm relative"
            id="page-section1"
          >
            <Particles className="absolute inset-0 -z-10" />

            <div className="container d-padding-top-20" style={{ minHeight: '40vh' }}>
              <div className="row d-margin-top-20">
                <div className="col-md-12 md-margin-bottom-30 aboutus-left-block">
                  <h1 className="inline-flex font-medium bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-purple-200 pb-3">
                    We are here to help!
                  </h1>
                  <h5 className="text-normal">
                    For questions and support:{` `}
                    <a
                      className="a-btn text-purple-500"
                      href="mailto:admin@higherlevelfx.com"
                    >
                      admin@higherlevelfx.com
                    </a>
                  </h5>
                  <h5 className="text-normal">
                    For business inquiries:{` `}
                    <a
                      className="a-btn text-purple-500"
                      href="mailto:admin@higherlevelfx.com"
                    >
                      admin@higherlevelfx.com
                    </a>
                  </h5>
                  <h5 className="text-normal mt-2">
                    We respond to all tickets in the order they are received and
                    will respond directly to yours as quickly as we can. Please
                    allow up to 48 hours for a reply. Rest assured, we will get
                    back to you as soon as possible.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HomeLayout>
    </div>
  );
};

export default SupportPage;
