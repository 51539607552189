import { APP_NAME } from "config/CONSTANTS";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import ScrollToTop from "react-scroll-to-top";

const HomeBottomScoller = () => {
    // const appDataStore = useSelector((x) => x.appDataStore);

    // useEffect(() => {

    // }, [])

    return (
        <>
            <ScrollToTop
                smooth
                color="white"
                width="20"
                height="20"
            />
        </>
    )
}

export default HomeBottomScoller;
