import { LICENSE_LIFE_TIME, LICENSE_PRICE } from "config/CONSTANTS";
import HomeLayout from "layouts/HomeLayout/HomeLayout";
import { ROUTE_TRIAL_POLICY } from "navigation/CONSTANTS";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { UpdateAppData } from "redux/actions/appActions";
import { priceFormat } from "utils/GlobalFunctions";
import Particles from "components/Particles/Particles";

const TrialPolicyPage = () => {
  const dispatch = useDispatch();
  const initPage = () => {
    let page_title = "7-Day Trial & Promo Code Policy";
    dispatch(
      UpdateAppData({
        currentPageTitle: page_title,
        current_route: ROUTE_TRIAL_POLICY,
      })
    );
  };

  useEffect(() => {
    initPage();
  });

  return (
    <div>
      <HomeLayout>
        <div className="main-content bg-front-page-gradient">
          <div
            className="section page-section container has-header content-sm relative"
            id="page-section1"
          >
            <Particles className="absolute inset-0 -z-10" />

            <div className="container d-padding-top-20">
              <div className="row d-margin-top-20">
                <div className="col-md-12" style={{ minHeight: '40vh' }}>
                  <h1 className="inline-flex font-medium bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-purple-200 pb-3">
                    7-Day Trial & Promo Code Policy
                  </h1>
                  <p className="text-normal">
                    If your membership isn't cancelled within the 7-day trial period OR within the 30-day trial period that you have obtained with a promotional code,
                    it will automatically roll over to a normal membership of ${priceFormat(LICENSE_PRICE)} / {LICENSE_LIFE_TIME} days.
                    <br />
                  </p>
                  <p className="text-normal"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HomeLayout>
    </div>
  );
};

export default TrialPolicyPage;
