import ConfirmModal from "components/ConfirmModal/ConfirmModal";
import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch } from "react-redux";
import { apiDeleteUserReferralFundRow } from "services/userReferralFundService";
import { apiDeleteUserPayoutRow } from "services/userWalletService";

import {
  showToast,
  show_loading,
  toLocalDatetime,
} from "utils/GlobalFunctions";

const UserReferralFundListTable = (props) => {
  const { initialTableData } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    setTableData(initialTableData);
  }, [initialTableData]);
  //const history = useHistory();
  const [tableData, setTableData] = useState(initialTableData);
  const [confirmModalTitle, setConfirmModalTitle] = useState(
    "Are you sure to delete this record?"
  );
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [currentRow, setCurrentRow] = useState({});

  const onClickDeleteRow = (row) => {
    setCurrentRow(row);
    setShowConfirmModal(true);
  };
  const deleteRow = () => {
    show_loading(true);
    apiDeleteUserReferralFundRow(currentRow)
      .then((api_res) => {
        show_loading(false);
        if (api_res.status === "1") {
          setShowConfirmModal(false);
          setTableData(api_res.data["referral_fund_list"]);
          showToast(
            "Referral fund record has been deleted successfully.",
            "success"
          );
        } else {
          showToast(api_res.message, "error");
        }
      })
      .catch((err) => {
        show_loading(false);
        showToast(err, "error");
      });
  };

  const columns = [
    {
      name: "Description",
      selector: "child_name",
      sortable: true,
      cell: (row) =>
        row.child_name ? (
          <>
            {`Reward from: ${row.child_name}`}
          </>
        ) : null,
    },
    {
      name: "Amount",
      selector: "amount",
      sortable: true,
      maxWidth: '100px',
    },
    {
      name: "Received at",
      selector: "add_timestamp",
      sortable: true,
      hide: "md",
      maxWidth: '180px',
      cell: (row) =>
        row.add_timestamp ? (
          <>
            <span className="hidden">{row.add_timestamp}</span>
            {toLocalDatetime(row.add_timestamp)}
          </>
        ) : null,
    },
    // {
    //   name: "Action",
    //   button: true,
    //   cell: (row) => (
    //     <>
    //       <button
    //         className="btn btn-xs btn-danger"
    //         onClick={() => onClickDeleteRow(row)}
    //       >
    //         <i className="fa fa-times"></i>
    //       </button>
    //     </>
    //   ),
    // },
  ];

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = tableData.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <>
        <input
          id="search"
          type="text"
          placeholder="Search..."
          value={filterText}
          className="form-control input-search"
          onChange={(e) => setFilterText(e.target.value)}
        />
        <button className="a-btn btn-clear-search" onClick={handleClear}>
          x
        </button>
      </>
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <>
      <DataTable
        theme="dark"
        noHeader={true}
        columns={columns}
        data={filteredItems}
        defaultSortField="add_timestamp"
        defaultSortAsc={false}
        pagination
        subHeader
        subHeaderComponent={subHeaderComponent}
        paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
      />
      <ConfirmModal
        content={confirmModalTitle}
        onClickYes={deleteRow}
        visibleModal={showConfirmModal}
        setVisibleModal={setShowConfirmModal}
        modalClass="user-page confirm-modal"
      />
    </>
  );
};

export default UserReferralFundListTable;
