import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch } from "react-redux";

import {
  timeConverter,
} from "utils/GlobalFunctions";

const AdminCouponUserListTable = (props) => {
  const { initialTableData, currentRankItem } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    setTableData(initialTableData);
  }, [initialTableData]);
  //const history = useHistory();
  const [tableData, setTableData] = useState(initialTableData);

  const columns = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
      omit:true
    },
    {
      name: "Username",
      selector: "user_name",
      sortable: true,
      cell: (row) => <div>{(row.user_name)}</div>,
    },
    {
      name: "Coupon",
      selector: "coupon",
      sortable: true,
    },
    {
      name: "Register Date",
      selector: "add_timestamp",
      sortable: true,
      cell: (row) => <span>{timeConverter(row.add_timestamp)}</span>,
    },
  ];

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = tableData.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div className="datatable-subheader">
        <div className="row">
          <div className="col-md-12">
            <div className="datatable-filter-box">
              <input
                id="search"
                type="text"
                placeholder="Search..."
                value={filterText}
                className="form-control input-search"
                onChange={(e) => setFilterText(e.target.value)}
              />
              <button className="a-btn btn-clear-search" onClick={handleClear}>
                x
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <div className="datatable-wrapper p-0">
      <DataTable
        theme="dark"
        noHeader={true}
        columns={columns}
        data={filteredItems}
        defaultSortField="id"
        defaultSortAsc={true}
        pagination
        subHeader
        subHeaderComponent={subHeaderComponent}
        paginationPerPage={10}
        paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
      />
    </div>
  );
};

export default AdminCouponUserListTable;
