import {
  ROUTE_LOGIN,
  ROUTE_USER_DASHBOARD,
  ROUTE_USER_SOFTWARE,
} from "navigation/CONSTANTS";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAppData } from "redux/actions/appActions";

import { TickerTape } from "react-tradingview-embed";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  empty,
  get_data_value,
  jQuery,
  showToast,
  show_loading,
} from "utils/GlobalFunctions";
import { apiGetUserDashboardDetail } from "services/userDashboardService";
import { useHistory, withRouter } from "react-router-dom";
import { BASE_FRONT_URL, RULE_PERCENT } from "config/CONSTANTS";
import { updateUser } from "redux/actions/userActions";
import UserReferralListPage from "./inc/UserReferralListPage";
import UserLayout from "layouts/UserLayout/UserLayout";

const UserDashboardPage = (props) => {

  const dispatch = useDispatch();
  const initPage = () => {
    dispatch(
      UpdateAppData({
        currentPageTitle: "Dashboard",
        current_route: ROUTE_USER_DASHBOARD,
        m_user_menu_collapsed: true,
      })
    );
  };

  useEffect(() => {
    initPage();
    getData();
  }, []);
  const history = useHistory();
  const userDataStore = useSelector((x) => x.userDataStore);
  if (userDataStore["is_active"] === 0) {
    history.push(ROUTE_USER_SOFTWARE);
  }

  const defaultPageData = {
    user: {},
    sponsor_name: "",
    check_in_holding_tank: false,
    ref_url: "",
    rank_info: {},
    tree_info: [],
    active_personal_referral_cnt: 0,
    active_member_count: 0,
    percent0: 0,
    percent1: 0,
    percent2: 0,
    active_personal_referral_list: [],
    personal_referral_list: [],
  };
  const [pageData, setPageData] = useState(defaultPageData);
  const getData = () => {
    show_loading(true);
    apiGetUserDashboardDetail()
      .then((api_res) => {
        show_loading(false);
        if (api_res.status === "1") {
          setPageData(api_res.data);
          dispatch(updateUser(api_res.data["user"]));
        } else {
          showToast(api_res.message, "error");
        }
      })
      .catch((err) => {
        show_loading(false);
        showToast(err, "error");
      });
  };

  const onUrlCopied = () => {
    jQuery("#referral_url").focus();
    showToast("Referral link copied.", "success");
  };

  const tradingViewSymbols = [
    {
      description: "EURUSD",
      proName: "FX:EURUSD",
    },
    {
      description: "USDJPY",
      proName: "FX:USDJPY",
    },
    {
      description: "AUDUSD",
      proName: "FX:AUDUSD",
    },
    {
      description: "GBPCAD",
      proName: "FX:GBPCAD",
    },
    {
      description: "GBPUSD",
      proName: "FX:GBPUSD",
    },
    {
      description: "GBPJPY",
      proName: "FX:GBPJPY",
    },
    {
      description: "NZDCAD",
      proName: "FX:NZDCAD",
    },
    {
      description: "NZDUSD",
      proName: "FX:NZDUSD",
    },
    {
      description: "USDCAD",
      proName: "FX:USDCAD",
    },
    {
      description: "XAUUSD",
      proName: "OANDA:XAUUSD",
    },
    {
      description: "AUDCAD",
      proName: "FX:AUDCAD",
    },
  ];

  return (
    <UserLayout>
      <div className={`block ${empty(pageData['user']['id']) ? 'hidden' : ''}`}>
        <div className="hidden trading-widget-block mb-4">
          <TickerTape
            widgetProps={{
              theme: "dark",
              colorTheme: "dark",
              isTransparent: true,
              displayMode: "adaptive",
              locale: "en",
              symbols: tradingViewSymbols,
            }}
          ></TickerTape>
        </div>

        <div className={`dashboardForCustomer`}>
          <div className="row">
            {
              (pageData['user']) && (
                <>
                  <div className="col-12 col-sm-12 col-xl-12 grid-margin">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="referral-url-box">
                            <h4>Your Referral Link:</h4>
                            <h4 className="text-white mt-3 position-relative">
                              <input
                                type="text"
                                placeholder="Referral url"
                                className="form-control"
                                id="referral_url"
                                name="referral_url"
                                value={pageData["ref_url"]}
                                readOnly={true}
                              />
                              <CopyToClipboard
                                text={pageData["ref_url"]}
                                onCopy={onUrlCopied}
                              >
                                <span
                                  className="btn-copy-text"
                                  data-clipboard-action="copy"
                                  data-clipboard-target="#referral_url"
                                >
                                  Copy Link
                                </span>
                              </CopyToClipboard>
                            </h4>
                          </div>

                          <CopyToClipboard
                            text={pageData["ref_url"]}
                            onCopy={onUrlCopied}
                          >
                            <div className="icon-box icon-box-bg-image-info btn-url-copy">
                              <i className="ti-files gradient-card-icon"></i>
                            </div>
                          </CopyToClipboard>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )
            }

            {
              (pageData['user']) && (
                <>
                  <div className="col-12 col-sm-4 col-xl-4 grid-margin">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex align-items-center justify-content-between">
                          <div>
                            <h4>Active Personal Referrals:</h4>
                            <h4 className="text-success mt-3 text-bold">
                              {pageData["active_personal_referral_cnt"]}
                              &nbsp;
                            </h4>
                            <h6 className="text-muted">&nbsp;</h6>
                          </div>
                          <div className="icon-box icon-box-bg-image-danger">
                            <i className="ti-pie-chart gradient-card-icon"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )
            }

            <div className="col-12 col-sm-4 col-xl-4 grid-margin">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <h4>Wallet Balance:</h4>
                      <h4 className="text-success mt-3 text-bold">
                        ${pageData["user"]["balance"]}&nbsp;
                      </h4>
                      <h6 className="text-muted">&nbsp;</h6>
                    </div>
                    <div className="icon-box icon-box-bg-image-danger">
                      <i className="ti-pie-chart gradient-card-icon"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-4 col-xl-4 grid-margin">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <h4>Your Sponsor:</h4>
                      <h4 className="text-success mt-3">
                        {get_data_value(pageData, "sponsor_name")}
                      </h4>
                      <h6 className="text-muted">&nbsp;</h6>
                    </div>
                    <div className="icon-box icon-box-bg-image-danger">
                      <i className="ti-user gradient-card-icon"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!empty(pageData["personal_referral_list"]) && (
            <div className="row">
              <div className="col-md-12">
                <UserReferralListPage pageData={pageData} />
              </div>
            </div>
          )}
        </div>


      </div>
    </UserLayout>
  );
};

export default withRouter(UserDashboardPage);
