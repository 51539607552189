import { TELEGRAM_COMMUNITY_GROUP_LINK } from "config/CONSTANTS";
import HomeLayout from "layouts/HomeLayout/HomeLayout";
import { ROUTE_FREE_BOOK } from "navigation/CONSTANTS";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { UpdateAppData } from "redux/actions/appActions";
import Particles from "components/Particles/Particles";

const FreeBookPage = () => {

  const dispatch = useDispatch();
  const initPage = () => {
    let page_title = "Free Book"
    dispatch(
      UpdateAppData({
        currentPageTitle: page_title,
        current_route: ROUTE_FREE_BOOK,
      })
    );

  };

  useEffect(() => {
    initPage();
  });

  return (
    <div>
      <HomeLayout>
        <div className="main-content bg-front-page-gradient">
          <div
            className="section page-section container content-sm relative"
            id="page-section1"
          >
            <Particles className="absolute inset-0 -z-10" />

            {/* <div className="absolute inset-0 -z-10 -mx-28 rounded-b-[3rem] pointer-events-none overflow-hidden" aria-hidden="true">
              <div className="absolute left-1/2 -translate-x-1/2 bottom-0 -z-10">
                <img src="/themes/front/images/glow-top.svg" className="max-w-none" width="2146" height="300" alt="Hero Illustration" />
              </div>
            </div> */}

            <div className="container">
              <div className="block-1 d-padding-top-40">
                <div className="row d-padding-top-20">
                  <div className="col-md-6 d-margin-bottom-30 order-2 md:order-none">
                    <h1 className="inline-flex font-medium bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-purple-200 pb-3">
                      What you will learn:
                    </h1>
                    <p className="text-normal">
                      ALL the key things you MUST look at before even looking at
                      any chart!
                    </p>
                    <p className="text-normal">
                      Your fundamental analysis will be taken to a higher level!
                    </p>
                    <p className="text-normal">
                      Learn about the tools of the Central Bank!
                    </p>
                    <p className="text-normal">
                      Learn about the absolute best times on when to get in and get out of the market!
                    </p>

                    <div className="block text-center pt-5 pb-3 text-normal-spacing">
                      <a
                        href={TELEGRAM_COMMUNITY_GROUP_LINK}
                        className="btn text-sm text-white bg-purple-500 hover:bg-purple-600 w-full shadow-sm group"
                        target="_blank"
                        rel="noreferrer"
                        style={{ maxWidth: '360px' }}
                      >
                        Download your FREE book!
                      </a>
                    </div>

                    <p className="text-normal mb-4">
                      Download your free eBook by visiting the pinned post in this Telegram group!
                    </p>
                  </div>
                  <div className="col-md-6 order-1">
                    <div className="intro-video-box text-center mt-4 mb-5">
                      <img
                        style={{ maxHeight: '320px', margin: 'auto' }}
                        src="/assets/front/images/20240123/freebook.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HomeLayout>
    </div>
  );
};

export default FreeBookPage;
