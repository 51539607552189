import AdminLayout from "layouts/AdminLayout/AdminLayout";
import {
  ROUTE_ADMIN_COUPON_STATS,
} from "navigation/CONSTANTS";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAppData } from "redux/actions/appActions";
import { updateUser } from "redux/actions/userActions";
import { apiGetAdminCouponStatsPageDetail } from "services/adminStatsService";
import {
  get_data_value,
  showToast,
  show_loading,
} from "utils/GlobalFunctions";
import { console_log } from "utils/logger";
import AdminCouponStatsInfoModal from "./inc/AdminCouponStatsInfoModal";

const currentPageTitle = "Coupon Details";
const AdminCouponStatsPage = () => {
  const userDataStore = useSelector((x) => x.userDataStore);
  const socketStore = useSelector((x) => x.socketStore);
  const socket = socketStore["socket"];
  const token = get_data_value(userDataStore, "token");
  const socketHeader = { token: token };

  const dispatch = useDispatch();
  const initPage = () => {
    dispatch(
      UpdateAppData({
        currentPageTitle: currentPageTitle,
        current_route: ROUTE_ADMIN_COUPON_STATS,
        m_user_menu_collapsed: true,
      })
    );
  };
  useEffect(() => {
    initPage();
    getData();
  }, []);

  const defaultCouponStats = []
  const [couponStats, setCouponStats] = useState(defaultCouponStats);

  const getData = () => {
    show_loading(true);
    apiGetAdminCouponStatsPageDetail()
      .then((api_res) => {
        show_loading(false);
        if (api_res.status === "1") {
          setCouponStats(api_res.data['stats_data']);
          dispatch(updateUser(api_res.data["user"]));
        } else {
          showToast(api_res.message, "error");
        }
      })
      .catch((err) => {
        show_loading(false);
        showToast(err, "error");
      });
  };

  const [currentStatItem, setCurrentStatItem] = useState({user_name:""});
  const [showStatsItemModal, setShowStatsItemModal] = useState(false);
 
  const showCouponUsers = (idx) => {
    console_log("idx:::", idx)
    const stats_item = couponStats[idx]
    console_log("stats_item:::", stats_item)
    setCurrentStatItem(stats_item)
    setShowStatsItemModal(true)
  }

  return (
    <>
      <AdminLayout>
        <div className="admin-coupon-stats-page">
          <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title t-show-mobile">
                    {currentPageTitle}
                  </h4>
                  <div className="card-content mt-2">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-4">
                          {
                            (couponStats.length > 0) && (
                              couponStats.map((item, index) => {
                                return (
                                  <p className="coupon-user-item text-normal" key={index} onClick={(e)=>{showCouponUsers(index)}}>
                                    User <span className="text-bold">{item['user_name']}</span> invited <span className="text-bold">{item['user_count']}</span> coupon user{item['user_count'] > 1 ? `s` : ``}
                                  </p>
                                )
                              })
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AdminCouponStatsInfoModal
            modalTitle={currentStatItem['user_name'] + `'s Invitation`}
            isVisible={showStatsItemModal}
            setVisibleModal={setShowStatsItemModal}
            currentStatItem={currentStatItem}
            modalClass="user-page admin-page admin-stats-info-modal modal-md"
          />

        </div>
      </AdminLayout>
    </>
  );
};

export default AdminCouponStatsPage;
