import { SYSTEM_ERROR } from "config/CONSTANTS";
import { axiosGet } from "./ajaxServices";
import {
  urlAdminCouponStatsPageDetail,
  urlAdminStatsPageDetail,
} from "./CONSTANTS";

export const apiGetAdminStatsPageDetail = () => {
  const url = urlAdminStatsPageDetail;
  return new Promise((resolve, reject) => {
    axiosGet(url, {}, SYSTEM_ERROR)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const apiGetAdminCouponStatsPageDetail = () => {
  const url = urlAdminCouponStatsPageDetail;
  return new Promise((resolve, reject) => {
    axiosGet(url, {}, SYSTEM_ERROR)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });   
};