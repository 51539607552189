import { DISCORD_LINK, FB_FIXEL_ID, TELEGRAM_COMMUNITY_GROUP_LINK } from "config/CONSTANTS";
import HomeLayout from "layouts/HomeLayout/HomeLayout";
import {
  ROUTE_LOGIN,
  ROUTE_USER_WALLET,
  ROUTE_WELCOME,
} from "navigation/CONSTANTS";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { UpdateAppData } from "redux/actions/appActions";
import Particles from "components/Particles/Particles";

const WelcomePage = () => {
  const dispatch = useDispatch();
  const initPage = () => {
    let page_title = "Congratulations! You're in!";
    dispatch(
      UpdateAppData({
        currentPageTitle: page_title,
        current_route: ROUTE_WELCOME,
      })
    );
  };

  useEffect(() => {
    initPage();
  });

  useEffect(() => {
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(FB_FIXEL_ID) // facebookPixelId
        ReactPixel.pageView()
      })
  }, [])

  return (
    <div>
      <HomeLayout>
        <div className="main-content bg-front-page-gradient">
          <div
            className="section page-section container has-header content-sm pb-40 relative"
            id="page-section1"
          >
            <Particles className="absolute inset-0 -z-10" />

            <div className="container">
              <div className="row">
                <div className="col-md-12 margin-bottom-30">
                  <div className="reg-form margin-bottom-40">
                    <div className="logo-wrapper">
                      <img
                        src="/assets/home/images/HLFX_Logo_White-Green_TM-01.png"
                        className="img-responsive img-logo"
                        alt="HLFX"
                      />
                    </div>
                  </div>
                  <h1 className="text-center text-bold mt-25 mb-20 text-3xl text-slate-300">
                    Congratulations! You're in! <br />
                    <span style={{ fontSize: "0.8em" }}>Watch this before you login to your account!</span>
                  </h1>
                  <div
                    className="intro-video-box text-center"
                    style={{ position: "relative", zIndex: 1 }}
                  >
                    <iframe
                      src="https://player.vimeo.com/video/818439749"
                      className="vimeo-iframe"
                      frameBorder="0"
                      allow="autoplay; fullscreen"
                      allowFullScreen
                      title="video"
                      style={{ margin: 'auto' }}
                    ></iframe>
                  </div>
                  <div className="text-center text-bold mt-5 mb-20">
                    <a
                      href={TELEGRAM_COMMUNITY_GROUP_LINK}
                      className="btn text-sm text-white bg-purple-500 hover:bg-purple-600 w-full shadow-sm group"
                      target="_blank"
                      rel="noreferrer"
                      style={{ maxWidth: '360px' }}
                    >
                      Join Our Community Support Group!
                    </a>
                  </div>
                  <p className="desc-v1 text-center mt-10 mb-10 text-slate-300">
                    ... Or if you have already joined, click{" "}
                    <Link className="text-bold text-purple-500" to={ROUTE_LOGIN}>
                      HERE
                    </Link>{" "}
                    to login to your account.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HomeLayout>
    </div>
  );
};

export default WelcomePage;
