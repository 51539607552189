// any app specific constants that you don't want to keep in config can go here.
export const APP_NAME = "Higher Level FX";
export const REGISTER_FUNC = "enabled";

let base_site_url = "";
let base_api_url = "";
let socket_server_url = "";
let base_upload_url = "";

let sms_func = "disabled";

export const BACKEND_LOCATION = "hlx_prod"; //"localhost", "hlx_prod", "hlx_test", "hlx_model"

if (BACKEND_LOCATION === "localhost") {
    base_site_url = "http://192.168.0.55"
    base_api_url = base_site_url + ":8088"
    socket_server_url = base_api_url
    base_upload_url = base_site_url + ""
    sms_func = "disabled";
} else if (BACKEND_LOCATION === "hlx_test") {
    base_site_url = "https://dev.higherlevelfx.com"
    base_api_url = "https://prodapi.higherlevelfx.com" // "https://dev.higherlevelfx.com:8088"
    socket_server_url = base_api_url
    base_upload_url = base_site_url
    sms_func = "disabled";
} else if (BACKEND_LOCATION === "hlx_model") {
    base_site_url = "https://54.244.14.37"
    base_api_url = base_site_url + ":8088"
    socket_server_url = base_api_url
    base_upload_url = base_site_url
    sms_func = "enabled";
} else if (BACKEND_LOCATION === "hlx_prod") {
    base_site_url = "https://higherlevelfx.com"
    base_api_url = "https://prodapi.higherlevelfx.com"
    socket_server_url = base_api_url
    base_upload_url = base_site_url
    sms_func = "enabled";
}

export const BASE_SITE_URL = base_site_url
export const BASE_FRONT_URL = BASE_SITE_URL
export const BASE_API_URL = base_api_url
export const SOCKET_SERVER_URL = socket_server_url
export const BASE_UPLOAD_URL = base_upload_url

export const LICENSE_TRIAL_PRICE = 1 //$
export const LICENSE_PRICE = 179 //$
export const LICENSE_ANNUAL_PRICE = 1797 //$
export const LICENSE_LIGHT_MEMBERSHIP_PRICE = 129 //$
export const LICENSE_CRYPTO_PRICE = 40 //$
export const WITHDRAWAL_MIN_AMOUNT = 100 //$
export const FEE_PERIOD = 30 //days
export const REFERRAL_FUNDS = 50; //$
export const REFERRAL_FUNDS_ANNUAL = 500;
export const MLM_LEG_COUNT = 3
export const MAX_LEVEL = 2
export const MAX_TANK_DURATION = 10 //days
export const LICENSE_LIFE_TIME = 30; //days
export const TRIAL_LICENSE_PRICE = 1; //
export const TRIAL_LICENSE_DURATION = 7;//days
export const LICENSE_AMOUNT = 2; //$
export const ACTIVE_CUSTOMER_COUNT = 3; //active customer cnt for free license
export const RULE_PERCENT = 0.66; //percent

export const SITE_MODE = "live";
export const SMS_FUNC = sms_func;

export const SYSTEM_ERROR = "System error. Please try again later!";

export const CTA_YES = "Yes";
export const CTA_NO = "No";
export const CTA_CANCEL = "Cancel";

export const logoUrl = "/assets/images/logo.png";
export const googleKey = "6LfrFKQUAAAAAMzFobDZ7ZWy982lDxeps8cd1I2i";

export const APPSTORE_LINK = "https://apps.apple.com/us/app/hlx-trader/id1581656445"
export const GOOGLEPLAY_LINK = "https://play.google.com/store/apps/details?id=com.higherlevelfx.HLXTrader"

export const ASSISTANT_NAME = "Jessica";
export const ASSISTANT_AVATAR = "admin-avatar.jpg";
export const FB_FIXEL_ID = "432853795668334";

export const DISCORD_LINK = "https://discord.gg/7FfCnKKGkG";
export const TELEGRAM_ANNOUNCEMENT_LINK = "https://t.me/higherlevelfx";
export const TELEGRAM_COMMUNITY_GROUP_LINK = "https://t.me/HigherLevelFXGroup";
